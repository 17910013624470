import React, { useState, useEffect, useCallback } from "react";
import axiosClient from "../../../axios-client";
import { useNavigate } from "react-router-dom";
import {
  AddIcon,
  SearchIcon,
  DeleteIcon,
  EditIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import MyPagination from "../../../components/MyPagination";
import { useStateContext } from "../../../context/ContextProvider";
// import { LiaUserEditSolid } from 'react-icons/lia';
import { VscFileSubmodule } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";

import {
  IconButton,
  Flex,
  Box,
  Heading,
  Button,
  Stack,
  Table,
  Thead,
  InputGroup,
  InputLeftElement,
  Tbody,
  Tr,
  Th,
  Select,
  Avatar,
  Switch,
  Badge,
  Td,
  Input,
  useToast,
  TableContainer,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  AlertIcon,
  FormControl,
  FormLabel,
  Alert,
  ModalFooter,
  useDisclosure,
  Link,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Card,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Checkbox,
} from "@chakra-ui/react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdGroupRemove, MdOutlineClose } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { CiEdit } from "react-icons/ci";
import { FaChartPie, FaCheck } from "react-icons/fa";
import axios from "axios";

export const GestStudents = () => {
  const toast = useToast();
  const [selectedEtat, setSelectedEtat] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOpt, setSelectedOpt] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedStudent, setSelectedStudent] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedTeamM, setSelectedTeamM] = useState("");

  const [changeModal, setChangeModal] = useState(true);

  //search variable
  const [searchTerm, setSearchTerm] = useState("");
  const [partenaires, setPartenaires] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [mbEquipe, setMbEquipe] = useState("");
  const [mbEquipeId, setMbEquipeId] = useState();
  const [edit, setEdit] = useState(-1);

  const navigate = useNavigate();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const MAX_WORDS = 2; // Maximum number of words to display

  function truncateAddress(address) {
    const words = address.split(" ");
    if (words.length > MAX_WORDS) {
      return words.slice(0, MAX_WORDS).join(" ") + "...";
    }
    return address;
  }
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  // current user
  const { user, setUser } = useStateContext();

  const [deleteId, setDeleteId] = useState(null);
  const cancelRef = React.useRef();

  //Student data
  const [data, setData] = useState([]);
  const [dataPartner, setDataPartner] = useState([]);

  // current user data
  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
  }, [user.id]);
  useEffect(() => {
    axiosClient
      .get("/partenaires/")
      .then((response) => {
        setPartenaires(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axiosClient
      .get("/membres-equipe/")
      .then((response) => {
        setTeamMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function update() {
    axiosClient
      .get("/partenaires/")
      .then((response) => {
        setPartenaires(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axiosClient
      .get("/membres-equipe/")
      .then((response) => {
        setTeamMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axiosClient
      .get("/students/")
      .then((response) => {
        let filteredData = response.data.filter(
          (e) => e.partener_related === null
        );
        let filteredDataPartner = response.data.filter(
          (e) => e.partener_related !== null
        );

        // if (selectedEtat !== null) {
        //     filteredData = filteredData.filter(
        //         (item) => item.etat === selectedEtat
        //     );
        // }
        // if (selectedOption !== "") {
        //     filteredData = filteredData.filter(
        //         (item) => item.is_confirmed === selectedOption
        //     );
        // }
        if (partenaires?.find((e) => e.id === user?.id)) {
          setData(
            response.data
              .filter((student) => student.partener_related === user?.id)
              .sort()
              .reverse()
          );
        } else if (
          teamMembers?.find((e) => e.id === user?.id)?.equipe ===
          "ConfirmationTeam" &&
          teamMembers?.find((e) => e.id === user?.id).type_membre !==
          "CHEFEQUIPE"
        ) {
          setData(
            filteredData
              .filter((student) => student.assigned_to === user.id)
              .sort()
              .reverse()
          );
          setDataPartner(
            filteredDataPartner
              .filter((student) => student.assigned_to === user.id)
              .sort()
              .reverse()
          );
        } else if (
          teamMembers?.find((e) => e.id === user.id)?.equipe === "SuiviTeam" &&
          teamMembers?.find((e) => e.id === user.id).type_membre !==
          "CHEFEQUIPE"
        ) {
          setData(
            filteredData
              .filter((student) => student.is_confirmed === "confirmed")
              .sort()
              .reverse()
          );
          setDataPartner(
            filteredDataPartner
              .filter((student) => student.is_confirmed === "confirmed")
              .sort()
              .reverse()
          );
        } else {
          setData(filteredData.sort().reverse());
          setDataPartner(filteredDataPartner.sort().reverse());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [selectedStudents, setSelectedStudents] = useState([]);
  const toggleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) => {
      if (prevSelected.includes(studentId)) {
        // If student is already selected, remove it
        return prevSelected.filter((id) => id !== studentId);
      } else {
        // If student is not selected, add it
        return [...prevSelected, studentId];
      }
    });
  };
  //get the students data
  useEffect(() => {
    // axiosClient
    //   .get("/students/")
    //   .then((response) => {
    //     let filteredData = response.data.filter(
    //       (e) => e.partener_related === null
    //     );
    //     let filteredDataPartner = response.data.filter(
    //       (e) => e.partener_related !== null
    //     );

    //     // if (selectedEtat !== null) {
    //     //     filteredData = filteredData.filter(
    //     //         (item) => item.etat === selectedEtat
    //     //     );
    //     // }
    //     // if (selectedOption !== "") {
    //     //     filteredData = filteredData.filter(
    //     //         (item) => item.is_confirmed === selectedOption
    //     //     );
    //     // }
    //     if (partenaires?.find((e) => e.id === user?.id)) {
    //       setData(
    //         response.data
    //           .filter((student) => student.partener_related === user?.id)
    //           .sort()
    //           .reverse()
    //       );
    //     } else if (
    //       teamMembers?.find((e) => e.id === user.id)?.equipe ===
    //       "ConfirmationTeam" &&
    //       teamMembers?.find((e) => e.id === user.id).type_membre !==
    //       "CHEFEQUIPE"
    //     ) {
    //       setData(
    //         filteredData
    //           .filter((student) => student.assigned_to === user.id)
    //           .sort()
    //           .reverse()
    //       );
    //       setDataPartner(
    //         filteredDataPartner
    //           .filter((student) => student.assigned_to === user.id)
    //           .sort()
    //           .reverse()
    //       );
    //     } else
    //       if (
    //         teamMembers?.find((e) => e.id === user.id)?.equipe === "SuiviTeam" &&
    //         teamMembers?.find((e) => e.id === user.id).type_membre !==
    //         "CHEFEQUIPE"
    //       ) {
    //         setData(
    //           filteredData
    //             .filter((student) => student.is_confirmed === "confirmed")
    //             .sort()
    //             .reverse()
    //         );
    //         setDataPartner(
    //           filteredDataPartner
    //             .filter((student) => student.is_confirmed === "confirmed")
    //             .sort()
    //             .reverse()
    //         );
    //       } else {
    //         setData(filteredData.sort().reverse());
    //         setDataPartner(filteredDataPartner.sort().reverse());
    //       }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    if (
      user.role === "ADMIN" ||
      user.role === "SUPERADMIN" ||
      user.role === "RESPO" ||
      user.role === 'CHEFEQUIPE'
    ) {
      axiosClient.get('/students/').then(response => {
        setData(response.data
          .filter(
            (e) => e.partener_related === null
          )
          .sort()
          .reverse())
        setDataPartner(response.data
          .filter(
            (e) => e.partener_related !== null
          )
          .sort()
          .reverse());
      })
    }

    if (user.role === 'PARTENAIRE') {
      axiosClient.get('/students/')
        .then(response => {
          setData(response.data
            .filter((student) => student.partener_related === user?.id)
            .sort()
            .reverse())
        })
    }

    if (user.role === "MBEQUIPE") {
      if (
        teamMembers?.find((e) => e.id === user.id)?.equipe ===
        "ConfirmationTeam" &&
        teamMembers?.find((e) => e.id === user.id).type_membre !==
        "CHEFEQUIPE"
      ) {
        axiosClient.get('/students/').then(response => {

          setData(
            response.data
              .filter((student) => student.partener_related === null && student.assigned_to === user.id)
              .sort()
              .reverse()
          );
          setDataPartner(
            response.data
              .filter((student) => student.partener_related !== null && student.assigned_to === user.id)
              .sort()
              .reverse()
          );
        })
      }
      if (
        teamMembers?.find((e) => e.id === user.id)?.equipe === "SuiviTeam" &&
        teamMembers?.find((e) => e.id === user.id).type_membre !==
        "CHEFEQUIPE"
      ) {
        axiosClient.get('/students/').then(response => {
          setData(
            response.data
              .filter((student) => student.partener_related === null && student.is_confirmed === "confirmed")
              .sort()
              .reverse()
          );
          setDataPartner(
            response.data
              .filter((student) => student.partener_related !== null && student.is_confirmed === "confirmed")
              .sort()
              .reverse()
          );
        })
      }
    }

  }, [
    selectedEtat,
    selectedOption,
    user.id,
    teamMembers,
    partenaires,
    selectedStudents,
  ]);

  const [message, setMessage] = useState(false);

  // delete student
  const handleDelete = (id = deleteId) => {
    if (!id) return;
    axiosClient
      .delete(`/students/${id}/`)
      .then((response) => {
        setData((prevData) => prevData.filter((row) => row.id !== id));
        toast({
          description: "The user is successfully deleted",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      })
      .catch((error) => {
        console.error(error);
      });
    onCloseAlert();
  };

  //search method
  const filteredData = useCallback(() => {
    return data.filter((row) => {
      return (
        row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.tel.includes(searchTerm)
      );
    });
  }, [data, searchTerm, user.id]);
  const filteredDataPartner = useCallback(() => {
    return dataPartner.filter((row) => {
      return (
        row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.tel.includes(searchTerm)
      );
    });
  }, [dataPartner, searchTerm, user.id]);

  //edit l'etat d'etudiant ('activé','désactivé')
  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append("edited_by", user.first_name + " " + user.last_name);

    axiosClient
      .put(`/update_user_etat/${id}/`, formData)
      .then((response) => {
        setData((rows) =>
          rows.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                etat: !etat,
              };
            }
            return row;
          })
        );
        setDataPartner((rows) =>
          rows.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                etat: !etat,
              };
            }
            return row;
          })
        );
      })
      .catch((error) => console.error(error));
    if (!etat) {
      toast({
        description: "The account is activated",
        status: "success",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    } else {
      toast({
        description: "The account is disabled",
        status: "warning",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const [currentPage, setCurrentPage] = useState(0);

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    }
  };

  const UpdateAssignment = (id) => {
    const formData = new FormData();
    if (selectedTeamM) formData.append("assigned_to", selectedTeamM);
    axiosClient.put(`/update_student_assignment/${id}/`, formData).then(() => {
      toast({
        description: `You successfuly assigned the student to a new team member`,
        status: "success",
        duration: 4000,
        isClosable: true,
        variant: "left-accent",
      });
      setMessage("");
      setSelectedTeam("");
      setSelectedTeamM("");
      update();
    });

    onClose();
  };
  const UpdateAssignmentAll = () => {
    if (!selectedTeamM) {
      toast({
        description: `You need to select a member`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        variant: "left-accent",
        position: "top",
      });
      return;
    }

    const updateRequests = selectedStudents.map((studentId) => {
      const formData = new FormData();
      if (selectedTeamM) formData.append("assigned_to", selectedTeamM);

      return axiosClient.put(
        `/update_student_assignment/${studentId}/`,
        formData
      );
    });

    // Execute all requests concurrently
    axios
      .all(updateRequests)
      .then(() => {
        toast({
          description: `You successfully assigned the selected students to a new team member`,
          status: "success",
          duration: 2000,
          isClosable: true,
          variant: "left-accent",
        });

        setSelectedStudents([]);
        setSelectedTeamM("");

        update();
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error updating assignments:", error);
      });
  };

  const borderColor = useColorModeValue("gray.300", "gray.600");

  const updateConf = (id) => {
    const formData = new FormData();
    formData.append("is_confirmed", selectedOpt);

    axiosClient
      .put(`/update_user_confirmation/${id}/`, formData)
      .then((response) => {
        toast({
          description: "the confirmation status is updated",
          status: "success",
          variant: "left-accent",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        setSelectedOpt("");
        setEdit(-1);
        update();
      })
      .catch((error) => console.error(error));
  };
  const viderInputs = () => {
    setSearchTerm("");
    setSelectedEtat(null);
    setSelectedOption("");
  };
  const color = useColorModeValue("#7e172c", "#7e172c");
  const txtcolor = useColorModeValue("#f2c478", "#f2c478");
  const { isOpend, onOpend, onClosed } = useDisclosure();

  return (
    <Box mt="5px">
      <Box
        w="100%"
        display={"flex"}
        justifyContent="space-between"
        mt={2}
        mb={5}
      >
        <Text
          fontFamily="Plus Jakarta Display"
          as="cite"
          fontSize={{ base: "18px", md: "20px", lg: "25px" }}
          color="gray"
        >
          <span
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            Home
          </span>{" "}
          / <span>Students</span>
        </Text>
        {user.role !== "ADMIN" && (
          <Button
            onClick={() => navigate("/add-student")}
            leftIcon={<AiOutlineUserAdd color="#f2c478" />}
            _hover={"none"}
            color={"#f2c478"}
            bg="#7e172c"
          >
            Add student
          </Button>
        )}
      </Box>
      {(user.role === "ADMIN" ||
        user.role === "RESPO" ||
        user.role === "CHEFEQUIPE") && (
          <Flex mb={{ base: 5, md: 0 }} justify={"end"} gap={2}>
            <Card
              backgroundColor={"red.700"}
              fontSize={{ base: "xs", md: "sm" }}
              p={3}
              color={"white"}
            >
              Number of Students : {data.length}
            </Card>
            <Card
              backgroundColor={"red.700"}
              fontSize={{ base: "xs", md: "sm" }}
              p={3}
              color={"white"}
            >
              Partner's students: {dataPartner.length}
            </Card>
          </Flex>
        )}

      <Stack>
        {user.role === "PARTENAIRE" ? (
          <Stack
            w={"full"}
            maxW="full"
            // shadow={"md"}
            // bg={useColorModeValue("gray.50", "gray.700")}
            // rounded={"lg"}
            p={6}
          // my={5}
          >
            {/* Search input */}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={{ base: "column", lg: "row" }}
            >
              <Flex w={"100%"}>
                <Select
                  borderRadius="5"
                  size="sm"
                  fontSize={{ base: 13, lg: 16 }}
                  w={{ base: "50%", lg: "25%" }}
                  mr={{ base: 0, lg: 5 }}
                  mb={4}
                  value={selectedEtat}
                  onChange={(e) =>
                    setSelectedEtat(
                      e.target.value === "" ? null : e.target.value === "true"
                    )
                  }
                >
                  <option value="">All</option>
                  <option value="true">Activated</option>
                  <option value="false">Disabled</option>
                </Select>
                <Select
                  borderRadius="5"
                  size="sm"
                  fontSize={{ base: 13, lg: 16 }}
                  w={{ base: "50%", lg: "25%" }}
                  mr={5}
                  mb={4}
                  value={selectedOption}
                  onChange={(e) =>
                    setSelectedOption(
                      e.target.value === "" ? "" : e.target.value
                    )
                  }
                >
                  <option value="">All</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="rejected">Rejected</option>
                </Select>
              </Flex>

              <InputGroup size="sm" w={{ base: "100%", lg: "40%" }}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  borderRadius="5"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  mb={4}
                  sx={{
                    marginLeft: "auto",
                  }}
                />{" "}
              </InputGroup>
            </Flex>
            <TableContainer rounded={10} p="5">
              <Table size="sm" variant="simple">
                <Thead size="sm">
                  <Tr fontSize="12">
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Image
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Code
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      First name
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      last name
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Email
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Phone number
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Address
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Student N°
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Status
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Confirmation status
                    </Th>

                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Creation date
                    </Th>
                    <Th
                      borderColor={borderColor}
                      fontFamily="Plus Jakarta Display"
                      fontSize="12"
                    >
                      Application
                    </Th>
                    {(user.role === "CHEFEQUIPE" ||
                      user.role === "SUPERADMIN") && (
                        <Th
                          borderColor={borderColor}
                          fontFamily="Plus Jakarta Display"
                          fontSize="12"
                        >
                          Assignment
                        </Th>
                      )}
                    {user.role !== "ADMIN" && (
                      <Th
                        borderColor={borderColor}
                        fontFamily="Plus Jakarta Display"
                        fontSize="12"
                      >
                        Options
                      </Th>
                    )}
                  </Tr>
                </Thead>
                {/* {partenaires.find(e => e.id === user.id)
                ? <Tbody>
                    {filteredData().filter(e => e.partener_related === user.id).filter((row) => selectedEtat === null || row.etat === selectedEtat).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                        <Tr key={row.id}>
                            <Td borderColor={borderColor} > <Avatar size='md' borderRadius="10px" src={row.image} /></Td>
                            <Td borderColor={borderColor} >{row.code}</Td>
                            <Td borderColor={borderColor}>{row.first_name}</Td>
                            <Td borderColor={borderColor}>{row.last_name}</Td>
                            <Td borderColor={borderColor} >{row.email}</Td>
                            <Td borderColor={borderColor}>
                                {row.address}
                            </Td>
                            <Td borderColor={borderColor}>{row.nb_student}</Td>
                            <Td borderColor={borderColor}>
                                <Flex>
                                    <Stack direction='row' mr="3px">
                                        {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                            <Switch
                                                colorScheme='green'
                                                isChecked={row.etat}
                                                onChange={() => handleEtatClick(row.id, row.etat)}
                                            />
                                        }
                                    </Stack>
                                    <Badge
                                        size="sm"
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                    >
                                        {row.etat ? 'Activated' : 'disable'}
                                    </Badge>
                                </Flex>
                            </Td>
                            <Td align='center' borderColor={borderColor}>
                                <Button
                                    size="sm"
                                    onClick={() => navigate(`/student-applications`, {
                                        state: {
                                            idStudent: row.id
                                        }
                                    })}
                                    mr="5px"
                                    variant={"outline"}
                                    colorScheme="yellow"
                                > <VscFileSubmodule fontSize={"16px"} /> Application
                                </Button>
                            </Td>
                            <Td borderColor={borderColor}>{new Date(row.date_creation).toLocaleDateString("en-US")}</Td>
                            {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                <Td borderColor={borderColor}>

                                    <IconButton
                                        size="sm"
                                        onClick={() => navigate(`/edit-student`, {
                                            state: {
                                                idStudent: row.id
                                            }
                                        })}
                                        mr="5px"
                                        variant='outline'
                                        colorScheme="whatsapp"
                                        icon={<FiEdit3 fontSize={"16px"} />}>
                                    </IconButton>


                                    <IconButton
                                        size="sm"
                                        onClick={() => {
                                            setDeleteId(row.id);
                                            onOpenAlert();

                                        }}
                                        mr="5px"
                                        colorScheme="red"
                                        icon={<MdGroupRemove fontSize={"18px"} />}>
                                    </IconButton>
                                </Td>}

                        </Tr>
                    ))}
                    {filteredData().length === 0 && (
                        <Tr>
                            <Td borderColor={borderColor} colSpan={11}>No matching rows were found.</Td>
                        </Tr>
                    )}
                </Tbody> : */}
                <Tbody>
                  {filteredData()
                    .filter(
                      (row) =>
                        selectedEtat === null ||
                        (row.etat === selectedEtat &&
                          (selectedOption === "" ||
                            row.is_confirmed === selectedOption))
                    )
                    .slice(
                      currentPage * pageSize,
                      currentPage * pageSize + pageSize
                    )
                    .map((row) => (
                      <Tr key={row.id}>
                        <Td borderColor={borderColor}>
                          {" "}
                          <Avatar
                            size="md"
                            borderRadius="10px"
                            src={row.image}
                          />
                        </Td>
                        <Td borderColor={borderColor}>{row.code}</Td>
                        <Td borderColor={borderColor}>{row.first_name}</Td>
                        <Td borderColor={borderColor}>{row.last_name}</Td>
                        <Td borderColor={borderColor}>{row.email}</Td>
                        <Td borderColor={borderColor}>{row.tel}</Td>
                        <Td borderColor={borderColor}>
                          {row.address ? row.address : "____"}
                        </Td>
                        <Td borderColor={borderColor}>{row.nb_student}</Td>

                        <Td borderColor={borderColor}>
                          <Flex>
                            {user.role !== "ADMIN" && (
                              <Stack direction="row" mr="3px">
                                <Switch
                                  colorScheme="green"
                                  isChecked={row.etat}
                                  onChange={() =>
                                    handleEtatClick(row.id, row.etat)
                                  }
                                />
                              </Stack>
                            )}
                            <Badge
                              size="sm"
                              rounded={"lg"}
                              colorScheme={!row.etat ? "red" : "green"}
                            >
                              {row.etat ? "Activated" : "disable"}
                            </Badge>
                          </Flex>
                        </Td>
                        {teamMembers?.find((e) => e.id === user.id)?.equipe ===
                          "ConfirmationTeam" ? (
                          <Td borderColor={borderColor}>
                            {edit === row.id ? (
                              <Flex gap={1} align={"center"}>
                                <Select
                                  borderRadius="5"
                                  size="sm"
                                  fontSize={{ base: 13, lg: 16 }}
                                  value={selectedOpt}
                                  onChange={(e) =>
                                    setSelectedOpt(
                                      e.target.value === ""
                                        ? null
                                        : e.target.value
                                    )
                                  }
                                >
                                  <option value="pending">Pending</option>
                                  <option value="confirmed">Confirmed</option>
                                  <option value="rejected">Rejected</option>
                                </Select>
                                <MdOutlineClose
                                  color="red"
                                  cursor={"pointer"}
                                  onClick={() => setEdit(-1)}
                                  fontSize={"23px"}
                                />
                                <FaCheck
                                  color="green"
                                  cursor={"pointer"}
                                  onClick={() => updateConf(row.id)}
                                  fontSize={"17px"}
                                />
                              </Flex>
                            ) : (
                              <Flex>
                                {row.is_confirmed}{" "}
                                <FiEdit3
                                  onClick={() => {
                                    setEdit(row.id);
                                  }}
                                  color="green"
                                  style={{
                                    marginLeft: 10,
                                    cursor: "pointer",
                                  }}
                                  fontSize={"16px"}
                                />
                              </Flex>
                            )}
                          </Td>
                        ) : (
                          <Td borderColor={borderColor}>{row.is_confirmed}</Td>
                        )}

                        <Td borderColor={borderColor}>
                          {new Date(row.date_creation).toLocaleDateString(
                            "en-US"
                          )}
                        </Td>

                        <Td borderColor={borderColor}>
                          <Button
                            size="sm"
                            onClick={() =>
                              navigate(`/student-applications`, {
                                state: {
                                  idStudent: row.id,
                                },
                              })
                            }
                            mr="5px"
                            variant={"outline"}
                            colorScheme="yellow"
                          >
                            {" "}
                            <VscFileSubmodule fontSize={"16px"} /> Application
                          </Button>
                        </Td>
                        {(user.role === "CHEFEQUIPE" ||
                          user.role === "SUPERADMIN") && (
                            <Td borderColor={borderColor}>
                              {row.assigned_to ? (
                                <Flex flexDirection={"row"} align={"center"}>
                                  {
                                    teamMembers.find(
                                      (e) => e.id === row.assigned_to
                                    )?.first_name
                                  }{" "}
                                  {
                                    teamMembers.find(
                                      (e) => e.id === row.assigned_to
                                    )?.last_name
                                  }
                                  <CiEdit
                                    cursor={"pointer"}
                                    style={{
                                      marginLeft: 5,
                                      fontSize: "16px",
                                      color: "green",
                                    }}
                                    onClick={() => {
                                      // setFullName(`${row.first_name} ${row.last_name}`)
                                      // setSelectedLead(row.chef_equipe)
                                      // setTeamId(row.id);
                                      // setIsModalOpen(true);
                                      setSelectedStudent(row);
                                      setSelectedStudentId(row.id);
                                      onOpen();
                                      setMbEquipe(
                                        teamMembers.find(
                                          (mb) => mb.id === row.assigned_to
                                        )?.equipe
                                      );
                                      // setMbEquipeId(`${teamMembers.find((mb) => mb.id === row.assigned_to)?.first_name} ${teamMembers.find((mb) => mb.id === row.assigned_to)?.last_name}`)
                                      setMbEquipeId(
                                        teamMembers.find(
                                          (mb) => mb.id === row.assigned_to
                                        )?.id
                                      );

                                      setChangeModal(false);
                                    }}
                                  />
                                </Flex>
                              ) : (
                                <Link
                                  color="gray"
                                  onClick={() => {
                                    setSelectedStudent(row);
                                    setSelectedStudentId(row.id);
                                    setChangeModal(true);
                                    onOpen();
                                  }}
                                >
                                  assign to a team member{" "}
                                </Link>
                              )}
                              {/* <Button
                                        size="sm"
                                        onClick={() => {
                                            setSelectedStudent(row)
                                            setSelectedStudentId(row.id)
                                            onOpen()

                                        }}
                                        mr="5px"
                                        variant={"outline"}
                                        colorScheme="blue"
                                        > Assign to
                                    </Button> */}
                            </Td>
                          )}
                        {user.role !== "ADMIN" && (
                          <Td borderColor={borderColor}>
                            {/* <IconButton
                         size="sm"
                         onClick={() => navigate(`/editResponsable`, {
                             state: {
                                 idResponsable: row.id
                             }
                         })}
                         mr="5px"
                         colorScheme="green"
                         icon={<EditIcon />}>
                     </IconButton> */}
                            {/* <Popover placement="left" >
                         <PopoverTrigger>
                             <IconButton
                                 size="sm"
                                 mr="5px"
                                 bg="gray.300"
                                 color={"black"}
                                 icon={<MdHistory />}>
                             </IconButton>
                         </PopoverTrigger>
                         <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                             <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                 Historique
                             </PopoverHeader>
                             <PopoverArrow bg="#497dff" />
                             <PopoverCloseButton />
                             <PopoverBody>
                                 <div style={{ marginBottom: '8px' }}>
                                     Ajouté par : {row.added_by}
                                 </div>
                                 <div style={{ marginBottom: '8px' }}>
                                     Modifié par : {row.edited_by}
                                 </div>

                                 <div>
                                     last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                 </div>
                             </PopoverBody>
                             <PopoverHeader pt={4} fontWeight="bold" border="0">
                             </PopoverHeader>
                         </PopoverContent>
                         
                     </Popover> */}

                            <IconButton
                              size="sm"
                              onClick={() =>
                                navigate(`/edit-student`, {
                                  state: {
                                    idStudent: row.id,
                                  },
                                })
                              }
                              mr="5px"
                              variant="outline"
                              colorScheme="whatsapp"
                              icon={<FiEdit3 fontSize={"16px"} />}
                            ></IconButton>

                            <IconButton
                              size="sm"
                              onClick={() => {
                                setDeleteId(row.id);
                                onOpenAlert();
                              }}
                              mr="5px"
                              colorScheme="red"
                              icon={<MdGroupRemove fontSize={"18px"} />}
                            ></IconButton>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  {filteredData().length === 0 && (
                    <Tr>
                      <Td borderColor={borderColor} colSpan={15}>
                        No matching rows were found.
                      </Td>
                    </Tr>
                  )}
                </Tbody>
                {/* } */}
              </Table>
            </TableContainer>
            {/* Pagination */}
            <Flex justify="space-between" align="center" w="100%">
              <Box flex="1">
                <MyPagination
                  data={filteredData()}
                  searchInput={searchTerm}
                  PAGE_SIZE={pageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Box>

              <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                {/* Add more options as needed */}
              </Select>
            </Flex>
          </Stack>
        ) : (
          <Tabs variant="enclosed">
            <TabList
              overflowX={"auto"}
              overflowY={"hidden"}
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Tab
                _selected={{ color: txtcolor, bg: color }}
                onClick={viderInputs}
              >
                <Text fontSize={{ base: 12, lg: 15 }}>Students</Text>
              </Tab>

              <Tab
                _selected={{ color: txtcolor, bg: color }}
                onClick={viderInputs}
              >
                <Text fontSize={{ base: 12, lg: 15 }}>Partner's Students</Text>
              </Tab>
            </TabList>
            <TabPanels shadow={"md"} rounded={"lg"}>
              <TabPanel bg={"gray.50"} shadow={"md"} rounded={"lg"}>
                <Stack
                  w={"full"}
                  maxW="full"
                  // shadow={"md"}
                  // bg={useColorModeValue("gray.50", "gray.700")}
                  // rounded={"lg"}
                  p={{ base: 2, md: 5 }}
                // my={5}
                >
                  {/* Search input */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ base: "column", lg: "row" }}
                  >
                    <Flex w={"100%"}>
                      <Select
                        borderRadius="5"
                        size="sm"
                        fontSize={{ base: 13, lg: 16 }}
                        w={{ base: "50%", lg: "25%" }}
                        mr="5"
                        mb={4}
                        value={selectedEtat}
                        onChange={(e) =>
                          setSelectedEtat(
                            e.target.value === ""
                              ? null
                              : e.target.value === "true"
                          )
                        }
                      >
                        <option value="">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Disabled</option>
                      </Select>
                      <Select
                        borderRadius="5"
                        size="sm"
                        fontSize={{ base: 13, lg: 16 }}
                        w={{ base: "50%", lg: "25%" }}
                        mr={{ base: 0, md: 5 }}
                        mb={4}
                        value={selectedOption}
                        onChange={(e) =>
                          setSelectedOption(
                            e.target.value === "" ? "" : e.target.value
                          )
                        }
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="confirmed">Confirmed</option>
                        <option value="rejected">Rejected</option>
                      </Select>
                    </Flex>

                    <InputGroup size="sm" w={{ base: "100%", lg: "40%" }}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                      />
                      <Input
                        borderRadius="5"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        mb={4}
                        sx={{
                          marginLeft: "auto",
                        }}
                      />{" "}
                    </InputGroup>
                  </Flex>
                  {selectedStudents.length > 0 && (
                    <Flex align={'center'} w={"30%"} gap={2} flexDirection={"row"}>
                      <Select
                        placeholder="Select the member"
                        onChange={(e) => setSelectedTeamM(e.target.value)}
                      >
                        {teamMembers
                          .filter(
                            (row) =>
                              row.equipe === "ConfirmationTeam" &&
                              row.type_membre !== "CHEFEQUIPE"
                          )
                          .map((val, key) => {
                            return (
                              <option value={val.id}>
                                {val.first_name} {val.last_name}
                              </option>
                            );
                          })}
                      </Select>
                      <Button onClick={UpdateAssignmentAll}>assign</Button>
                      <IconButton
                        onClick={() => setSelectedStudents([])}
                        aria-label="Send email"
                        backgroundColor={'transparent'}
                        _hover={'none'}
                        px={0}
                        size={'sm'}
                        icon={<MdOutlineClose color="red" fontSize={"20px"} />}
                      />
                    </Flex>
                  )}

                  <TableContainer rounded={10} p="5">
                    <Table size="sm" variant="simple">
                      <Thead size="sm">
                        <Tr fontSize="12">
                          {(user.role === "CHEFEQUIPE" ||
                            user.role === "SUPERADMIN") && (
                              <Th
                                borderColor={borderColor}
                                fontFamily="Plus Jakarta Display"
                                fontSize="12"
                              >
                                *
                              </Th>)}
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Image
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Code
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            First name
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            last name
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Email
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Phone number
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Address
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Student N°
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Status
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Confirmation status
                          </Th>

                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Creation date
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Application
                          </Th>
                          {(user.role === "CHEFEQUIPE" ||
                            user.role === "SUPERADMIN") && (
                              <Th
                                borderColor={borderColor}
                                fontFamily="Plus Jakarta Display"
                                fontSize="12"
                              >
                                Assignment
                              </Th>
                            )}
                          {user.role !== "ADMIN" && (
                            <Th
                              borderColor={borderColor}
                              fontFamily="Plus Jakarta Display"
                              fontSize="12"
                            >
                              Options
                            </Th>
                          )}
                        </Tr>
                      </Thead>

                      <Tbody>
                        {filteredData()
                          .filter(
                            (row) =>
                              selectedEtat === null ||
                              (row.etat === selectedEtat &&
                                (selectedOption === "" ||
                                  row.is_confirmed === selectedOption))
                          )
                          .slice(
                            currentPage * pageSize,
                            currentPage * pageSize + pageSize
                          )
                          .map((row) => (
                            <Tr key={row.id}>
                              {(user.role === "CHEFEQUIPE" ||
                                user.role === "SUPERADMIN") && (
                                  <Td>
                                    <Checkbox
                                      isChecked={selectedStudents.includes(row.id)}
                                      onChange={() =>
                                        toggleStudentSelection(row.id)
                                      }
                                    />
                                  </Td>)}
                              <Td borderColor={borderColor}>
                                {" "}
                                <Avatar
                                  size="md"
                                  borderRadius="10px"
                                  src={row.image}
                                />
                              </Td>
                              <Td borderColor={borderColor}>{row.code}</Td>
                              <Td borderColor={borderColor}>
                                {row.first_name}
                              </Td>
                              <Td borderColor={borderColor}>{row.last_name}</Td>
                              <Td borderColor={borderColor}>{row.email}</Td>
                              <Td borderColor={borderColor}>{row.tel}</Td>
                              <Td borderColor={borderColor}>
                                {row.address ? row.address : "____"}
                              </Td>
                              <Td borderColor={borderColor}>
                                {row.nb_student}
                              </Td>

                              <Td borderColor={borderColor}>
                                <Flex>
                                  {user.role !== "ADMIN" && (
                                    <Stack direction="row" mr="3px">
                                      <Switch
                                        colorScheme="green"
                                        isChecked={row.etat}
                                        onChange={() =>
                                          handleEtatClick(row.id, row.etat)
                                        }
                                      />
                                    </Stack>
                                  )}
                                  <Badge
                                    size="sm"
                                    rounded={"lg"}
                                    colorScheme={!row.etat ? "red" : "green"}
                                  >
                                    {row.etat ? "Activated" : "disable"}
                                  </Badge>
                                </Flex>
                              </Td>
                              {teamMembers?.find((e) => e.id === user.id)
                                ?.equipe === "ConfirmationTeam" ? (
                                <Td borderColor={borderColor}>
                                  {edit === row.id ? (
                                    <Flex gap={1} align={"center"}>
                                      <Select
                                        borderRadius="5"
                                        size="sm"
                                        fontSize={{ base: 13, lg: 16 }}
                                        value={selectedOpt}
                                        onChange={(e) =>
                                          setSelectedOpt(
                                            e.target.value === ""
                                              ? null
                                              : e.target.value
                                          )
                                        }
                                      >
                                        <option value="pending">Pending</option>
                                        <option value="confirmed">
                                          Confirmed
                                        </option>
                                        <option value="rejected">
                                          Rejected
                                        </option>
                                      </Select>
                                      <MdOutlineClose
                                        color="red"
                                        cursor={"pointer"}
                                        onClick={() => setEdit(-1)}
                                        fontSize={"23px"}
                                      />
                                      <FaCheck
                                        color="green"
                                        cursor={"pointer"}
                                        onClick={() => updateConf(row.id)}
                                        fontSize={"17px"}
                                      />
                                    </Flex>
                                  ) : (
                                    <Flex>
                                      {row.is_confirmed}{" "}
                                      <FiEdit3
                                        onClick={() => {
                                          setEdit(row.id);
                                        }}
                                        color="green"
                                        style={{
                                          marginLeft: 10,
                                          cursor: "pointer",
                                        }}
                                        fontSize={"16px"}
                                      />
                                    </Flex>
                                  )}
                                </Td>
                              ) : (
                                <Td borderColor={borderColor}>
                                  {row.is_confirmed}
                                </Td>
                              )}

                              <Td borderColor={borderColor}>
                                {new Date(row.date_creation).toLocaleDateString(
                                  "en-US"
                                )}
                              </Td>

                              <Td borderColor={borderColor}>
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(`/student-applications`, {
                                      state: {
                                        idStudent: row.id,
                                      },
                                    })
                                  }
                                  mr="5px"
                                  variant={"outline"}
                                  colorScheme="yellow"
                                >
                                  {" "}
                                  <VscFileSubmodule fontSize={"16px"} />{" "}
                                  Application
                                </Button>
                              </Td>
                              {(user.role === "CHEFEQUIPE" ||
                                user.role === "SUPERADMIN") && (
                                  <Td borderColor={borderColor}>
                                    {row.assigned_to ? (
                                      <Flex
                                        flexDirection={"row"}
                                        align={"center"}
                                      >
                                        {
                                          teamMembers.find(
                                            (e) => e.id === row.assigned_to
                                          )?.first_name
                                        }{" "}
                                        {
                                          teamMembers.find(
                                            (e) => e.id === row.assigned_to
                                          )?.last_name
                                        }
                                        <CiEdit
                                          cursor={"pointer"}
                                          style={{
                                            marginLeft: 5,
                                            fontSize: "16px",
                                            color: "green",
                                          }}
                                          onClick={() => {
                                            // setFullName(`${row.first_name} ${row.last_name}`)
                                            // setSelectedLead(row.chef_equipe)
                                            // setTeamId(row.id);
                                            // setIsModalOpen(true);
                                            setSelectedStudent(row);
                                            setSelectedStudentId(row.id);
                                            onOpen();
                                            setMbEquipe(
                                              teamMembers.find(
                                                (mb) => mb.id === row.assigned_to
                                              )?.equipe
                                            );
                                            // setMbEquipeId(`${teamMembers.find((mb) => mb.id === row.assigned_to)?.first_name} ${teamMembers.find((mb) => mb.id === row.assigned_to)?.last_name}`)
                                            setMbEquipeId(
                                              teamMembers.find(
                                                (mb) => mb.id === row.assigned_to
                                              )?.id
                                            );

                                            setChangeModal(false);
                                          }}
                                        />
                                      </Flex>
                                    ) : (
                                      <Link
                                        color="gray"
                                        onClick={() => {
                                          setSelectedStudent(row);
                                          setSelectedStudentId(row.id);
                                          setChangeModal(true);
                                          onOpen();
                                        }}
                                      >
                                        assign to a team member{" "}
                                      </Link>
                                    )}
                                    {/* <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            setSelectedStudent(row)
                                                            setSelectedStudentId(row.id)
                                                            onOpen()

                                                        }}
                                                        mr="5px"
                                                        variant={"outline"}
                                                        colorScheme="blue"
                                                        > Assign to
                                                    </Button> */}
                                  </Td>
                                )}
                              {user.role !== "ADMIN" && (
                                <Td borderColor={borderColor}>
                                  {/* <IconButton
                                         size="sm"
                                         onClick={() => navigate(`/editResponsable`, {
                                             state: {
                                                 idResponsable: row.id
                                             }
                                         })}
                                         mr="5px"
                                         colorScheme="green"
                                         icon={<EditIcon />}>
                                     </IconButton> */}
                                  {/* <Popover placement="left" >
                                         <PopoverTrigger>
                                             <IconButton
                                                 size="sm"
                                                 mr="5px"
                                                 bg="gray.300"
                                                 color={"black"}
                                                 icon={<MdHistory />}>
                                             </IconButton>
                                         </PopoverTrigger>
                                         <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                                             <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                 Historique
                                             </PopoverHeader>
                                             <PopoverArrow bg="#497dff" />
                                             <PopoverCloseButton />
                                             <PopoverBody>
                                                 <div style={{ marginBottom: '8px' }}>
                                                     Ajouté par : {row.added_by}
                                                 </div>
                                                 <div style={{ marginBottom: '8px' }}>
                                                     Modifié par : {row.edited_by}
                                                 </div>

                                                 <div>
                                                     last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                                 </div>
                                             </PopoverBody>
                                             <PopoverHeader pt={4} fontWeight="bold" border="0">
                                             </PopoverHeader>
                                         </PopoverContent>
                                         
                                     </Popover> */}

                                  <IconButton
                                    size="sm"
                                    onClick={() =>
                                      navigate(`/edit-student`, {
                                        state: {
                                          idStudent: row.id,
                                        },
                                      })
                                    }
                                    mr="5px"
                                    variant="outline"
                                    colorScheme="whatsapp"
                                    icon={<FiEdit3 fontSize={"16px"} />}
                                  ></IconButton>

                                  <IconButton
                                    size="sm"
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      onOpenAlert();
                                    }}
                                    mr="5px"
                                    colorScheme="red"
                                    icon={<MdGroupRemove fontSize={"18px"} />}
                                  ></IconButton>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        {filteredData().length === 0 && (
                          <Tr>
                            <Td borderColor={borderColor} colSpan={15}>
                              No matching rows were found.
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                      {/* } */}
                    </Table>
                  </TableContainer>
                  {/* Pagination */}
                  <Flex justify="space-between" align="center" w="100%">
                    <Box flex="1">
                      <MyPagination
                        data={filteredData()}
                        searchInput={searchTerm}
                        PAGE_SIZE={pageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Box>

                    <Select
                      w="70px"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      {/* Add more options as needed */}
                    </Select>
                  </Flex>
                </Stack>
              </TabPanel>
              <TabPanel bg={"gray.50"} shadow={"md"} rounded={"lg"}>
                <Stack
                  w={"full"}
                  maxW="full"
                  // shadow={"md"}
                  // bg={useColorModeValue("gray.50", "gray.700")}
                  // rounded={"lg"}
                  p={6}
                // my={5}
                >
                  {/* Search input */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ base: "column", lg: "row" }}
                  >
                    <Flex w={"100%"}>
                      <Select
                        borderRadius="5"
                        size="sm"
                        fontSize={{ base: 13, lg: 16 }}
                        w={{ base: "30%", lg: "25%" }}
                        mr={5}
                        mb={4}
                        value={selectedEtat}
                        onChange={(e) =>
                          setSelectedEtat(
                            e.target.value === ""
                              ? null
                              : e.target.value === "true"
                          )
                        }
                      >
                        <option value="">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Disabled</option>
                      </Select>
                      <Select
                        borderRadius="5"
                        size="sm"
                        fontSize={{ base: 13, lg: 16 }}
                        w={{ base: "30%", lg: "25%" }}
                        mr={5}
                        mb={4}
                        value={selectedOption}
                        onChange={(e) =>
                          setSelectedOption(
                            e.target.value === "" ? null : e.target.value
                          )
                        }
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="confirmed">Confirmed</option>
                        <option value="rejected">Rejected</option>
                      </Select>
                    </Flex>

                    <InputGroup size="sm" w={{ base: "70%", lg: "40%" }}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                      />
                      <Input
                        borderRadius="5"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        mb={4}
                        sx={{
                          marginLeft: "auto",
                        }}
                      />{" "}
                    </InputGroup>
                  </Flex>
                  {selectedStudents.length > 0 && (
                    <Flex align={'center'} w={"30%"} gap={2} flexDirection={"row"}>
                      <Select
                        placeholder="Select the member"
                        onChange={(e) => setSelectedTeamM(e.target.value)}
                      >
                        {teamMembers
                          .filter(
                            (row) =>
                              row.equipe === "ConfirmationTeam" &&
                              row.type_membre !== "CHEFEQUIPE"
                          )
                          .map((val, key) => {
                            return (
                              <option value={val.id}>
                                {val.first_name} {val.last_name}
                              </option>
                            );
                          })}
                      </Select>
                      <Button onClick={UpdateAssignmentAll}>assign</Button>
                      <IconButton
                        onClick={() => setSelectedStudents([])}
                        aria-label="Send email"
                        backgroundColor={'transparent'}
                        _hover={'none'}
                        px={0}
                        size={'sm'}
                        icon={<MdOutlineClose color="red" fontSize={"20px"} />}
                      />
                    </Flex>
                  )}
                  <TableContainer rounded={10} p="5">
                    <Table size="sm" variant="simple">
                      <Thead size="sm">
                        <Tr fontSize="12">
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            *
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Image
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Code
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            First name
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            last name
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Email
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Phone number
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Address
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Student N°
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Status
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Confirmation status
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Partner
                          </Th>

                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Creation date
                          </Th>
                          <Th
                            borderColor={borderColor}
                            fontFamily="Plus Jakarta Display"
                            fontSize="12"
                          >
                            Application
                          </Th>
                          {(user.role === "CHEFEQUIPE" ||
                            user.role === "SUPERADMIN") && (
                              <Th
                                borderColor={borderColor}
                                fontFamily="Plus Jakarta Display"
                                fontSize="12"
                              >
                                Assignment
                              </Th>
                            )}
                          {user.role !== "ADMIN" && (
                            <Th
                              borderColor={borderColor}
                              fontFamily="Plus Jakarta Display"
                              fontSize="12"
                            >
                              Options
                            </Th>
                          )}
                        </Tr>
                      </Thead>

                      <Tbody>
                        {filteredDataPartner()
                          .filter(
                            (row) =>
                              selectedEtat === null ||
                              (row.etat === selectedEtat &&
                                (selectedOption === "" ||
                                  row.is_confirmed === selectedOption))
                          )
                          .slice(
                            currentPage * pageSize,
                            currentPage * pageSize + pageSize
                          )
                          .map((row) => (
                            <Tr key={row.id}>
                              <Td>
                                <Checkbox
                                  isChecked={selectedStudents.includes(row.id)}
                                  onChange={() =>
                                    toggleStudentSelection(row.id)
                                  }
                                />
                              </Td>
                              <Td borderColor={borderColor}>
                                {" "}
                                <Avatar
                                  size="md"
                                  borderRadius="10px"
                                  src={row.image}
                                />
                              </Td>
                              <Td borderColor={borderColor}>{row.code}</Td>
                              <Td borderColor={borderColor}>
                                {row.first_name}
                              </Td>
                              <Td borderColor={borderColor}>{row.last_name}</Td>
                              <Td borderColor={borderColor}>{row.email}</Td>
                              <Td borderColor={borderColor}>{row.tel}</Td>
                              <Td borderColor={borderColor}>
                                {row.address ? row.address : "____"}
                              </Td>
                              <Td borderColor={borderColor}>
                                {row.nb_student}
                              </Td>

                              <Td borderColor={borderColor}>
                                <Flex>
                                  {user.role !== "ADMIN" && (
                                    <Stack direction="row" mr="3px">
                                      <Switch
                                        colorScheme="green"
                                        isChecked={row.etat}
                                        onChange={() =>
                                          handleEtatClick(row.id, row.etat)
                                        }
                                      />
                                    </Stack>
                                  )}
                                  <Badge
                                    size="sm"
                                    rounded={"lg"}
                                    colorScheme={!row.etat ? "red" : "green"}
                                  >
                                    {row.etat ? "Activated" : "disable"}
                                  </Badge>
                                </Flex>
                              </Td>
                              {teamMembers?.find((e) => e.id === user.id)
                                ?.equipe === "ConfirmationTeam" ? (
                                <Td borderColor={borderColor}>
                                  {edit === row.id ? (
                                    <Flex gap={1} align={"center"}>
                                      <Select
                                        borderRadius="5"
                                        size="sm"
                                        fontSize={{ base: 13, lg: 16 }}
                                        value={selectedOpt}
                                        onChange={(e) =>
                                          setSelectedOpt(
                                            e.target.value === ""
                                              ? null
                                              : e.target.value
                                          )
                                        }
                                      >
                                        <option value="pending">Pending</option>
                                        <option value="confirmed">
                                          Confirmed
                                        </option>
                                        <option value="rejected">
                                          Rejected
                                        </option>
                                      </Select>
                                      <MdOutlineClose
                                        color="red"
                                        cursor={"pointer"}
                                        onClick={() => setEdit(-1)}
                                        fontSize={"23px"}
                                      />
                                      <FaCheck
                                        color="green"
                                        cursor={"pointer"}
                                        onClick={() => updateConf(row.id)}
                                        fontSize={"17px"}
                                      />
                                    </Flex>
                                  ) : (
                                    <Flex>
                                      {row.is_confirmed}{" "}
                                      <FiEdit3
                                        onClick={() => {
                                          setEdit(row.id);
                                        }}
                                        color="green"
                                        style={{
                                          marginLeft: 10,
                                          cursor: "pointer",
                                        }}
                                        fontSize={"16px"}
                                      />
                                    </Flex>
                                  )}
                                </Td>
                              ) : (
                                <Td borderColor={borderColor}>
                                  {row.is_confirmed}
                                </Td>
                              )}
                              <Td borderColor={borderColor}>
                                {
                                  partenaires.find(
                                    (e) => e.id === row.partener_related
                                  )?.first_name
                                }{" "}
                                {
                                  partenaires.find(
                                    (e) => e.id === row.partener_related
                                  )?.last_name
                                }
                              </Td>

                              <Td borderColor={borderColor}>
                                {new Date(row.date_creation).toLocaleDateString(
                                  "en-US"
                                )}
                              </Td>

                              <Td borderColor={borderColor}>
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(`/student-applications`, {
                                      state: {
                                        idStudent: row.id,
                                      },
                                    })
                                  }
                                  mr="5px"
                                  variant={"outline"}
                                  colorScheme="yellow"
                                >
                                  {" "}
                                  <VscFileSubmodule fontSize={"16px"} />{" "}
                                  Application
                                </Button>
                              </Td>
                              {(user.role === "CHEFEQUIPE" ||
                                user.role === "SUPERADMIN") && (
                                  <Td borderColor={borderColor}>
                                    {row.assigned_to ? (
                                      <Flex
                                        flexDirection={"row"}
                                        align={"center"}
                                      >
                                        {
                                          teamMembers.find(
                                            (e) => e.id === row.assigned_to
                                          )?.first_name
                                        }{" "}
                                        {
                                          teamMembers.find(
                                            (e) => e.id === row.assigned_to
                                          )?.last_name
                                        }
                                        <CiEdit
                                          cursor={"pointer"}
                                          style={{
                                            marginLeft: 5,
                                            fontSize: "16px",
                                            color: "green",
                                          }}
                                          onClick={() => {
                                            // setFullName(`${row.first_name} ${row.last_name}`)
                                            // setSelectedLead(row.chef_equipe)
                                            // setTeamId(row.id);
                                            // setIsModalOpen(true);
                                            setSelectedStudent(row);
                                            setSelectedStudentId(row.id);
                                            onOpen();
                                            setMbEquipe(
                                              teamMembers.find(
                                                (mb) => mb.id === row.assigned_to
                                              )?.equipe
                                            );
                                            // setMbEquipeId(`${teamMembers.find((mb) => mb.id === row.assigned_to)?.first_name} ${teamMembers.find((mb) => mb.id === row.assigned_to)?.last_name}`)
                                            setMbEquipeId(
                                              teamMembers.find(
                                                (mb) => mb.id === row.assigned_to
                                              )?.id
                                            );

                                            setChangeModal(false);
                                          }}
                                        />
                                      </Flex>
                                    ) : (
                                      <Link
                                        color="gray"
                                        onClick={() => {
                                          setSelectedStudent(row);
                                          setSelectedStudentId(row.id);
                                          setChangeModal(true);
                                          onOpen();
                                        }}
                                      >
                                        assign to a team member{" "}
                                      </Link>
                                    )}
                                    {/* <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            setSelectedStudent(row)
                                                            setSelectedStudentId(row.id)
                                                            onOpen()

                                                        }}
                                                        mr="5px"
                                                        variant={"outline"}
                                                        colorScheme="blue"
                                                        > Assign to
                                                    </Button> */}
                                  </Td>
                                )}
                              {user.role !== "ADMIN" && (
                                <Td borderColor={borderColor}>
                                  {/* <IconButton
                                         size="sm"
                                         onClick={() => navigate(`/editResponsable`, {
                                             state: {
                                                 idResponsable: row.id
                                             }
                                         })}
                                         mr="5px"
                                         colorScheme="green"
                                         icon={<EditIcon />}>
                                     </IconButton> */}
                                  {/* <Popover placement="left" >
                                         <PopoverTrigger>
                                             <IconButton
                                                 size="sm"
                                                 mr="5px"
                                                 bg="gray.300"
                                                 color={"black"}
                                                 icon={<MdHistory />}>
                                             </IconButton>
                                         </PopoverTrigger>
                                         <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                                             <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                 Historique
                                             </PopoverHeader>
                                             <PopoverArrow bg="#497dff" />
                                             <PopoverCloseButton />
                                             <PopoverBody>
                                                 <div style={{ marginBottom: '8px' }}>
                                                     Ajouté par : {row.added_by}
                                                 </div>
                                                 <div style={{ marginBottom: '8px' }}>
                                                     Modifié par : {row.edited_by}
                                                 </div>

                                                 <div>
                                                     last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                                 </div>
                                             </PopoverBody>
                                             <PopoverHeader pt={4} fontWeight="bold" border="0">
                                             </PopoverHeader>
                                         </PopoverContent>
                                         
                                     </Popover> */}
                                  <IconButton
                                    size="sm"
                                    onClick={() =>
                                      navigate(`/edit-student`, {
                                        state: {
                                          idStudent: row.id,
                                        },
                                      })
                                    }
                                    mr="5px"
                                    variant="outline"
                                    colorScheme="whatsapp"
                                    icon={<FiEdit3 fontSize={"16px"} />}
                                  ></IconButton>

                                  <IconButton
                                    size="sm"
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      onOpenAlert();
                                    }}
                                    mr="5px"
                                    colorScheme="red"
                                    icon={<MdGroupRemove fontSize={"18px"} />}
                                  ></IconButton>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        {filteredDataPartner().length === 0 && (
                          <Tr>
                            <Td borderColor={borderColor} colSpan={15}>
                              No matching rows were found.
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                      {/* } */}
                    </Table>
                  </TableContainer>
                  {/* Pagination */}
                  <Flex justify="space-between" align="center" w="100%">
                    <Box flex="1">
                      <MyPagination
                        data={filteredDataPartner()}
                        searchInput={searchTerm}
                        PAGE_SIZE={pageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Box>

                    <Select
                      w="70px"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      {/* Add more options as needed */}
                    </Select>
                  </Flex>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Stack>

      <AlertDialog
        size={"sm"}
        closeOnOverlayClick={false}
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Are you sure? You won't be able to undo this action later.
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                size={"sm"}
                ref={cancelRef}
                onClick={() => {
                  setMessage(false);
                  onCloseAlert();
                }}
              >
                Close
              </Button>
              <Button
                size={"sm"}
                onClick={() => handleDelete(deleteId)}
                colorScheme="red"
                ml={3}
                isDisabled={message}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {changeModal ? (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setMessage("");
            setSelectedTeam("");
            setSelectedStudent("");
            setSelectedStudentId("");
            setSelectedTeamM("");
          }}
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>Confirmation team member assignment</ModalHeader>

            <ModalCloseButton />
            <ModalBody>
              {message && (
                <Alert
                  mb={2}
                  fontSize={"sm"}
                  padding={2}
                  variant={"left-accent"}
                  status="error"
                  rounded="md"
                >
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Flex flexDirection={"column"} align={"center"}>
                <Flex w="100%" flexDirection={"column"}>
                  <Flex w="100%" flexDirection={"column"}>
                    {/* <FormControl id="team" isRequired >
                                            <FormLabel ml={0.5}>Pick the team</FormLabel>
                                            <Select placeholder="Select the  team" onChange={(e) => setSelectedTeam(e.target.value)}>
                                                <option value="SuiviTeam">Tracking team</option>
                                                <option value="ConfirmationTeam">Confirmation team</option>
                                            </Select>
                                        </FormControl> */}

                    {/* {selectedTeam && */}
                    <FormControl id="team" isRequired>
                      <FormLabel ml={0.5}>Pick a member </FormLabel>
                      <Select
                        placeholder="Select the member"
                        onChange={(e) => setSelectedTeamM(e.target.value)}
                      >
                        {teamMembers
                          .filter(
                            (row) =>
                              row.equipe === "ConfirmationTeam" &&
                              row.type_membre !== "CHEFEQUIPE"
                          )
                          .map((val, key) => {
                            return (
                              <option value={val.id}>
                                {val.first_name} {val.last_name}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>
                    {/* } */}
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => UpdateAssignment(selectedStudentId)}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setMessage("");
            setSelectedTeam("");
            setMbEquipe();
            setMbEquipeId();
          }}
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>Assignment edit</ModalHeader>

            <ModalCloseButton />
            <ModalBody>
              {message && (
                <Alert
                  mb={2}
                  fontSize={"sm"}
                  padding={2}
                  variant={"left-accent"}
                  status="error"
                  rounded="md"
                >
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Flex flexDirection={"column"} align={"center"}>
                <Flex w="100%" flexDirection={"column"}>
                  <Flex w="100%" flexDirection={"column"}>
                    <FormControl id="team" isRequired>
                      <FormLabel ml={0.5}>Pick a member </FormLabel>
                      <Select
                        onChange={(e) => setSelectedTeamM(e.target.value)}
                      >
                        <option value={mbEquipeId}>
                          {
                            teamMembers.find((mb) => mb.id === mbEquipeId)
                              ?.first_name
                          }{" "}
                          {
                            teamMembers.find((mb) => mb.id === mbEquipeId)
                              ?.last_name
                          }
                        </option>

                        {teamMembers
                          .filter(
                            (row) =>
                              row.equipe === "ConfirmationTeam" &&
                              row.id !== mbEquipeId &&
                              row.type_membre !== "CHEFEQUIPE"
                          )
                          .map((val, key) => {
                            return (
                              <option value={val.id}>
                                {val.first_name} {val.last_name}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => UpdateAssignment(selectedStudentId)}
              >
                edit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default GestStudents;
