import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, background, useColorModeValue, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../assets/images/loginS.png";
import { IoCloudUploadOutline } from 'react-icons/io5';
import upload from "../../../assets/images/upload.png";
import zip from "../../../assets/images/zip.png";
import axiosClient from '../../../axios-client';
import { IoIosCloseCircle } from "react-icons/io";
import ReactCardSlider from "react-card-slider-component";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import Footer from '../../../components/footer/Footer';

function NewOffers() {
  const { student } = useStateContext();
  const navigate = useNavigate()
  const toast = useToast()

  const inputRef = useRef < HTMLInputElement | null > (null)
  const [selectedMajor, setSelectedMajor] = useState('')
  const [selectedDegree, setSelectedDegree] = useState('')
  const [selectedScholarship, setSelectedScholarship] = useState('')
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [message, setMessage] = useState('')
  const [offers, setOffers] = useState([])


  useEffect(() => {
    axiosClient.get('offres/')
      .then((res) => {
        setOffers(res.data.sort().reverse())
      })
      .catch((error) => {
        console.error(error);
      });

  }, [])
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setSlidesPerView(1);
      }
      else if (window.innerWidth < 700) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 1250) {
        setSlidesPerView(2);
      }
       else {
        setSlidesPerView(3);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box >
      {/* <Box w={"100%"}  mb={2}>
        <Text as="cite" fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span onClick={() => navigate('/students')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Students</span> / <span> Add a student</span></Text>
      </Box> */}
      <Flex
        w={"100%"}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'center', md: 'center' }}
        justify={"left"}
        p={10}
        px={{base:50,lg:50,xl:100}}
        height={"500px"}
      >
        <Image  h={"80%"} w={"30%"} rounded={"0px 100px 0px 100px"} src={china} display={{base:"none", lg:"fixed"}}></Image>
        < Flex justify={'center'} align={"center"} px={{base:0,md:0,lg:10}} h={"100%"} py={10} w={{base:"100%",lg:"70%"}} >
          <Swiper

            style={{ height: "100%", width: "100%", paddingLeft: 15, paddingRight: 15 }}
            spaceBetween={30}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {offers.map((row) => (

              <SwiperSlide style={{ height: "100%", display: "flex", alignItems: "center" }} >
                <Card shadow={"xl"}  minHeight={"250px"}  minWidth={"250px"}>
                  <CardHeader pb={0}>
                    <Heading size={{base:'sm' , md:"md"}}> {row.nom}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{row.description}</Text>
                    <Flex justify={"end"}>
                      <Text >{row.prix} DH</Text>
                    </Flex>
                  </CardBody>


                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>

      </Flex>


      <Footer />
    </Box >

  )
}

export default NewOffers