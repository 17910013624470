import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import axiosClient from '../../axios-client';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPasswordStudent = () => {

  const [email, setEmail] = useState("")
  const toast = useToast()
  const navigate = useNavigate()

  const { token } = useParams();
  
  const onSubmit = ev => {
    toast.closeAll()
    ev.preventDefault();
    if(email === "") {
      toast({
        description: "Enter your email address",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "subtle"
      });
    } else {
      axiosClient.get('reset_email/', { params: { email: email } })
      .then((response) => {
        if(response.status === 203) {
          toast({
            description: response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
            variant: "subtle"
          });
        } else {
          toast({
            description: "A password reset email was sent successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            variant: "subtle"
          });
          navigate('/login-student')
        }
      
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  
  return (
    <Flex
      minH={'100vh'}

      align={'center'}
      justify={'center'}
      >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        rounded={'xl'}
        boxShadow={'2xl'}
        p={6}
        my={12}>
        <Heading color={"red.700"} lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot password ?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.500', 'gray.500')}>
          Enter your email address
        </Text>
        <FormControl id="email">
          <Input
            name='email'
            placeholder="votre-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <Stack spacing={6}>
          <Button 
          name='submit'
            type='submit'
            onClick={onSubmit}
            color={'white'}
            _hover={'none'}
            backgroundColor={'gray.500'}>
            Request a password reset
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default ResetPasswordStudent