import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, background, useColorModeValue, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../../assets/images/china4.jpg";
import axiosClient from '../../../../axios-client';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
function MajorsPartner() {

  const [majors, setMajors] = useState([])

 
  useEffect(() => {
    axiosClient.get('programmes/')
      .then((res) => {
        setMajors(res.data.sort().reverse())
      })
      .catch((error) => {
        console.error(error);
      });

  }, [])
 
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      }
      else if (window.innerWidth < 900) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 1200) {
        setSlidesPerView(3);
      }
      else {
        setSlidesPerView(4);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box >
      
      <Flex
        w={"100%"}
        rounded={"0px 100px 0px 100px"}
        bgRepeat={"no-repeat"}
        backgroundSize={'cover'}
        backgroundImage={china} minH={"250px"}
        backgroundPosition={"center"}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'right' }}
        justify={"center"}
        
      >
        {/* <Flex w={"100%"} h="250px" position='absolute' backgroundColor="black" opacity="0.4" ></Flex> */}
        <Flex zIndex="1" align={"center"} justify={"center"} flexDirection={"column"} >
        <Text color={"white"} fontWeight='bold'  fontSize={{base:'xl',md:"2xl", lg:"3xl"}} backgroundColor={"#00000066"}>Specializations Available For Studying In China
        </Text>
        </Flex>

      </Flex>
     
      {majors.length >= 4 &&
        < Flex mt={10} px={{base:10,lg:20}} h={"300px"}  w={"100%"} >
          <Swiper
            spaceBetween={30}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {majors.map((row) => (

              <SwiperSlide >
                <Card shadow={"xl"} minHeight={"250px"}>
                  <CardHeader pb={0}>
                    <Heading size={{base:'sm' , lg:"md"}}> {row.nom}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{row.description}</Text>
                  </CardBody>

                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>}
    </Box >

  )
}

export default MajorsPartner