import { Alert, AlertIcon, Avatar, AvatarBadge, Box, Button, Card, CardBody, CardHeader, DarkMode, Divider, Flex, Grid, Icon, Input, InputGroup, InputRightElement, Select, Stack, Text, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineSecurity, MdSettings } from 'react-icons/md'
import { FaCube, FaPenFancy, FaPencilAlt } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'
import { PiGenderFemaleBold } from 'react-icons/pi'
import { IoMdMale, IoMdFemale } from 'react-icons/io'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient, { linkImage } from '../../../axios-client';
import { Navigate, useNavigate } from 'react-router-dom'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
// import countryData from '../../assets/dataJson/countries.json';
import axios from 'axios'

function EditProfileAdmin() {
    const { user } = useStateContext();
    const navigate = useNavigate()
    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState()
    const { Cuser, setCuser } = useStateContext();
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;

    //toast variable
    const toast = useToast()
    //password variables
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageP, setMessageP] = useState('');

    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);

    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [listAdmins, setListAdmins] = useState([])
    const [selectedCountry, setSelectedCountry] = useState({});
    const [countries, setCountries] = useState([]);
    const [messageE, setMessageE] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    
    const [tel, setTel] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;



    const handleChangeImage = (e) => {
        const image = e.target.files[0]
        setSelectedImage(image)
    }
    useEffect(() => {
        if (selectedImage) {
            setImage(URL.createObjectURL(selectedImage))
        }
        else {
            setSelectedImage()
        }
        axiosClient
            .get("/admins/")
            .then((res) => setListAdmins(res.data));

    }, [selectedImage]);

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);

    // function pays(p) {
    //     let t
    //     t = {
    //         value: 1,
    //         label: p
    //     }

    //     return t
    // }
    const isEmpty = () => {
        return !lastName || !firstName;
    };
    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////


    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push("a special Character");
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push("a lowercase letter");
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push("an uppercase letter");
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push("a number");
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push("a minimum length of 8 characters");
        }

        if (missingRequirements.length > 0) {
            const missingText = `You need ${missingRequirements.join(", ")} in your password.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength('The password is correct !');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('The password must be at least 8 characters long.');
        }

        if (!digitRegex.test(password)) {
            errors.push('The password must contain at least one number.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('The password must contain at least one lowercase letter.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('The password must contain at least one uppercase letter.');
        }
        if (!specialchar.test(password)) {
            errors.push('The password must contain at least one special character (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('The password must not exceed 20 characters.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`/change_password/${user.id}/`, {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                useradd: user.first_name + ' ' + user.last_name
            });
            if (response.data.success) {
                toast({
                    description: "le mot de passe est modifié avec succes",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                setConfirmPassword('')
                setNewPassword('')
                setOldPassword('')

            }
            setMessage(response.data.error);
        } catch (err) {
            setError(err.response.data.error);
        }
    };


    const handleSubmit = async (event) => {

        // if (user.code_postal && !codepRegex.test(user.code_postal)) {
        //     setMessage("Veuillez entrer un code postal valide.");
        //     return;
        // }
        console.log(firstName, lastName,  birthday, tel, address, selectedImage)
        const formData = new FormData();
        if (firstName) { formData.append("first_name", firstName); }
        if (lastName) { formData.append("last_name", lastName); }
        if (address) { formData.append("address", address); }
        
        if (tel) { formData.append("tel", tel); }
        if (selectedImage) formData.append("image", selectedImage);


        // if (firstName.trim() === "" || lastName.trim() === "" || (ville && ville.trim() === "") || (rue && rue.trim() === "") || (codeP && codeP.trim() === "")) {
        //     setMessage("Please fill in the fields correctly.");
        //     return;
        // }
        

        // const newList = listAdmins.filter((e) => e.id !== user.id);
        // if (newList.length > 0) {
        //     const check = newList.find((obj) => {
        //         return obj.email === user.email.trim();
        //     });
        //     if (check) {
        //         window.scrollTo(0, 0);
        //         return setMessage("This mail already exists!");
        //     }
        // }

        // Send the updated user data to the API

        try {
            const response = await axiosClient.put(`/update_user/${user.id}/`, formData)
            if (response.data.message) {
                toast({
                    description: "Your profile is successfuly updated",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent'

                });
                setMessageP('')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent'
            })
        }

    };
    const EditEmail = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        if (email) { formData.append("email", email); }

        const newList = listAdmins.filter((e) => e.id !== user.id);
        if (newList.length > 0) {
            const check = newList.find((obj) => {
                return obj.email === email.trim();
            });
            if (check) {
                window.scrollTo(0, 0);
                return setMessageE("This mail already exists!");
            }
        }
        if (!emailRegex.test(email)) {
            return setMessageE("Please enter a valid email.");

        }

        try {
            const response = await axiosClient.put(`/update_user_email/${user.id}/`, formData)
            if (response.data.message) {
                toast({
                    description: "The email is successfuly modified",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent'

                });
                setMessageE('')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent'
            })
        }
    }
    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '10px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'
                    >
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={image ? image : user.image ? linkImage + user.image : user.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                <label htmlFor="imageInput">
                                    <input
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleChangeImage}
                                    />
                                    <AvatarBadge
                                        cursor='pointer'
                                        borderRadius='8px'
                                        border='transparent'
                                        bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                        boxSize='26px'
                                        backdropFilter='blur(120px)'>
                                        <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                    </AvatarBadge>
                                </label>
                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                    <Text
                                        fontSize={{ sm: 'lg', lg: 'xl' }}
                                        color={useColorModeValue("gray.700", "white")}
                                        fontWeight='bold'
                                        ms={{ sm: '8px', md: '0px' }}
                                        mr={2}
                                    >
                                        {user.first_name} {user.last_name}
                                    </Text>
                                    {user.gender === "Femme" ? <IoMdFemale size={"22px"} color='#ff8096' /> : user.gender === "Homme" ? <IoMdMale size={"22px"} color='#5da4ff' /> : null}
                                </Flex>

                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {user.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex mt={{ base: 4, md: 0 }} direction={{ base: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}>
                            <Button
                                onClick={() => navigate('/profile')}
                                borderRadius='12px'
                                bg='transparent'
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={ImProfile} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    PROFILE
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg='#7e172c'
                                _hover={'none'}
                                onClick={() => navigate('/edit-profile')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    SETTINGS
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg='transparent'
                                onClick={() => navigate('/account-security')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={MdOutlineSecurity} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    ACCOUNT SECURITY
                                </Text>
                            </Button>
                            {/* <Button
                                borderRadius='12px'
                                bg='transparent'

                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={FaCube} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                    ELSE
                                </Text>
                            </Button> */}
                        </Flex>
                    </Flex>
                </Flex>
                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 1fr " }} px={5} gap='40px' >

                    <Card
                        bg={useColorModeValue("gray.100", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                Profile informations
                            </Text>
                        </CardHeader>
                        {messageP &&
                            <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                <AlertIcon />
                                {messageP}
                            </Alert>
                        }
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >
                                    <Flex flexDirection={'column'} align='center' w={{ base: '100%', md: "50%" }} mr={10}>
                                        <Text letterSpacing={1} w={'100%'} mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            First name  {' '}
                                        </Text>
                                        <Input onChange={(e) => setFirstName(e.target.value)} size={'md'} w={'100%'} placeholder='last name' defaultValue={user.first_name} type="text" name='last_name' id="last_name" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            background: "#dae0e947",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>
                                    <Flex flexDirection={'column'} align='center' w={{ base: '100%', md: "50%" }}>
                                        <Text letterSpacing={1} w={'100%'} mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            Last name  {' '}
                                        </Text>
                                        <Input onChange={(e) => setLastName(e.target.value)} size={'md'} w={'100%'} placeholder='first name' defaultValue={user.last_name} type="text" name='first_name' id="first_name" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            background: "#dae0e947",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                </Flex>
                                {/* <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        Nom complet {' '}
                                    </Text>
                                    <Input size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} placeholder='Phone number' defaultValue={user.tel} type="tel" name='tel' id="tel" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex> */}
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >

                                    <Flex w={{ base: '100%', md: "50%" }} flexDirection={'column'} align='center' mr={10} mb='14px'>
                                        <Text letterSpacing={1} w={'100%'} fontSize={{ sm: 'sm', md: 'md' }} mb={2} color={'gray.500'} me='10px'>
                                            Phone number {' '}
                                        </Text>
                                        <Input onChange={(e) => setTel(e.target.value)} size={'md'} w={'100%'} placeholder='Phone number' defaultValue={user.tel} type="tel" name='tel' id="tel" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            background: "#dae0e947",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                    <Flex w={{ base: '100%', md: "50%" }} flexDirection={'column'} align='center' mb='14px'>
                                        <Text mb={2} letterSpacing={1} w={'100%'} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            Birthday {' '}
                                        </Text>
                                        <Input onChange={(e) => setBirthday(e.target.value)} size={'md'} w={'100%'} defaultValue={user.birthday} type="date" name='birthday' id="birthday" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            background: "#dae0e947",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>
                                </Flex>

                            </Flex>
                        </CardBody>
                    </Card>
                    <Card

                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.800", "white")} fontWeight='bold'>
                                Address
                            </Text>
                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex align='left' mb='20px' direction={{ base: 'column', md: "row" }}  >
                                <Flex flexDirection={'column'} align='center' w={{ base: '100%', md: "100%" }} mr={10}>
                                    <Text letterSpacing={1} w={'100%'} mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue("gray.500", "gray.100")} me='10px'>
                                        Full address  {' '}
                                    </Text>
                                    <Input onChange={(e) => setAddress(e.target.value)} size={'md'} w={'100%'} placeholder='Enter the address' defaultValue={user.address} type="text" name='ville' id="ville" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>
                                {/* <Flex flexDirection={'column'} align='center' w={{ base: '100%', md: "50%" }}>
                                    <Text letterSpacing={1} w={'100%'} mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue("gray.500", "gray.100")} me='10px'>
                                        Rue  {' '}
                                    </Text>
                                    <Input onChange={(e) => setRue(e.target.value)} size={'md'} w={'100%'} placeholder='Entrez la rue' defaultValue={user.rue} type="text" name='rue' id="rue" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex> */}

                            </Flex>
                       
                        </CardBody>
                        <Stack spacing={2} direction={['column', 'row']} justifyContent="end" >
                            <Button
                                size={'sm'}
                                colorScheme="red"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <Button
                                size={'sm'}
                                type='submit'

                                onClick={handleSubmit}
                                colorScheme="gray">
                                Save
                            </Button>

                        </Stack>
                    </Card>

                </Grid>
            </Flex>
        </Box>

    )
}

export default EditProfileAdmin