import React, { useEffect } from 'react';
import {
  Box,
  HStack,
  Button,
} from "@chakra-ui/react";

function MyPaginantion({ data, searchInput, PAGE_SIZE, currentPage, setCurrentPage }) {
  const pageCount = Math.ceil(data.length / PAGE_SIZE);
  const MAX_VISIBLE_PAGES = 5; // Maximum number of visible page buttons.

  useEffect(() => {
    setCurrentPage(0);
  }, [searchInput]);

  const handlePageClick = (newPage) => {
    if (newPage >= 0 && newPage < pageCount) {
      setCurrentPage(newPage);
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    
    // Calculate the range of visible page buttons.
    let startPage = Math.max(0, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
    let endPage = Math.min(pageCount - 1, startPage + MAX_VISIBLE_PAGES - 1);
    
    // Adjust the startPage if the endPage is at the end.
    if (endPage === pageCount - 1) {
      startPage = Math.max(0, endPage - MAX_VISIBLE_PAGES + 1);
    }

    // Add "Précédent" button and disable it if there is only one page.
    pageButtons.push(
      <Button
        key="prev"
        size="xs"
        rounded="full"
        colorScheme="gray"
        onClick={() => handlePageClick(currentPage - 1)}
        className="pagination-button"
        isDisabled={pageCount === 1}
      >
        Previous
      </Button>
    );

    // Add page buttons with dots
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Button
          key={i}
          size="xs"
          color={'white'}
          rounded="full"
          background={i === currentPage ? "red.700" : "gray"} // Highlight the current page.
          onClick={() => handlePageClick(i)}
          className="pagination-button"
        >
          {i + 1}
        </Button>
      );
    }

    // Add "Suivant" button and disable it if there is only one page.
    pageButtons.push(
      <Button
        key="next"
        size="xs"
        rounded="full"
        colorScheme="gray"
        onClick={() => handlePageClick(currentPage + 1)}
        className="pagination-button"
        isDisabled={pageCount === 1}
      >
        Next
      </Button>
    );

    return pageButtons;
  };

  return (
    <Box my="4">
      <HStack justify="center" align="center">
        {renderPageButtons()}
      </HStack>
    </Box>
  );
}

export default MyPaginantion;
