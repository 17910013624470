import { Avatar, AvatarBadge, Box, Button, Card, CardBody, CardHeader, DarkMode, Flex, Grid, Icon, Image, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineSecurity, MdSettings } from 'react-icons/md'
import { FaCube, FaPenFancy, FaPencilAlt } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'
import { PiGenderFemaleBold } from 'react-icons/pi'
import { IoMdMale ,IoMdFemale } from 'react-icons/io'
import { Navigate, useNavigate } from 'react-router-dom'
import { linkImage } from '../../../axios-client'
import { useStateContext } from '../../../context/ContextProvider'
function ProfileAdmin() {
    const { user } = useStateContext();
    const navigate = useNavigate()


    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '20px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'>
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={linkImage + user.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                
                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                <Text
                                    fontSize={{ sm: 'lg', lg: 'xl' }}
                                    color={useColorModeValue("gray.700", "white")}
                                    fontWeight='bold'
                                    ms={{ sm: '8px', md: '0px' }}
                                    mr={2}
                                >
                                    {user.first_name} {user.last_name}
                                </Text>
                                 {user.gender === "Femme" ?  <IoMdFemale  size={"22px"} color='#ff8096'/>: user.gender === "Homme"? <IoMdMale size={"22px"} color='#5da4ff' /> : null}
                                </Flex>
                                
                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {user.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex direction={{ base: 'column', lg: 'row' }} mt={{base : 2 ,md:0}} w={{ sm: '100%', md: '50%', lg: 'auto' }}>
                            <Button
                                onClick={()=> navigate('/profile')}
                                borderRadius='12px'
                                _hover={'none'}
                                bg='#7e172c'
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={ImProfile} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    PROFILE
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg='transparent'
                                onClick={()=> navigate('/edit-profile')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    SETTINGS
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg='transparent'
                                onClick={() => navigate('/account-security')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("#f2c478", "#f2c478")} as={MdOutlineSecurity} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("#f2c478", "#f2c478")} fontWeight='bold'>
                                    ACCOUNT SECURITY
                                </Text>
                            </Button>
                            {/* <Button
                                borderRadius='12px'
                                bg='transparent'

                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={FaCube} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                    ELSE
                                </Text>
                            </Button> */}
                        </Flex>
                    </Flex>
                </Flex>
                <Grid  px={5} templateColumns={{ sm: "1fr", md: "1fr", lg: "3fr 1fr " }} justify={"center"} gap='22px'>

                    <Card
                        bg={useColorModeValue("gray.100", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                Profile informations
                            </Text>
                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                {/* <Flex align='center' mb='14px'>
                                    <Flex w={"50%"}>
                                        <Text letterSpacing={1} w={{ base: "50%", sm: "40%", lg: "20%" }} fontSize='md' color={'gray.500'} me='10px'>
                                            Nom  :{' '}
                                        </Text>
                                        <Text fontSize='md' color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                            {user.last_name}
                                        </Text>
                                    </Flex>
                                    <Flex w={"50%"}>
                                        <Text letterSpacing={1} w={{ base: "50%", sm: "40%", lg: "20%" }} fontSize='md' color={'gray.500'} me='10px'>
                                            Prenom :{' '}
                                        </Text>
                                        <Text fontSize='md' color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                            {user.first_name}
                                        </Text>
                                    </Flex>

                                </Flex> */}
                                <Flex  mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={'gray.500'} me='10px'>
                                        Full name {' '}
                                    </Text>
                                    <Text  fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                     : &nbsp; {user.first_name} {user.last_name}
                                    </Text>
                                </Flex>
                                <Flex  mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={'gray.500'} me='10px'>
                                        Email address {' '} 
                                    </Text>
                                    <Text  fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                     : &nbsp; {user.email}
                                    </Text>
                                </Flex>
                                <Flex  mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={'gray.500'} me='10px'>
                                        Phone number {' '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                    : &nbsp; {user.tel ? user.tel  : <span style={{ color:'#8080807a'}}> Phone number not available.</span>}
                                    </Text>
                                </Flex>
                                
                                <Flex mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "50%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={'gray.500'} me='10px'>
                                        Birthday{' '}
                                    </Text>
                                    <Text fontSize='md' color={useColorModeValue("gray.700", "white")} fontWeight='400'>
                                    : &nbsp; {user.birthday ? new Date(user.birthday).toLocaleDateString() : <span style={{ color:'#8080807a'}}> Birthday not available.</span>}
                                    </Text>
                                </Flex>

                            </Flex>
                        </CardBody>
                    </Card>
                    <Card

                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.800", "white")} fontWeight='bold'>
                                Address
                            </Text>
                        </CardHeader>
                        <CardBody px='5px' >
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                <Flex  mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} fontWeight='400'>
                                     &nbsp; {user.address ? user.address :<span style={{ color:'#8080807a'}}> not available.</span>}
                                    </Text>
                                </Flex>
                                {/* <Flex  mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "20%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} me='10px'>
                                        Ville {' '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} fontWeight='400'>
                                    : &nbsp; {user.ville ? user.ville :<span style={{ color:'#8080807a'}}> non disponibles.</span>}
                                    </Text>
                                </Flex>
                                <Flex mb='14px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "20%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} me='10px'>
                                        Pays {' '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} fontWeight='400'>
                                    : &nbsp; {user.pays ? user.pays :<span style={{ color:'#8080807a'}}> non disponibles.</span>}
                                    </Text>
                                </Flex> */}
                                {/* <Flex  mb='15px' flexDirection={{base: "column",md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} me='10px'>
                                        Code postal {'  '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  color={useColorModeValue("gray.500", "gray.100")} fontWeight='400'>
                                    : &nbsp;  {user.code_postal ? user.code_postal :<span style={{ color:'#8080807a'}}> non disponibles.</span>}
                                    </Text>
                                </Flex> */}

                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
        </Box>

    )
}

export default ProfileAdmin