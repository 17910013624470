import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../axios-client";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import MyPaginantion from "../../../components/MyPagination";
import { AiOutlineUserAdd } from "react-icons/ai";
import { PiPlusBold } from "react-icons/pi";
// import Subs from '../../assets/images/Subscribe.png'
import animationData from "../../../assets/lotties/program.json";
import Lottie from "react-lottie";
import { CiEdit } from "react-icons/ci";
import china from "../../../assets/images/loginS.png";
import zip from "../../../assets/images/zip.png";
import { TbReceipt2 } from "react-icons/tb";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function StudentApplication() {
  const navigate = useNavigate();
  const { user, setUser } = useStateContext();
  const [candidature, setCandidature] = useState([]);
  const [offreData, setOffreData] = useState([]);
  const [offreId, setOffreId] = useState("");
  const [studentCandidature, setStudentCandidature] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [id, setId] = useState(null);
  const toast = useToast();
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setMessage] = useState("");
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [university, setUniversity] = useState("");
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [price, setPrice] = useState();
  const [justification, setJustification] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);

  //search variable
  const [searchTerm, setSearchTerm] = useState("");

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [changeModal, setChangeModal] = useState(true);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };

  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 1;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  //get the data
  useEffect(() => {
    axiosClient
      .get(`/candidature/`)
      .then((res) => {
        setCandidature(
          res.data.filter((row) => row.student === location.state.idStudent)
        );
      })
      .catch((error) => {
        console.error(error);
      });
    axiosClient.get('/membres-equipe/')
      .then((response) => {
        setTeamMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function update() {
    axiosClient
      .get(`/candidature/`)
      .then((res) => {
        setCandidature(
          res.data.filter((row) => row.student === location.state.idStudent)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //search method
  const filteredData = useCallback(() => {
    return candidature?.filter((row) => {
      return (
        row.major.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.degree.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.scholarship.includes(searchTerm)
      );
    });
  }, [candidature, searchTerm]);

  // const handleDelete = (id) => {
  //   if (!id) return;
  //   axiosClient.delete(`/offres/${id}/`)
  //     .then((response) => {
  //       setOffres((prevData) => prevData.filter((row) => row.id !== id));
  //       toast({
  //         description: "the major is successfuly removed",
  //         status: 'warning',
  //         duration: 2000,
  //         isClosable: true,
  //         position: 'bottom-right',
  //         variant: 'left-accent',
  //       })
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   onCloseAlert()
  // }

  const [dataStudent, setDataStudent] = useState();
  useEffect(() => {
    axiosClient.get(`/students/${location.state.idStudent}/`).then((res) => {
      setDataStudent(res.data);
      console.log("data student : ", res.data);
    });
  }, []);

  const Save = (id) => {
    if (selectedStatus === "payment" && !price) {
      setMessage("please enter the price")
      return
    }

    if (selectedStatus === "rejected" && !justification) {
      setMessage("please justify your decision")
      return
    }

    const formData = new FormData();
    formData.append("status", selectedStatus);
    if (selectedStatus === "payment") {
      formData.append("price", price);
    } else {
      formData.append("price", "");
    }

    if (selectedStatus === "rejected") {
      formData.append("justification", justification);
    } else {
      formData.append("justification", "");
    }

    axiosClient
      .patch(`/candidature/${studentCandidature.id}/`, formData)
      .then(() => {
        update();

        toast({
          description: `The status is successfuly updated`,
          status: "success",
          duration: 2000,
          isClosable: true,
          variant: "left-accent",
        });
        // setTeam('')
        setMessage("");
        // setOffreData("")
      });

    axiosClient.post("/notificationsAdmin/", {
      title: `${dataStudent.first_name} ${dataStudent.last_name} file status`,
      content: `The status of the student file for  ${dataStudent.first_name} ${dataStudent.last_name} has been changed to ${selectedStatus}`,
      cible: "TeamLead",
      confirmationTeam: true,
    });
    axiosClient.post("/notificationsAdmin/", {
      title: `file status`,
      content: `your file status has been changed to ${selectedStatus}`,
      cible: "Student",
      student: location.state.idStudent,
      confirmationTeam: true,
    });

    onClose();
  };
  const [flightFiles, setFlightFiles] = useState();
  const handleFileChangeFlight = (event) => {
    const file = event.target.files[0];
    const extensions = [".pdf"];
    if (file) {
      if (file.name?.length > 70) {
        toast({
          description: "The name of the file is too long",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-center",
        });
        event.target.value = null;
      } else if (
        !extensions.includes(
          file.name?.substring(file.name?.length, file.name?.lastIndexOf("."))
        )
      ) {
        toast({
          description: "the type of the file should be a PDF",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-center",
        });
        event.target.value = null;
      } else {
        setFlightFiles(file);
      }
    }

    console.log("FILLLLLLLLLLLEEEEEEEe : ", file);
    // console.log('FILLLLLLLLLLLEEEEEEEe : ',file[0]);
  };

  const addFlightFile = (id) => {
    const formData = new FormData();
    formData.append("flight_files", flightFiles);
    axiosClient
      .patch(`/candidature/${id}/`, formData)
      .then((res) => {
        // setCandidature(res.data);
        update()
        setFlightFiles();
        console.log("receiiiiiiiipt data : ", res.data);
      });
  };

  const EditFlightFile = (id) => {
    axiosClient
      .patch(`/candidature/${id}/`, {
        flight_files: null,
      })
      .then((res) => {
        // setCandidature(res.data);
        update()
        setFlightFiles();
      });
  };

  return (
    <Box>
      <Flex>
        <Box
          w="100%"
          display={"flex"}
          justifyContent="space-between"
          mt={2}
          mb={5}
        >
          <Text
            fontFamily="Plus Jakarta Display"
            as="cite"
            fontSize={{ base: "18px", md: "20px", lg: "25px" }}
            color="gray"
          >
            <span
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </span>{" "}
            / <span>Application</span>{" "}
          </Text>
        </Box>
      </Flex>
      <Flex
        direction="column"
        mt={{ sm: "25px", md: "0px" }}
        p={{ base: 0, lg: 7 }}
      >
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 3fr " }}
          justify={"center"}
          gap="22px"
        >
          <Flex display={{ base: "none", lg: "flex" }} align={"center"}>
            <Image
              h={"100%"}
              px={{ base: 0, lg: 4 }}
              rounded={"0px 100px 0px 100px"}
              src={china}
              display={"start"}
            ></Image>
          </Flex>

          <Card border={"none"} shadow={"none"} display={"flex"}>
            <CardBody px="2px" border={"none"}>
              <Flex align={"center"} justify={"center"}>
                <Stack w={"full"} maxW="full">
                  {/* Search input */}
                  <Flex
                    justifyContent="end"
                    alignItems="center"
                    direction={{ base: "column", lg: "row" }}
                  >
                    <InputGroup size={"sm"} w={{ base: "100%", lg: "30%" }}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                      />
                      <Input
                        borderRadius={"5"}
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        mb={4}
                        sx={{
                          marginLeft: "auto",
                        }}
                      />{" "}
                    </InputGroup>
                  </Flex>

                  {filteredData()
                    .slice(
                      currentPage * pageSize,
                      currentPage * pageSize + pageSize
                    )
                    .map((row) => (
                      <Card shadow={"xl"}>
                        <CardBody>
                          <Stack divider={<StackDivider />} spacing="4">
                            <Box>
                              <Heading size="xs" textTransform="uppercase">
                                Program
                              </Heading>
                              <Text pt="2" fontSize="sm">
                                {row.major} /{row.degree} / {row.scholarship}%
                              </Text>
                            </Box>
                            <Box>
                              <Heading size="xs" textTransform="uppercase">
                                Files
                              </Heading>
                              <Flex
                                flexDirection={"row"}
                                align={"center"}
                                justify={"start"}
                                position="relative"
                              >
                                <img
                                  src={zip}
                                  style={{
                                    marginRight: 5,
                                    width: "30px",
                                    height: "30px",
                                    color: "lightgray",
                                    opacity: ".4",
                                  }}
                                />

                                <a href={row.files} download>
                                  <Text>{row.files?.split("/").pop()}</Text>
                                </a>
                              </Flex>
                            </Box>
                            <Box
                              alignItems={"center"}
                              display={"flex"}
                              justifyContent={"space-between"}
                              flexDirection={{ base: "column", md: "row" }}
                            >
                              <Heading size="xs" textTransform="uppercase">
                                Status
                                <Badge
                                  p={2}
                                  rounded={"xl"}
                                  ml="5"
                                  colorScheme={
                                    row.status === "pending"
                                      ? "yellow"
                                      : row.status === "payment"
                                        ? "blue"
                                        : row.status === "accepted"
                                          ? "green"

                                          : row.status === "rejected"
                                            ? "red"
                                            : ""
                                  }
                                >
                                  {row.status === "pending"
                                    ? "pending"
                                    : row.status === "payment"
                                      ? "ready for payment"
                                      : row.status === "accepted"
                                        ? "accepted"

                                        : row.status === "rejected"
                                          ? "rejected"
                                          : ""}
                                  {/* <IconButton size={'sm'} icon={<AiOutlineUserAdd />}></IconButton> */}
                                </Badge>
                                {/* {teamMembers?.find((e) => e.id === user.id)?.equipe==='SuiviTeam' && */}
                                <IconButton
                                  ml={2}
                                  _hover={"none"}
                                  icon={
                                    <CiEdit color="green" fontSize={"24px"} />
                                  }
                                  background={"none"}
                                  onClick={() => {
                                    onOpen();
                                    setStudentCandidature(row);
                                  }}
                                ></IconButton>
                                {/* } */}

                              </Heading>
                              {/* {row.status === "flight" && <Text color={'gray'}> </Text>}

                            {(row.status === "flight" && row.flight_files !== null) ?
                              <a href={row.flight_files} download>

                                <Text color={"green"}>{row.flight_files?.split('/').pop()}</Text>
                              </a>
                              : (row.status === "flight" && row.flight_files === null) ?
                                <Text color={'gray'}>Wait for the student choice on this matter... </Text> :
                                (row.status === "flight" && row.flight_files === null && row.etat_flight === "nihao") ? <Text color={'gray'}>The student wants you to handle the flight operation ... </Text> :
                                  ""} */}
                            </Box>
                            {/* {teamMembers?.find((e) => e.id === user.id)?.equipe==='SuiviTeam' && */}

                            <>
                            {row.status === "payment" && 
                             <Heading size="xs" textTransform="uppercase" mb={2}>
                             Payment receipt
                           </Heading>}
                           
                              {row.status === "payment" &&
                                row.receipt_payment !== null ? (
                                <a href={row.receipt_payment} download>
                                  <Text color={"green"}>
                                    {row.receipt_payment?.split("/").pop()}
                                  </Text>
                                </a>
                              ) : row.status === "payment" &&
                                row.receipt_payment === null ? (
                                <Text color={"gray"}>
                                  Wait for the student to upload the reciept...{" "}
                                </Text>
                              ) : (
                                ""
                              )}
                              {row.status === "accepted" ? (
                                <>
                                  <Heading size="xs" textTransform="uppercase" mb={2}>
                                    Flignt informations
                                  </Heading>
                                  {row.etat_flight === null ? (
                                    <Text color={"gray"}>
                                      Wait for the student choice on this
                                      matter...{" "}
                                    </Text>
                                  ) : (
                                    <>
                                      {row.etat_flight === "nihao" ? (
                                        <>
                                          <Text color={'gray'}>  The student wants you to take care of the flight reservation , please contact the student for more details.
                                            upload the necessary files bellow</Text>
                                          {row.flight_files === null ? (
                                            <>
                                              <Flex
                                                h={75}
                                                border="1px dashed gray"
                                                rounded="md"
                                                position="relative"
                                                align="center"
                                                justify="center"
                                                mt={2}
                                              >
                                                {!flightFiles ? (
                                                  <>
                                                    <TbReceipt2
                                                      size={45}
                                                      color="lightgray"
                                                    />
                                                    <Input
                                                      type="file"
                                                      onChange={
                                                        handleFileChangeFlight
                                                      }
                                                      accept=".pdf"
                                                      position="absolute"
                                                      top={0}
                                                      h="100%"
                                                      opacity="0"
                                                      cursor="pointer"
                                                    ></Input>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Flex
                                                      h="100%"
                                                      w="100%"
                                                      align="center"
                                                      justify="center"
                                                      flexDirection="column"
                                                      position="relative"
                                                    >
                                                      <FaFilePdf
                                                        size={35}
                                                        color="gray"
                                                      />
                                                      <Text px={2}>
                                                        {flightFiles?.name}
                                                      </Text>

                                                      <IoCloseCircleOutline
                                                        size={20}
                                                        color="red  "
                                                        style={{
                                                          position: "absolute",
                                                          top: 5,
                                                          right: 5,
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          setFlightFiles()
                                                        }
                                                      />
                                                    </Flex>
                                                  </>
                                                )}
                                              </Flex>
                                              {flightFiles && (
                                                <Button
                                                  colorScheme="yellow"
                                                  size="sm"
                                                  w="100%"
                                                  mt={1}
                                                  onClick={() => addFlightFile(row.id)}
                                                >
                                                  Add file
                                                </Button>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Flex
                                                flexDirection={"row"}
                                                align={"center"}
                                                justify={"start"}
                                                position="relative"
                                                py={5}
                                              >
                                                <FaFilePdf
                                                  size={35}
                                                  color="red"
                                                />
                                                <a
                                                  href={row.flight_files}
                                                  download
                                                >
                                                  <Text>
                                                    {row.flight_files
                                                      ?.split("/")
                                                      .pop()}
                                                  </Text>
                                                </a>
                                              </Flex>
                                              <Button
                                                colorScheme="yellow"
                                                size="sm"
                                                w="100%"
                                                mt={1}
                                                onClick={() => EditFlightFile(row.id)}
                                              >
                                                Edit receipt
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {row.flight_files === null ? (

                                            <Alert status='info' rounded='lg'>
                                              <AlertIcon />
                                              The student wants to take care of the flight reservation, Wait for them to upload the necessary files...
                                            </Alert>
                                          ) : (
                                            <>
                                              <Flex
                                                flexDirection={"row"}
                                                align={"center"}
                                                justify={"start"}
                                                position="relative"
                                                py={5}
                                              >

                                                <FaFilePdf
                                                  size={35}
                                                  color="red"
                                                />
                                                <a
                                                  href={row.flight_files}
                                                  download
                                                >
                                                  <Text>
                                                    {row.flight_files
                                                      ?.split("/")
                                                      .pop()}
                                                  </Text>
                                                </a>
                                              </Flex>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : null}
                            </>
                            {/* } */}
                          </Stack>
                        </CardBody>
                      </Card>
                    ))}
                  {filteredData().length === 0 && (
                    <Text>No matching rows were found.</Text>
                  )}

                  {/* Pagination */}
                  <Flex justify="space-between" align="center" w="100%">
                    <Box flex="1">
                      <MyPaginantion
                        data={filteredData()}
                        searchInput={searchTerm}
                        PAGE_SIZE={pageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Box>
                  </Flex>
                </Stack>
              </Flex>
            </CardBody>
          </Card>
        </Grid>
      </Flex>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Offer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You won't be able to undo this action later.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Close
              </Button>
              <Button
                onClick={() => "handleDelete(id)"}
                colorScheme="red"
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setMessage("");
          setSelectedStatus("");
          setPrice();
          setDescription("");
        }}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Edit the status</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={"column"} align={"center"}>
              {message &&
                <Alert status='error' rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              }
              <FormControl id="lastName" isRequired>
                <Select
                  value={
                    selectedStatus ? selectedStatus : studentCandidature.status
                  }
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    setPrice();
                    setDescription("");
                  }}
                  placeholder=""
                >
                  {studentCandidature.status === "pending" && (
                    <>
                      <option value="pending">pending</option>

                      <option value="payment">ready to be paid</option>
                      <option value="rejected">rejected</option>
                      <option value="accepted">accepted</option>
                    </>
                  )}
                  {studentCandidature.status === "rejected" && (
                    <>
                      <option value="pending">pending</option>
                      <option value="payment">ready to be paid</option>
                      {/* <option value="flight">flight</option> */}
                      <option value="accepted">accepted</option>
                      <option value="rejected">rejected</option>
                    </>
                  )}
                  {studentCandidature.status === "payment" && (
                    <>
                      <option value="payment">ready to be paid</option>
                      {/* <option value="flight">flight</option> */}
                      <option value="rejected">rejected</option>
                      <option value="accepted">accepted</option>
                    </>
                  )}
                  {/* {studentCandidature.status === "flight" && (
                    <>
                      <option value="flight">flight</option>
                      <option value="rejected">rejected</option>
                      <option value="accepted">accepted</option>
                    </>
                  )} */}
                  {studentCandidature.status === "accepted" && (
                    <>
                      <option value="pending">pending</option>
                      <option value="payment">ready to be paid</option>
                      {/* <option value="flight">flight</option> */}
                      <option value="accepted">accepted</option>
                      <option value="rejected">rejected</option>
                    </>
                  )}
                </Select>
              </FormControl>
            </Flex>
            {selectedStatus === "payment" ? (
              <Flex w="100%" flexDirection={"column"}>
                <FormControl isRequired mt={3}>
                  <FormLabel>Price</FormLabel>
                  <Input
                    maxLength={50}
                    value={price ? price : studentCandidature.price}
                    name="price"
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="price "
                  />
                </FormControl>
              </Flex>
            ) : (
              ""
            )}
            {selectedStatus === "rejected" ? (
              <Flex w="100%" flexDirection={"column"}>
                <FormControl isRequired mt={3}>
                  <FormLabel>Justification</FormLabel>
                  <Textarea
                    value={
                      justification
                        ? justification
                        : studentCandidature.justification
                    }
                    resize={"none"}
                    maxLength={250}
                    type="text"
                    name="justification"
                    onChange={(e) => setJustification(e.target.value)}
                    placeholder="please justify the reason of the rejection  "
                  />
                </FormControl>
              </Flex>
            ) : (
              ""
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={2}
              ref={cancelRef}
              onClick={() => {
                onClose();
                setMessage("");
                setSelectedStatus("");
                setPrice();
                setDescription("");
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="green"
              onClick={() => Save(studentCandidature.id)}
              ref={cancelRef}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default StudentApplication;
