import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../../components/MyPagination";
import { useStateContext } from "../../../context/ContextProvider";
// import { LiaUserEditSolid } from 'react-icons/lia';
import { TbHomeCog } from 'react-icons/tb';

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    Text,
    Alert,
    AlertIcon,
    FormControl,
    FormLabel,
    Card,
} from '@chakra-ui/react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { MdGroupRemove } from 'react-icons/md';
import { FiEdit3 } from 'react-icons/fi';
import { LiaUserEditSolid } from 'react-icons/lia';

export const GestTeamMember = () => {

    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [fullName, setFullName] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEquipe, setSelectedEquipe] = useState('')

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };
    const onCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRole('')
        setSelectedEquipe('')
        setMessage('')
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user } = useStateContext();


    const [deleteId, setDeleteId] = useState(null);
    const [mb, setMb] = useState();

    const cancelRef = React.useRef()

    //team member data
    const [data, setData] = useState([]);

    //get the team member data
    useEffect(() => {
        axiosClient.get('/membres-equipe/')
            .then((response) => {
                let filteredData = response.data;
                if (selectedEtat !== null) {
                    filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                }
                if (selectedUser !== '') {
                    filteredData = filteredData.filter((item) => item.role === selectedUser);
                }
                setData(filteredData.sort().reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }, [selectedEtat, selectedUser]);


    function update(){
        axiosClient.get('/membres-equipe/')
            .then((response) => {
                let filteredData = response.data;
                if (selectedEtat !== null) {
                    filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                }
                if (selectedUser !== '') {
                    filteredData = filteredData.filter((item) => item.role === selectedUser);
                }
                setData(filteredData.sort().reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const [message, setMessage] = useState(false)

    // delete team member
    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/membres-equipe/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "The user is successfully deleted",
                    status: 'warning',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //search method
    const filteredData = useCallback(() => {
        return data.filter((row) => {
            // Exclude data of current user
            if (row.id === user.id) {
                return false;
            }

            return (
                row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [data, searchTerm, user.id]);


    //edit l'etat de team member ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_user_etat/${id}/`, formData)
            .then((response) => {
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: "The account is activated",
                variant: 'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: "The account is disabled",
                status: 'warning',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }
    const [currentPage, setCurrentPage] = useState(0);

    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };
    const handleRoleSubmit = () => {
        changeRole(deleteId);
    };
    const changeRole = (id) => {

        const updatedData = data.map(row => {
            if (row.id === id) {
                if (selectedRole === "CHEFEQUIPE" && data?.find(e => e.type_membre === "CHEFEQUIPE")) {
                    setMessage("there is already a team leader");
                    return
                }
                const formData = new FormData();

                formData.append("type_membre", selectedRole);
                if(selectedEquipe) {formData.append("equipe", selectedEquipe)}
                else {formData.append("equipe", '')}
                // formData.append("edited_by", user.first_name + ' ' + user.last_name);

                axiosClient.put(`/update_member_type/${id}/`, formData)
                    .then((response) => {

                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "The role is successfully changed",
                            status: 'success',
                            duration: 2000,
                            variant: 'left-accent',
                            isClosable: true,
                            position: 'bottom-right'
                        })
                        setIsModalOpen(false);
                        update()
                        setMessage('')
                        setSelectedRole('')
                        setSelectedEquipe('')
                    })

                    .catch((error) => console.error(error));

            }
        });
    }

    const borderColor = useColorModeValue("gray.300", "gray.600");

    return (
        <Box mt="5px" >

            <Box w="100%" display={"flex"} justifyContent="space-between" mt={2} mb={5}>
                <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span >Team Members</span></Text>
                {user.role !== "ADMIN" &&
                <Button onClick={() => navigate('/add-team-member')} leftIcon={<AiOutlineUserAdd color='#f2c478' />} _hover={'none'} color={"#f2c478"} bg="#7e172c">
                    Add Team member
                </Button> 
               
                }
            </Box>
            {(user.role === "ADMIN" || user.role === "RESPO" || user.role === "CHEFEQUIPE")  &&
            <Flex justify={'end'}  gap={2}>
                     <Card backgroundColor={'red.700'} fontSize={{base:'xs',md:'sm'}} p={3} color={'white'}>Number of members : {data.length}</Card>
                     <Card backgroundColor={'red.700'} fontSize={{base:'xs',md:'sm'}} p={3} color={'white'}>Confirmation team : {data.filter(e=>e.equipe==="ConfirmationTeam").length}</Card>
                     <Card backgroundColor={'red.700'} fontSize={{base:'xs',md:'sm'}} p={3} color={'white'}>Tracking team : {data.filter(e=>e.equipe==="SuiviTeam").length}</Card>
                </Flex>}
            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    shadow={"md"}
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex w={"100%"}>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '30%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">All the users</option>
                                <option value="MBEQUIPE">Simple member</option>
                                <option value="CHEFEQUIPE">Team leader</option>
                            </Select>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mr={{base:0,lg:5}} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">All</option>
                                <option value="true">Activated</option>
                                <option value="false">Disabled</option>
                            </Select>
                        </Flex>


                        <InputGroup size="sm" w={{ base: '100%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer bg={"gray.50"} rounded={10} p="5">
                        <Table size="sm" variant='simple' >
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Code</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">First name</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Last name</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Email</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Adresse</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Etat</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Role</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Team</Th>
                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display' fontSize="12">Date Creation</Th>
                                    {user.role !== "ADMIN" &&
                                        <Th borderColor={borderColor} fontSize="12">Options</Th>}
                                    
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredData().filter((row) => selectedEtat === null || row.etat === selectedEtat && (selectedUser === '' || row.role === selectedUser)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                    <Tr key={row.id}>
                                        <Td borderColor={borderColor} >
                                            <Avatar size='md' borderRadius="10px" src={row.image} />
                                        </Td>
                                        <Td borderColor={borderColor} >{row.code}</Td>
                                        <Td borderColor={borderColor}>{row.first_name}</Td>
                                        <Td borderColor={borderColor}>{row.last_name}</Td>

                                        <Td borderColor={borderColor} >{row.email}</Td>
                                        <Td borderColor={borderColor}>
                                            {row.address}
                                        </Td>
                                        <Td borderColor={borderColor}>
                                            <Flex>
                                            {user.role !== "ADMIN" &&
                                                <Stack direction='row' mr="3px">
                                                        <Switch
                                                            colorScheme='green'
                                                            isChecked={row.etat}
                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                        />
                                                    
                                                </Stack>}
                                                <Badge
                                                    size="sm"
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? 'Activated' : 'Disabled'}
                                                </Badge>
                                            </Flex>
                                        </Td>
                                        <Td borderColor={borderColor} w={"100%"} p={0}>

                                            <Badge
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                rounded="full"
                                                bg={""}
                                                w={"fit-content"}
                                                py={2}
                                                px={4}
                                                cursor="pointer"
                                                title="change the type of the member"
                                                onClick={() => {
                                                    setFullName(`${row.first_name} ${row.last_name}`)
                                                    setSelectedRole(row.role)
                                                    setDeleteId(row.id);
                                                    setMb(row);
                                                    setIsModalOpen(true);


                                                }}>
                                                <LiaUserEditSolid size={20} />
                                                <Text ml={1}>{row.type_membre === "MBEQUIPE" ? "Simple member" : "Team leader"} {}</Text>
                                            </Badge>
                                        </Td>
                                        <Td borderColor={borderColor}> {(() => {
                                            if (row.equipe === "ConfirmationTeam") {
                                            return "Confirmation team";
                                            } else if (row.equipe === "SuiviTeam") {
                                            return "Tracking team";
                                            } else {
                                            return "____";
                                            }
                                        })()} </Td>
                                        <Td borderColor={borderColor}>{new Date(row.date_creation).toLocaleDateString("en-US")}</Td>
                                        {user.role !== "ADMIN" &&
                                        <Td borderColor={borderColor}>

                                            {/* <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/editResponsable`, {
                                                    state: {
                                                        idResponsable: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                colorScheme="green"
                                                icon={<EditIcon />}>
                                            </IconButton> */}
                                            <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/team-member-edit`, {
                                                    state: {
                                                        idTeamM: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                variant='outline'
                                                colorScheme="whatsapp"
                                                icon={<FiEdit3 fontSize={"16px"} />}>
                                            </IconButton>

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteId(row.id);
                                                        onOpenAlert();

                                                    }}
                                                    mr="5px"
                                                    colorScheme="red"
                                                    icon={<MdGroupRemove fontSize={"18px"} />}>
                                                </IconButton>
                                        </Td>}

                                    </Tr>
                                ))}
                                {filteredData().length === 0 && (
                                    <Tr>
                                        <Td borderColor={borderColor} colSpan={15}>No matching rows were found.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                data={filteredData()}
                                searchInput={searchTerm}
                                PAGE_SIZE={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete
                        </AlertDialogHeader>

                        <AlertDialogBody>


                            <Text>
                                Are you sure? You won't be able to undo this action later.
                            </Text>

                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                Close
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Edit the type of {fullName}
                        </AlertDialogHeader>

                        <AlertDialogBody >
                        {message &&
                        <Alert fontSize={"sm"} padding={2} mb={2} status='error' rounded="md">
                            <AlertIcon />
                            {message}
                        </Alert>
                    }
                            <Stack>
                                <Text mb={2} fontSize={"14px"}>
                                    Are you sure you want to change this member's role? Changing the role will update its privileges and may affect its ability to perform certain actions.                                 </Text>
                                <Select
                                    size={"sm"}
                                    defaultValue={selectedRole ? selectedRole : mb?.type_membre} onChange={e => setSelectedRole(e.target.value)}>
                                  
                                    <option value="MBEQUIPE">Simple member</option>
                                    <option value="CHEFEQUIPE">Team leader</option>
                                </Select>

                            </Stack>
                            {selectedRole === "MBEQUIPE" 
                            &&
                            <FormControl mt={3} id="team" isRequired >
                                        <FormLabel ml={0.5}>Team</FormLabel>
                                        <Select defaultValue={selectedEquipe ? selectedEquipe : mb?.equipe} size={"sm"} placeholder="Select the team ..." onChange={(e)=> setSelectedEquipe(e.target.value)} >

                                                <option value="SuiviTeam">Tracking team</option>
                                                <option value="ConfirmationTeam">Confirmation team</option>
                                        </Select>
                                    </FormControl>
                            }


                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage('')
                                // setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                Close
                            </Button>
                            <Button size={"sm"} onClick={handleRoleSubmit} colorScheme='green' ml={3}>
                                Edit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>

    )
}

export default GestTeamMember