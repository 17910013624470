import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../../components/MyPagination';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../../assets/images/bell.png'
import animationData from '../../../assets/lotties/notif.json'
import Lottie from 'react-lottie';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function Notif() {
    const navigate = useNavigate()
    const { user, setUser } = useStateContext();
    const [notificationData, setNotificationData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    

    const [selectedC, setSelectedC] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');


    
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const location = useLocation()


    //get the data
    useEffect(() => {
        axiosClient.get(`notificationsAdmin/${location.state.idNotif}/`)
            .then((res) => {
                setNotificationData(res.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])
   
    
    const myColor = useColorModeValue("gray.50", "gray.700");

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span>Notification</span> </Text>
                    
                </Box>

            </Flex>
                
                <Flex direction='column' justify={'center'} mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>


                        <Card
            
                            bg={"none"}
                            border={'none'}
                            shadow={'none'}
                            display={'flex'}
                            justify={'center'}
                        >

                            <CardBody   px='2px' >

                                
                                <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    align={{ base: 'stretch', md: 'center' }}
                                    justify={{ base: 'flex-start', md: 'space-between' }}
                                    p={5}
                                >

                                    <Flex
                                        direction="column"
                                        width={{ base: "100%", lg: "100%" }}
                                        justify={{ base: 'flex-start', md: 'space-between' }}

                                    >

                                            <Stack
                                                bg={'gray.50'}
                                                mb={2}
                                                rounded={'lg'}
                                                p={5}
                                                boxShadow={'xl'}
                                                alignItems={'start'}
                                                justify="space-between"

                                            >
                                                <Flex direction="row"
                                                    justify="space-between"
                                                    ml={'auto'}
                                                    width="full">
                                                    <Stack direction={'row'} align={'center'}>
                                                        <Text fontSize={'lg'} fontWeight="semibold">{notificationData.title}</Text>
                                                        
                                                    </Stack>
                                                    <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(notificationData.date_creation).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>

                                                </Flex>

                                                <Flex
                                                    direction="row"
                                                    width="full"
                                                    ml={'auto'}
                                                    justify="space-between">
                                                    <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                                        {notificationData.content}

                                                    </Text>

                                                </Flex>
                                            </Stack>
                                        
                                       
                                    </Flex>


                                </Flex>
                            </CardBody>
                        </Card>
                       
                </Flex>

         

        </Box>
    )
}

export default Notif