import logo from '../../assets/images/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createRef, useEffect, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
// import login from '../../assets/images/logo.png'
import signInImage from "../../assets/images/login.jpg";

import {
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    Stack,
    Alert,
    AlertIcon,
    Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    DarkMode,
} from "@chakra-ui/react";

// Assets


export default function Login() {
    const titleColor = "#7e172c";
    const textColor = "gray.400";
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { setToken, setRefresh, isLoading, setIsLoading } = useStateContext()
    const [message, setMessage] = useState([])
    const toast = useToast()
    const navigate = useNavigate()
    const [listStudents, setListStudents] = useState([])
    useEffect(() => {
        

        axiosClient
            .get("/students/")
            .then((res) => setListStudents(res.data));

    }, []);

    //encryption function
    function encryptString(plainText, shift) {
        if (!Number.isInteger(shift) || shift < 1 || shift > 25) {
            throw new Error("Shift must be an integer between 1 and 25.");
        }

        const encryptedArray = [];
        for (let i = 0; i < plainText.length; i++) {
            let charCode = plainText.charCodeAt(i);

            // Encrypt uppercase letters
            if (charCode >= 65 && charCode <= 90) {
                charCode = ((charCode - 65 + shift) % 26) + 65;
            }
            // Encrypt lowercase letters
            else if (charCode >= 97 && charCode <= 122) {
                charCode = ((charCode - 97 + shift) % 26) + 97;
            }

            encryptedArray.push(String.fromCharCode(charCode));
        }

        return encryptedArray.join("");
    }


    const onSubmit = ev => {
        toast.closeAll()
        ev.preventDefault()
        setIsLoading(true)
        const payload = {
            email,
            password,
        }
        if(listStudents.find(e=>e.email===email))
        {
            setMessage({ alert: "Email incorrect", target: "email" });
            setIsLoading(false)
            return
        }

        axiosClient.post('token/user/', payload)
            .then(({ data }) => {
                setToken(data.access);
                setRefresh(data.refresh)
                setIsLoading(false);

                // localStorage.setItem("mutumAdmin", encryptString(email, 25))
                // localStorage.setItem("tsaroAdmin", encryptString(password, 25))
                localStorage.setItem("role", data.role)

            })
            .catch((err) => {
                const response = err.response;
                if (response.data.message === "The password is incorrect") {
                    setMessage({ alert: response.data.message, target: "password" });
                }
                else if (response.data.message === "Your account is disabled") { setMessage({ alert: response.data.message, target: "" }); }
                else {
                    setMessage({ alert: "Email incorrect", target: "email" });
                }

                setIsLoading(false)
            })

    }
    const [showPassword, setShowPassword] = useState(false);
    const isFormFilled = email && password;

    return (
        <Flex position='relative' bg="white">
            <Flex
                minH='100vh'
                h={{ base: "fit-content", lg: "fit-content" }}
                w='100%'
                // maxW='1044px'
                // mx='auto'

                // pt={{ sm: "100px", md: "0px" }}
            // flexDirection='column'
            // me={{ base: "auto", lg: "50px", xl: "auto" }}
            >
                <Flex

                    alignItems='center'
                    justifyContent='center'
                    style={{ userSelect: "none" }}
                    mx={{ base: "auto", lg: "unset" }}
                    ms={{ base: "auto", lg: "auto" }}
                    w={{ base: "100%", md: "50%", lg: "50%" }}
                    px={{ base: "20px", md: 0, lg: "40px", xl: "150px" }}
                >
                    <Flex
                        direction='column'
                        w='100%'
                        background='transparent'
                    // mt={{ base: "50px", md: "150px", lg: "160px", xl: "150px" }}
                    // mb={{ base: "60px", lg: "95px" }}
                    >
                        {/* <Image  src={logo} width="100px" mt="10px"></Image> */}

                        <Heading color={titleColor} fontSize='32px' mb='20px'>
                            Administrative area
                        </Heading>
                        <Text
                            mb='36px'
                            ms='4px'
                            color={textColor}
                            fontWeight='bold'
                            fontSize='14px'>
                            Enter your email address and password to log in.
                        </Text>
                        {message.alert &&
                            <Alert status='error' rounded="md">
                                <AlertIcon />
                                {message.alert}
                            </Alert>
                        }
                        <FormControl mb="3" id="email" isRequired >
                            <FormLabel color={"gray.800"} >Email address</FormLabel>
                            <Input color={titleColor} onChange={(e) => setEmail(e.target.value)} type="text" placeholder='Enter your email' />
                        </FormControl>
                        <FormControl id="password" isRequired isInvalid={message.target === "password"}>
                            <FormLabel mb="3" color={"gray.800"}>Password</FormLabel>
                            <InputGroup mb="3">
                                <Input color={titleColor} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder='Enter your password' />
                                <InputRightElement h={'full'}>
                                    <Button
                                        color={"gray.800"}
                                        variant={'ghost'}
                                        _hover='none'
                                        onClick={() =>
                                            setShowPassword((showPassword) => !showPassword)
                                        }>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Link href='/reset_password' color={'#f2c478'}>Forgot password ?</Link>
                            </Stack>
                            <Button
                                _hover={
                                    {
                                        color: "red.600",
                                        background: "transparent",
                                        border: '1px solid',
                                        borderColor: 'red.600'
                                    }
                                }
                                transition={".3s ease-in-out"}
                                fontSize='13px'
                                type="submit" onClick={onSubmit} isLoading={isLoading}
                                color={"#f2c478"} background='#7e172c' isDisabled={!isFormFilled}>
                                {isLoading ? <Spinner size="sm" /> : "Login"}
                            </Button>
                            <Button
                            variant='outline'
                            display={{base: 'block', lg:'none'}}
                            fontSize='13px'
                            onClick={() => navigate('/login-student')}
                            colorScheme='yellow' >
                            Login as student
                        </Button>
                        </Stack>


                    </Flex>
                </Flex>

                <Box
                    display={{ base: "none", lg: "block" }}
                    overflowX='hidden'
                    h='100%'
                    maxW={{ md: "50vw", lg: "50vw" }}
                    minH='100vh'
                    w='960px'
                    position='absolute'
                    left='0px'>
                   
                    <Box
                        bgImage={signInImage}
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                         <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        }}
                    ></Box>
                        <Text
                        zIndex={1}
                            mb={2}
                            textAlign='center'
                            color='white'
                            letterSpacing='8px'
                            fontSize='20px'
                            fontWeight='500'>
                            WELCOME TO NIHAO ADMIN
                        </Text>
                        <Text
                        zIndex={1}
                            textAlign='center'
                            color='transparent'
                            letterSpacing='10px'
                            fontSize='36px'
                            fontFamily="initial"
                            fontWeight='bold'
                            bgClip='text !important'
                            bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                            NIHAO
                        </Text>
                        <Button
                            fontSize='13px'
                            onClick={() => navigate('/login-student')}
                            color={"#f2c478"} background='#7e172c' >
                            Login as student
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

