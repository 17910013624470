import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../../components/MyPagination";
import { useStateContext } from "../../../context/ContextProvider";
import { AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import { LiaUserEditSolid } from 'react-icons/lia';

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    Text,
    

} from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import { FiEdit3 } from 'react-icons/fi';
import { MdGroupRemove } from 'react-icons/md';

export const GestAdmins = () => {
    const [fullName, setFullName] = useState()
    const [oldRole, setOldRole] = useState()
    const [newRole, setNewRole] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialFocusRef = React.useRef()


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Apprenant data
    const [data, setData] = useState([]);


    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data
    useEffect(() => {

        axiosClient.get('/admins/')
            .then((response) => {
                let filteredData = response.data;
                if (selectedEtat !== null) {
                    filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                }
                if (selectedUser !== '') {
                    filteredData = filteredData.filter((item) => item.role === selectedUser);
                }
                setData(filteredData.sort().reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user.role, selectedUser]);


    const handleRoleSubmit = () => {
        changeRole(deleteId, selectedRole);
        setIsModalOpen(false);
    };

    const [message, setMessage] = useState(false)


    const changeRole = (id) => {
        const updatedData = data.map(row => {
            if (row.id === id) {
                const formData = new FormData();

                formData.append("type_admin", selectedRole);
                // formData.append("edited_by", user.first_name + ' ' + user.last_name);

                axiosClient.put(`/update_admin_role/${id}/`, formData)
                    .then((response) => {
                        // const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                    // date_modification: (updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "The role is successfully changed",
                            status: 'success',
                            duration: 2000,
                            variant:'left-accent',
                            isClosable: true,
                            position: 'bottom-right'
                        })
                    })

                    .catch((error) => console.error(error));

            }
        });
    }

    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/admins/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "The user is successfully deleted",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //search method
    const filteredData = useCallback(() => {
        return data.filter((row) => {
            // Exclude data of current user
            if (row.id === user.id || row.role === "SUPERADMIN") {
                return false;
            }

            // if (row.role === "ADMIN" && (user.role !== "SUPERADMIN")) {
            //     return false;
            // }

            return (
                row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
              
                row.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [data, searchTerm, user.id]);


    //edit l'etat de responsable ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        // formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_user_etat/${id}/`, formData)
            .then((response) => {
                const updatedRow = response.data;
                // Update the row object with the new etat value
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            // date_modification: updatedRow.date_modification.format('YYYY-MM-DD HH:mm:ss'),
                            // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: "The account is activated ",
                variant:'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: "The account is disabled",
                status: 'warning',
                variant:'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }
    const [currentPage, setCurrentPage] = useState(0);


    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };
    const borderColor = useColorModeValue("gray.300", "gray.600");


    return (
        <Box mt="5px" >
                <Box w="100%" display={"flex"} justifyContent="space-between" mt={2}  mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{base:"18px",md:"20px",lg:"25px"}} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span  >Admins</span></Text>
                    <Button onClick={() => navigate('/add-admin')} leftIcon={<AiOutlineUserAdd color='#f2c478' />} _hover={'none'} color={"#f2c478"} bg="#7e172c">
                        Add admin
                    </Button>
                </Box>



            <Flex
                align={'center'}
                justify={'center'}
            >

                <Stack

                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    shadow={"md"}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex w={"100%"}>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">All the users</option>
                                <option value="ADMIN">Director</option>
                                <option value="RESPO">Manager</option>
                            </Select>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mr={5} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">All</option>
                                <option value="true">Activated</option>
                                <option value="false">Disabled</option>
                            </Select>
                        </Flex>


                        <InputGroup size="sm" w={{ base: '70%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer rounded={10} p="5">
                        <Table size="sm" variant='simple' >
                            <Thead size="sm">
                                <Tr h={10} fontSize="12">
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Code</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">First name</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">last name</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Email</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Address</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">State</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Role</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Creation date</Th>
                                    <Th borderColor={borderColor}  fontFamily='Plus Jakarta Display' fontSize="12">Options</Th>
                                    
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredData().filter((row) => selectedEtat === null || row.etat === selectedEtat && (selectedUser === '' || row.role === selectedUser)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                    <Tr key={row.id}>
                                        <Td borderColor={borderColor} > <Avatar borderRadius="10px" size='md' src={row.image} /></Td>
                                        <Td borderColor={borderColor} >{row.code}</Td>
                                        <Td borderColor={borderColor}>{row.first_name}</Td>
                                        <Td borderColor={borderColor}>{row.last_name}</Td>
                                        <Td borderColor={borderColor} >{row.email}</Td>
                                        <Td borderColor={borderColor}>{row.address}
                                        </Td>
                                        <Td borderColor={borderColor}>
                                            <Flex>
                                                <Stack direction='row' mr="3px">
                                                        <Switch
                                                            colorScheme='green'
                                                            isChecked={row.etat}
                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                        />
                                                    
                                                </Stack>
                                                <Badge
                                                    size="sm"
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? 'Activated' : 'disabled'}
                                                </Badge>
                                            </Flex>
                                        </Td>
                                            <Td borderColor={borderColor} w={"100%"} p={0}>
                                                
                                                <Badge
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    rounded="full"
                                                    bg={"#efc07254"}
                                                    w={"fit-content"}
                                                    py={2}
                                                    px={4}
                                                    cursor={ "pointer"}
                                                    title={ "changer le rôle de l'utilisateur" }
                                                    onClick={() => {
                                                       
                                                            setFullName(`${row.first_name} ${row.last_name}`)
                                                            setSelectedRole(row.role)
                                                            setDeleteId(row.id);
                                                            setIsModalOpen(true);

                                                        
                                                    }}>
                                                    <LiaUserEditSolid size={20} />
                                                    <Text ml={1}>{row.role === "ADMIN" ? "Director" : "Manager"}</Text>
                                                </Badge>
                                            </Td>
                                        
                                        <Td borderColor={borderColor}>{new Date(row.date_creation).toLocaleDateString("en-US")}</Td>
                                        <Td borderColor={borderColor}>
                                       
                                            <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/modifier-admin`, {
                                                    state: {
                                                        idAdmin: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                variant='outline'
                                                colorScheme="whatsapp"
                                                icon={<FiEdit3 fontSize={"16px"} />}>
                                            </IconButton>
                                            {/* <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        icon={<MdHistory />}>
                                                    </IconButton>
                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="#497dff" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Ajouté par : {row.added_by}
                                                        </div>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover> */}

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteId(row.id);
                                                        setSelectedRole( row.role === "ADMIN" ? "Director" : "Manager")
                                                        onOpenAlert();

                                                    }}
                                                    mr="5px"
                                                    colorScheme="red"
                                                    icon={<MdGroupRemove fontSize={"18px"} />}>
                                                </IconButton>
                                        </Td>

                                    </Tr>
                                ))}
                                {filteredData().length === 0 && (
                                    <Tr>
                                        <Td borderColor={borderColor} colSpan={13}>No matching rows were found.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                data={filteredData()}
                                searchInput={searchTerm}
                                PAGE_SIZE={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete {selectedRole}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                            Are you sure? You won't be able to undo this action later.
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                Close
                            </Button>
                            <Button size={"sm"} onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Edit the role of {fullName}
                        </AlertDialogHeader>

                        <AlertDialogBody >

                            <Stack>
                                <Text mb={2} fontSize={"14px"}>
                                Are you sure you want to change this user's role? Changing the role will update its privileges and may affect its ability to perform certain actions.                                </Text>
                                <Select
                                    size={"sm"}
                                    defaultValue={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                    {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                        <option value="MASTER">Super-administrateur</option>
                                    } */}
                                    <option value="RESPO">Manager</option>
                                    <option value="ADMIN">Director</option>
                                </Select>
                            </Stack>


                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage('')
                                setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                Close
                            </Button>
                            <Button size={"sm"} onClick={handleRoleSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                Edit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>

    )
}

export default GestAdmins