import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import {
    Alert,
    AlertIcon,
    Avatar,
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Heading,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    SimpleGrid,
    Stack,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    background,
    useColorModeValue,
    useSteps,
    useToast,
} from "@chakra-ui/react";
import { GrAdd } from "react-icons/gr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiFile } from "react-icons/fi";
import china from "../../../assets/images/china4.jpg";
import { IoCloudUploadOutline } from "react-icons/io5";
import upload from "../../../assets/images/upload.png";
import zip from "../../../assets/images/zip.png";
import axiosClient from "../../../axios-client";
import { IoIosCloseCircle } from "react-icons/io";
import ReactCardSlider from "react-card-slider-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { TbReceipt2 } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";

// Import Swiper styles
import "swiper/css";
import Footer from "../../../components/footer/Footer";

function SuivreApplication() {
    const location = useLocation();

    const toast = useToast();

    const { student } = useStateContext();
    const [loading, setLoading] = useState(true); // Introduce a loading state

    const [candidature, setCandidature] = useState([]);

    const steps = [
        { title: "pending", description: "please wait" },
        { title: "payment", description: "ready to be paid" },
        { title: "accepted", description: "application closed " },
    ];
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    });
    useEffect(() => {
        axiosClient
            .get(`/candidature/${location.state.idCandidature}/`)
            .then((res) => {
                const filteredCandidature = res.data;
                setCandidature(res.data);

                const status = res.data.status;

                // Set the activeStep based on the status
                if (status === "pending") {
                    setActiveStep(0);
                } else if (status === "payment") {
                    setActiveStep(1);
                } else if (status === "flight") {
                    setActiveStep(2);
                } else if (status === "accepted") {
                    setActiveStep(3);
                } else if (status === "rejected") {
                    setActiveStep(-1);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [student.id]);

    function update() {
        axiosClient
            .get("candidature/")
            .then((res) => {
                const filteredCandidature = res.data.filter(
                    (row) => row.student === student.id
                );
                setCandidature(filteredCandidature);

                // Assuming that candidature array has only one item for a student, adjust accordingly if needed
                const status =
                    filteredCandidature.length > 0 ? filteredCandidature[0].status : null;

                // Set the activeStep based on the status
                if (status === "pending") {
                    setActiveStep(0);
                } else if (status === "payment") {
                    setActiveStep(1);
                } else if (status === "accepted") {
                    setActiveStep(2);
                } else if (status === "rejected") {
                    setActiveStep(0);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const [reçu, setReçu] = useState("");
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const extensions = [".pdf"];
        if (file) {
            if (file.name?.length > 70) {
                toast({
                    description: "The name of the file is too long",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-center",
                });
                event.target.value = null;
            } else if (
                !extensions.includes(
                    file.name?.substring(file.name?.length, file.name?.lastIndexOf("."))
                )
            ) {
                toast({
                    description: "the type of the file should be a PDF",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-center",
                });
                event.target.value = null;
            } else {
                setReçu(file);
            }
        }

        console.log("FILLLLLLLLLLLEEEEEEEe : ", file);
        // console.log('FILLLLLLLLLLLEEEEEEEe : ',file[0]);
    };

    const addReceipt = () => {
        const formData = new FormData();
        formData.append("receipt_payment", reçu);
        axiosClient
            .patch(`/candidature/${location.state.idCandidature}/`, formData)
            .then((res) => {
                setCandidature(res.data);
                setReçu();
                console.log("receiiiiiiiipt data : ", res.data);
            });
    };

    const EditReceipt = () => {
        axiosClient
            .patch(`/candidature/${location.state.idCandidature}/`, {
                receipt_payment: null,
            })
            .then((res) => {
                setCandidature(res.data);
                setReçu();
                console.log("receiiiiiiiipt data : ", res.data);
            });
    };

    const [flightFiles, setFlightFiles] = useState();
    const handleFileChangeFlight = (event) => {
        const file = event.target.files[0];
        const extensions = [".pdf"];
        if (file) {
            if (file.name?.length > 70) {
                toast({
                    description: "The name of the file is too long",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-center",
                });
                event.target.value = null;
            } else if (
                !extensions.includes(
                    file.name?.substring(file.name?.length, file.name?.lastIndexOf("."))
                )
            ) {
                toast({
                    description: "the type of the file should be a PDF",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-center",
                });
                event.target.value = null;
            } else {
                setFlightFiles(file);
            }
        }

        console.log("FILLLLLLLLLLLEEEEEEEe : ", file);
        // console.log('FILLLLLLLLLLLEEEEEEEe : ',file[0]);
    };

    const addFlightFile = () => {
        const formData = new FormData();
        formData.append("flight_files", flightFiles);
        axiosClient
            .patch(`/candidature/${location.state.idCandidature}/`, formData)
            .then((res) => {
                setCandidature(res.data);
                setFlightFiles();
                console.log("receiiiiiiiipt data : ", res.data);
            });
    };

    const EditFlightFile = () => {
        axiosClient
            .patch(`/candidature/${location.state.idCandidature}/`, {
                flight_files: null,
            })
            .then((res) => {
                setCandidature(res.data);
                setFlightFiles();
                console.log("receiiiiiiiipt data : ", res.data);
            });
    };

    return (
        <Box >

            <Flex px={{ base: 5, md: 10, lg: 20 }} py={10} w={"100%"}>

                <Stepper pb={{ base: 5, md: 0 }} gap={{ base: 10, md: 5 }} overflowX={{ base: 'scroll', sm: 'hidden' }} colorScheme={"green"} w={"100%"} size="lg" index={activeStep}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink="0">
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>

                            <StepSeparator style={{ display: "flex", align: "start" }} />
                        </Step>
                    ))}
                </Stepper>
            </Flex>
            <Flex px={{ base: 5, md: 10, lg: 20 }} pb={10} w="100%">
                {activeStep === 0 && (
                    <Flex w="100%" justify="start">
                        <Card
                            direction={{ base: "column", sm: "row" }}
                            overflow="hidden"
                            variant="outline"
                            w={{ base: "100%", lg: '30%' }}
                        >
                            <Stack>
                                <CardBody px={{ base: 5, md: 10 }}>
                                    <Heading size="sm">
                                        <Flex flexDirection={"column"}>
                                            <Text fontWeight="semibold">
                                                Major : {candidature.major}
                                            </Text>
                                            <Text fontWeight="semibold">
                                                Degree : {candidature.degree}
                                            </Text>
                                            <Text fontWeight="semibold">
                                                Scholarship : {candidature.scholarship} %
                                            </Text>
                                        </Flex>
                                    </Heading>

                                    <Flex
                                        flexDirection={"row"}
                                        align={"center"}
                                        justify={"start"}
                                        position="relative"
                                        py={5}
                                    >
                                        <img
                                            src={zip}
                                            style={{
                                                marginRight: 5,
                                                width: "30px",
                                                height: "30px",
                                                color: "lightgray",
                                                opacity: ".4",
                                            }}
                                        />

                                        <a href={candidature.files} download>
                                            <Text>{candidature.files?.split("/").pop()}</Text>
                                        </a>
                                    </Flex>
                                </CardBody>
                            </Stack>
                        </Card>
                    </Flex>
                )}
                {activeStep === 1 && (
                    <Flex w="100%" justify="center">
                        <Card
                            direction={{ base: "column", sm: "row" }}
                            overflow="hidden"
                            variant="outline"
                            w={{ base: "100%", lg: '30%' }}
                        >
                            <Stack w={"100%"}>
                                <>
                                    <CardBody px={{ base: 5, md: 10 }}>
                                        <Heading size="sm">
                                            <Flex flexDirection={"column"}>
                                                <Text fontWeight="semibold">
                                                    Major : {candidature.major}
                                                </Text>
                                                <Text fontWeight="semibold">
                                                    Degree : {candidature.degree}
                                                </Text>
                                                <Text fontWeight="semibold">
                                                    Scholarship : {candidature.scholarship} %
                                                </Text>
                                            </Flex>
                                        </Heading>

                                        {candidature.receipt_payment === null ? (
                                            <>
                                                <Flex
                                                    flexDirection={"row"}
                                                    align={"center"}
                                                    justify={"start"}
                                                    position="relative"
                                                    py={5}

                                                >
                                                    Amount to be paid : {candidature.price}
                                                    {/* {candidature.price} */}
                                                </Flex>
                                                <Flex
                                                    h={75}
                                                    border="1px dashed gray"
                                                    rounded="md"
                                                    position="relative"
                                                    align="center"
                                                    flexDirection={'column'}
                                                    justify="center"
                                                >
                                                    <Text fontSize={'sm'} color={'gray'}>Upload receipt</Text>
                                                    {!reçu ? (
                                                        <>
                                                            <TbReceipt2 size={45} color="lightgray" />
                                                            <Input
                                                                type="file"
                                                                onChange={handleFileChange}
                                                                accept=".pdf"
                                                                position="absolute"
                                                                top={0}
                                                                h="100%"
                                                                opacity="0"
                                                                cursor="pointer"
                                                            ></Input>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Flex
                                                                h="100%"
                                                                w="100%"
                                                                align="center"
                                                                justify="center"
                                                                flexDirection="column"
                                                                position="relative"
                                                            >
                                                                <FaFilePdf size={35} color="gray" />
                                                                <Text px={2}>{reçu?.name}</Text>

                                                                <IoCloseCircleOutline
                                                                    size={20}
                                                                    color="red  "
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 5,
                                                                        right: 5,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => setReçu()}
                                                                />
                                                            </Flex>
                                                        </>
                                                    )}
                                                </Flex>
                                                {reçu && (
                                                    <Button
                                                        colorScheme="yellow"
                                                        size="sm"
                                                        w="100%"
                                                        mt={1}
                                                        onClick={addReceipt}
                                                    >
                                                        Add receipt
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Flex
                                                    flexDirection={"row"}
                                                    align={"center"}
                                                    justify={"start"}
                                                    position="relative"
                                                    py={5}
                                                >
                                                    <FaFilePdf size={35} color="red" />
                                                    <a href={candidature.receipt_payment} download>
                                                        <Text>
                                                            {candidature.receipt_payment?.split("/").pop()}
                                                        </Text>
                                                    </a>
                                                </Flex>
                                                <Button
                                                    colorScheme="yellow"
                                                    size="sm"
                                                    w="100%"
                                                    mt={1}
                                                    onClick={EditReceipt}
                                                >
                                                    Edit receipt
                                                </Button>

                                                <Flex bg="green.100" rounded="lg" mt={3} p={2}>
                                                    <Text>
                                                        <strong>NB:</strong> please wait until we check your receipt ...
                                                    </Text>
                                                </Flex>
                                            </>
                                        )}
                                    </CardBody>

                                    {/* <CardFooter>
                                            <Button variant='solid' colorScheme='blue'>
                                                Buy Latte
                                            </Button>
                                        </CardFooter> */}
                                </>
                            </Stack>
                        </Card>
                    </Flex>
                )}

                {activeStep === -1 && (
                    <Flex w="100%" justify="center">
                        <Card
                            w={{ base: "100%", lg: '50%' }}
                            backgroundColor={"#fed7d7"}
                            direction={{ base: "column", sm: "row" }}
                            overflow="hidden"
                            variant="outline"
                        >
                            <>
                                <CardBody w={"100%"} px={{ base: 5, md: 10 }}>
                                    {candidature.justification && (
                                        <Alert
                                            fontSize={"sm"}
                                            padding={2}
                                            status="error"
                                            rounded="md"
                                        >
                                            <AlertIcon />
                                            Rejected : {candidature.justification}
                                        </Alert>
                                    )}
                                    <Heading size="sm">
                                        <Flex flexDirection={"column"}>
                                            <Text fontWeight="semibold">
                                                Major : {candidature.major}
                                            </Text>
                                            <Text fontWeight="semibold">
                                                Degree : {candidature.degree}
                                            </Text>
                                            <Text fontWeight="semibold">
                                                Scholarship : {candidature.scholarship} %
                                            </Text>
                                        </Flex>
                                    </Heading>

                                    <Flex
                                        flexDirection={"row"}
                                        align={"center"}
                                        justify={"start"}
                                        position="relative"
                                        pt={5}
                                    >
                                        <img
                                            src={zip}
                                            style={{
                                                marginRight: 5,
                                                width: "30px",
                                                height: "30px",
                                                color: "lightgray",
                                                opacity: ".4",
                                            }}
                                        />

                                        <a href={candidature.files} download>
                                            <Text>{candidature.files?.split("/").pop()}</Text>
                                        </a>

                                    </Flex>
                                    <Flex py={0} w={"100%"} justify={'end'}>
                                        <Text color={'gray'}>Contact our team for more informations</Text>
                                    </Flex>

                                </CardBody>


                            </>
                        </Card>
                    </Flex>
                )}
                {activeStep === 3 && (
                    <Flex w="100%" justify="center">
                        <Card
                            w={{ base: "100%", lg: '50%' }}
                            backgroundColor={"green.100"}
                            direction={{ base: "column", sm: "row" }}
                            overflow="hidden"
                            variant="outline"
                        >
                            <Stack>
                                <>
                                    <CardBody px={{ base: 5, md: 10 }}>
                                        <Heading size="sm">
                                            <Flex flexDirection={"column"}>
                                                <Text fontWeight="semibold">
                                                    Major : {candidature.major}
                                                </Text>
                                                <Text fontWeight="semibold">
                                                    Degree : {candidature.degree}
                                                </Text>
                                                <Text fontWeight="semibold">
                                                    Scholarship : {candidature.scholarship} %
                                                </Text>
                                            </Flex>
                                        </Heading>
                                        {/* {candidature.flight_files &&
                                            <Flex
                                                flexDirection={"row"}
                                                align={"center"}
                                                justify={"start"}
                                                position="relative"
                                                py={5}
                                            >
                                                <Text mr={2}>  your flight details :</Text>
                                                <FaFilePdf size={35} color="red" opacity='.7' />
                                                <a href={candidature.flight_files} download>
                                                    <Text>
                                                        {candidature.flight_files?.split("/").pop()}
                                                    </Text>
                                                </a>
                                            </Flex>
                                        } */}

                                    </CardBody>

                                    {/* <CardFooter>
                                            <Button variant='solid' colorScheme='blue'>
                                                Buy Latte
                                            </Button>
                                        </CardFooter> */}
                                </>
                            </Stack>
                        </Card>
                    </Flex>
                )}

            </Flex>
            {activeStep === 3 && 
            <Flex mb={5} w="100%" justify="center">
            <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                w={{ base: "100%", lg: '30%' }}
            >
                <Stack w={"100%"}>
                    <>
                        <CardBody px={{ base: 5, md: 10 }}>

                            {candidature.etat_flight === null ? (

                                <Flex
                                    flexDirection={"column"}
                                    align={"center"}
                                    justify={"space-between"}
                                    position="relative"
                                    py={3}
                                    w={"100%"}
                                    gap="3"
                                >
                                    <Text> who do you want to take care of the flight ?</Text>
                                    <Flex
                                        gap="3" w={"100%"} flexDirection={"row"}>
                                        <Button
                                            colorScheme="gray"
                                            w="50%"
                                            onClick={() => {
                                                axiosClient
                                                    .patch(
                                                        `/candidature/${location.state.idCandidature}/`,
                                                        {
                                                            etat_flight: "student",
                                                        }
                                                    )
                                                    .then((res) => {
                                                        setCandidature(res.data);
                                                    });
                                            }}
                                        >
                                            You
                                        </Button>
                                        <Button
                                            colorScheme="gray"
                                            w="50%"
                                            onClick={() => {
                                                axiosClient
                                                    .patch(
                                                        `/candidature/${location.state.idCandidature}/`,
                                                        {
                                                            etat_flight: "nihao",
                                                        }
                                                    )
                                                    .then((res) => {
                                                        setCandidature(res.data);
                                                    });
                                            }}
                                        >
                                            our team ?
                                        </Button>
                                    </Flex>

                                </Flex>
                            ) : (
                                <>
                                    {candidature.etat_flight === "student" ? (
                                        <>
                                            {candidature.flight_files === null ? (
                                                <>
                                                    <Flex
                                                        h={75}
                                                        border="1px dashed gray"
                                                        rounded="md"
                                                        position="relative"
                                                        align="center"
                                                        justify="center"
                                                        mt={2}
                                                    >
                                                        {!flightFiles ? (
                                                            <>
                                                                <TbReceipt2 size={45} color="lightgray" />
                                                                <Input
                                                                    type="file"
                                                                    onChange={handleFileChangeFlight}
                                                                    accept=".pdf"
                                                                    position="absolute"
                                                                    top={0}
                                                                    h="100%"
                                                                    opacity="0"
                                                                    cursor="pointer"
                                                                ></Input>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Flex
                                                                    h="100%"
                                                                    w="100%"
                                                                    align="center"
                                                                    justify="center"
                                                                    flexDirection="column"
                                                                    position="relative"
                                                                >
                                                                    <FaFilePdf size={35} color="gray" />
                                                                    <Text px={2}>{flightFiles?.name}</Text>

                                                                    <IoCloseCircleOutline
                                                                        size={20}
                                                                        color="red  "
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 5,
                                                                            right: 5,
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => setFlightFiles()}
                                                                    />
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Flex>
                                                    {flightFiles && (
                                                        <Button
                                                            colorScheme="yellow"
                                                            size="sm"
                                                            w="100%"
                                                            mt={1}
                                                            onClick={addFlightFile}
                                                        >
                                                            Add file
                                                        </Button>
                                                    )}

                                                    <Button
                                                        bg="red.100"
                                                        _hover="none"
                                                        size="sm"
                                                        p={2}
                                                        w={'100%'}
                                                        whiteSpace="normal"
                                                        height='auto'
                                                        blockSize='auto'
                                                        mt={1}
                                                        onClick={() => {
                                                            axiosClient
                                                                .patch(
                                                                    `/candidature/${location.state.idCandidature}/`,
                                                                    {
                                                                        etat_flight: null,
                                                                        flight_files: null,
                                                                    }
                                                                )
                                                                .then((res) => {
                                                                    setCandidature(res.data);
                                                                    setFlightFiles();
                                                                });
                                                        }}
                                                    >
                                                        Click here if you want to change who's the flight is assigned to
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Flex
                                                        flexDirection={"row"}
                                                        align={"center"}
                                                        justify={"start"}
                                                        position="relative"
                                                        py={5}
                                                    >
                                                        <FaFilePdf size={35} color="red" />
                                                        <a href={candidature.flight_files} download>
                                                            <Text>
                                                                {candidature.flight_files
                                                                    ?.split("/")
                                                                    .pop()}
                                                            </Text>
                                                        </a>
                                                    </Flex>
                                                    <Button
                                                        colorScheme="yellow"
                                                        size="sm"
                                                        w="100%"
                                                        mt={1}
                                                        onClick={EditFlightFile}
                                                    >
                                                        Edit receipt
                                                    </Button>

                                                    <Button
                                                        bg="red.100"
                                                        _hover="none"
                                                        size="sm"
                                                        w="100%"
                                                        whiteSpace="normal"
                                                        height='auto'
                                                        blockSize='auto'
                                                        p={2}
                                                        mt={1}
                                                        onClick={() => {
                                                            axiosClient
                                                                .patch(
                                                                    `/candidature/${location.state.idCandidature}/`,
                                                                    {
                                                                        etat_flight: null,
                                                                        flight_files: null,
                                                                    }
                                                                )
                                                                .then((res) => {
                                                                    setCandidature(res.data);
                                                                    console.log(
                                                                        "receiiiiiiiipt data : ",
                                                                        res.data
                                                                    );
                                                                });
                                                        }}
                                                    >
                                                        Click here if you want to change who's the
                                                        flight is assigned to!{" "}
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {candidature.flight_files === null ? (
                                                <>
                                                    <Alert status='info' rounded='lg'>
                                                        <AlertIcon />
                                                        Please wait for the team to contact you...
                                                    </Alert>
                                                    <Button
                                                        bg="red.100"
                                                        _hover="none"
                                                        size="sm"
                                                        w="100%"
                                                        whiteSpace="normal"
                                                        height='auto'
                                                        blockSize='auto'
                                                        p={2}
                                                        mt={1}
                                                        onClick={() => {
                                                            axiosClient
                                                                .patch(
                                                                    `/candidature/${location.state.idCandidature}/`,
                                                                    {
                                                                        etat_flight: null,
                                                                        flight_files: null,
                                                                    }
                                                                )
                                                                .then((res) => {
                                                                    setCandidature(res.data);
                                                                });
                                                        }}
                                                    >
                                                        Click here if you want to change who's the
                                                        flight is assigned to!{" "}
                                                    </Button>
                                                </>
                                            ) : (
                                                <Flex
                                                    flexDirection={"row"}
                                                    align={"center"}
                                                    justify={"start"}
                                                    position="relative"
                                                    py={5}
                                                >
                                                    <FaFilePdf size={35} color="red" opacity='.7' />
                                                    <a href={candidature.flight_files} download>
                                                        <Text>
                                                            {candidature.flight_files?.split("/").pop()}
                                                        </Text>
                                                    </a>
                                                </Flex>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </>
                </Stack>
            </Card>
        </Flex>
            }

            
            <Footer />
        </Box>
    );
}

export default SuivreApplication;
