import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, background, useColorModeValue, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../assets/images/china4.jpg";
import { IoCloudUploadOutline } from 'react-icons/io5';
import upload from "../../../assets/images/upload.png";
import zip from "../../../assets/images/zip.png";
import axiosClient from '../../../axios-client';
import { IoIosCloseCircle } from "react-icons/io";
import ReactCardSlider from "react-card-slider-component";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import Footer from '../../../components/footer/Footer';
function DashboardStudent() {
  const { student } = useStateContext();

  const [majors, setMajors] = useState([])


  useEffect(() => {
    axiosClient.get('programmes/')
      .then((res) => {
        setMajors(res.data.sort().reverse())
      })
      .catch((error) => {
        console.error(error);
      });

  }, [])

  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      }
      else if (window.innerWidth < 900) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 1200) {
        setSlidesPerView(3);
      }
      else {
        setSlidesPerView(4);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box >

      <Flex
        w={"100%"}
        bgRepeat={"no-repeat"}
        backgroundSize={'cover'}
        backgroundImage={china} minH={"300px"}
        backgroundPosition={"center"}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'right' }}
        justify={"center"}

      >
        <Flex w="100%" h="300px" position='absolute' backgroundColor="black" opacity="0.4" ></Flex>
        <Flex zIndex="1" align={"center"} justify={"center"} flexDirection={"column"} >
          <Text color={"white"} fontWeight='bold' fontSize={{base:'xl',md:"2xl", lg:"3xl"}}> A Journey of a Thousand Miles </Text>
          <Text color={"white"} fontWeight='bold' fontSize={{base:'xl',md:"2xl", lg:"3xl"}}> Begins with a Single Step</Text>
        </Flex>

      </Flex>
      <Flex p={10} align={"center"} justify={"center"}>
        <Text color={"#7e172c"} fontWeight='bold' fontSize={{base:'xl',md:"2xl", lg:"3xl"}}>Specializations Available For Studying In China
        </Text>

      </Flex>
      {majors.length >= 4 &&
        < Flex px={{base:10,md:20}} h={"300px"} w={"100%"} >
          <Swiper
            spaceBetween={50}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {majors.map((row) => (

              <SwiperSlide >
                <Card shadow={"xl"} style={{ height: "200px" }}>
                  <CardHeader pb={0}>
                    <Heading size={{base:'sm' , md:"md"}}> {row.nom}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{row.description}</Text>
                  </CardBody>

                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>}
      <Footer />
    </Box >

  )
}

export default DashboardStudent