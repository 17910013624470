// import logo from '../../assets/images/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createRef, useEffect, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
// import login from '../../assets/images/logo.png'
import signInImage from "../../assets/images/loginS.png";

import {
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    Stack,
    Alert,
    AlertIcon,
    HStack,
    InputLeftElement,
    Select,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    DarkMode,
} from "@chakra-ui/react";
import { TfiReload } from 'react-icons/tfi';

// Assets


export default function LoginStudent() {
    const titleColor = "#7e172c";
    const textColor = "gray.400";
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { setTokenStudent, setRefreshStudent, isLoading, setIsLoading } = useStateContext()
    const [message, setMessage] = useState([])
    const [address, setAddress] = useState("")
    const confirmPassword = createRef()

    const toast = useToast()
    const navigate = useNavigate()
    const [registerForm, setRegisterForm] = useState(false)
    const [codeStudent, setCodeStudent] = useState("")
    const [tel, setTel] = useState()
    const [selectedSexe, setSelectedSexe] = useState('')
    const [pwd, setPwd] = useState('');

    const handleSexeChange = (ev) => {
        setSelectedSexe(ev.target.value) // update selected role when option is selected
    }

    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(pwd)) {
            missingRequirements.push("a special Character");
        } else if (!lowercaseRegex.test(pwd)) {
            missingRequirements.push("a lowercase letter");
        } else if (!uppercaseRegex.test(pwd)) {
            missingRequirements.push("an uppercase letter");
        } else if (!digitRegex.test(pwd)) {
            missingRequirements.push("a number");
        } else if (!minLengthRegex.test(pwd)) {
            missingRequirements.push("a minimum length of 8 characters");
        }

        if (missingRequirements.length > 0) {
            const missingText = `You need ${missingRequirements.join(", ")} in your password.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength('The password is correct !');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [pwd]);
    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('The password must be at least 8 characters long.');
        }

        if (!digitRegex.test(password)) {
            errors.push('The password must contain at least one number.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('The password must contain at least one lowercase letter.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('The password must contain at least one uppercase letter.');
        }
        if (!specialchar.test(password)) {
            errors.push('The password must contain at least one special character (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('The password must not exceed 20 characters.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const [disableInputConfirm, setDisableInputConfirm] = useState(false)
    const [generetedPwd, setGeneretedPwd] = useState()
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setPwd(newPassword);
        confirmPassword.current.value = newPassword
        setGeneretedPwd(newPassword)
        setDisableInputConfirm(true)

    };

    //encryption function
    function encryptString(plainText, shift) {
        if (!Number.isInteger(shift) || shift < 1 || shift > 25) {
            throw new Error("Shift must be an integer between 1 and 25.");
        }

        const encryptedArray = [];
        for (let i = 0; i < plainText.length; i++) {
            let charCode = plainText.charCodeAt(i);

            // Encrypt uppercase letters
            if (charCode >= 65 && charCode <= 90) {
                charCode = ((charCode - 65 + shift) % 26) + 65;
            }
            // Encrypt lowercase letters
            else if (charCode >= 97 && charCode <= 122) {
                charCode = ((charCode - 97 + shift) % 26) + 97;
            }

            encryptedArray.push(String.fromCharCode(charCode));
        }

        return encryptedArray.join("");
    }


    const onSubmit = ev => {
        toast.closeAll()
        ev.preventDefault()
        setIsLoading(true)
        const payload = {
            email,
            password,
        }

        axiosClient.post('token/student/', payload)
            .then(({ data }) => {
                setTokenStudent(data.access);
                setRefreshStudent(data.refresh)
                setIsLoading(false);
                navigate('/dashboard-student')
                // localStorage.setItem("mutumStudent", encryptString(email, 25))
                // localStorage.setItem("tsaroStudent", encryptString(password, 25))

            })
            .catch((err) => {
                const response = err.response;
                if (response.data.message === "The password is incorrect") {
                    setMessage({ alert: response.data.message, target: "password" });
                }
                else if (response.data.message === "Your account is disabled") { setMessage({ alert: response.data.message, target: "" }); }
                else {
                    setMessage({ alert: "Email incorrect", target: "email" });
                }

                setIsLoading(false)
            })

    }
    const [showPassword, setShowPassword] = useState(false);
    const isFormFilled = email && password;


    const [dataDemandes, setDataDemandes] = useState([]);
    const [dataApprenants, setDataApprenants] = useState([]);
    const emailRegex = /^\S+@\S+\.\S+$/;
    const isFormValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
    
        let errors = [];
    
        if (!minLengthRegex.test(password)) {
          errors.push('The password must be at least 8 characters long.');
        }
    
        if (!digitRegex.test(password)) {
          errors.push('The password must contain at least one number.');
        }
    
        if (!lowercaseRegex.test(password)) {
          errors.push('The password must contain at least one lowercase letter.');
        }
    
        if (!uppercaseRegex.test(password)) {
          errors.push('The password must contain at least one uppercase letter.');
        }
        if (!specialchar.test(password)) {
          errors.push('The password must contain at least one special character (@#$%^&+=).');
        }
        if (password.length > 20) {
          errors.push('The password must not exceed 20 characters.');
        }
    
        if (errors.length > 0) {
          setMessage(errors[0]);
          return false;
        }
    
        return passwordRegex.test(password);
      };
    useEffect(() => {
        axiosClient.get('/demandeInscription/')
          .then((response) => {
            setDataDemandes(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);

      useEffect(() => {
        axiosClient.get('/students/')
          .then((response) => {
            setDataApprenants(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);

    const isRegisterFormFilled = email && pwd && first_name && last_name && selectedSexe && tel && codeStudent;

    const onRegister = ev => {
        ev.preventDefault()
        setIsLoading(true)
        toast.closeAll()
    
        // Check if the email exists in the dataDemandes array
        const emailExistsInDemandes = dataDemandes.some((demande) => demande.email === email);
    
        const emailExistsInApprenants = dataApprenants.some((apprenant) => apprenant.email === email);
    
        if (emailExistsInApprenants) {
          setMessage({
            alert: "A user with the entered email already exists",
            target: "email",
          });
          setIsLoading(false);
          return;
        }
        else if (emailExistsInDemandes) {
          setMessage({
            alert: "A user with this email address already exists in the registration requests. Please wait while we process your request.",
            target: "email",
          });
          setIsLoading(false);
          return;
        }
        
      
        const formData = new FormData()
        formData.append("first_name", first_name)
        formData.append("last_name", last_name)
        formData.append("email", email)
        formData.append("tel", tel)
        formData.append("password", pwd)
        formData.append("etat", true)
        formData.append("address", address)
        formData.append("gender", selectedSexe)
        formData.append("nb_student", codeStudent)
        formData.append("isChecked", true)
    
    
        setMessage({})
        if (pwd !== confirmPassword.current.value) {
          setMessage({ alert: "Please confirm the password.", target: "password" })
          setIsLoading(false)
        } else if (passwordStrength !== 'Fort' && pwd.length > 0 && !isFormValid(pwd)) {
          setMessage({ alert: "Please enter a valid password.", target: "password" });
          setIsLoading(false)
          return;
        }
    
        else if (!emailRegex.test(email)) {
          setMessage({ alert: "Please enter a valid email.", target: "email" });
          setIsLoading(false)
        }
    
        else {
          axiosClient.post('demandeInscription/', formData, {
            first_name: first_name,
            last_name: last_name
          })
            .then(() => {
              setIsLoading(false)
              toast({
                title: "Request Sent",
                description: "Your request has been submitted successfully, please wait for your account to be validated.",
                status: "success",
                position:"top",
                duration: 2000,
                variant:'left-accent',
                isClosable: true,
              });
    
              navigate('/login-student')
              setRegisterForm(false)
              setFirstName()
              setLastName()
              setAddress()
              setCodeStudent()
              setEmail()
              setPassword()
            //   setPwd()
              setTel()
              setSelectedSexe()
            })
            .catch((err) => {
              const response = err.response;
              // if (response.status === 400) {
              //   setMessage({ alert: "This mail already existsparmi les demandes d'inscription. Veuillez patienter pendant que nous traitons votre demande.", target: "email" });
              // } else {
              setMessage({ alert: response.data, target: "all" });
              // }
              setIsLoading(false);
            });
           
        }
      }
    return (
        <Flex position='relative' bg="white">
            <Flex
                minH='100vh'
                h={{ base: "fit-content", lg: "fit-content" }}
                w='100%'
            // maxW='1044px'
            // mx='auto'

            // pt={{ sm: "100px", md: "0px" }}
            // flexDirection='column'
            // me={{ base: "auto", lg: "50px", xl: "auto" }}
            >
                {registerForm ?
                    <Flex

                        alignItems='center'
                        justifyContent='center'
                        style={{ userSelect: "none" }}
                        mx={{ base: "auto", lg: "unset" }}
                        // ms={{ base: "auto", lg: "auto" }}
                        w={{ base: "100%", md: "50%", lg: "50%" }}
                        py={{ base: "50px", lg: 10}}
                        px={{ base: "30px", md: 10, lg: "40px", xl: "110px" }}>
                        <Flex
                            direction='column'
                            w='100%'
                            background='transparent'
                        // mt={{ base: "50px", md: "80px", lg: "80px", xl: "100px" }}
                        // mb={{ base: "60px", lg: "80px" }}
                        >
                            <Heading color={titleColor} fontSize='32px' mb='20px'>
                                Student area Register
                            </Heading>
                            <Text
                                mb='30px'
                                ms='4px'
                                color={textColor}
                                fontWeight='bold'
                                fontSize='14px'>
                                Enter your infos to sign up .
                            </Text>
                            {message.alert &&
                                <Alert status='error' rounded="md">
                                    <AlertIcon />
                                    {message.alert}
                                </Alert>
                            }
                            <Stack spacing={2} >
                                <HStack spacing={2}>
                                    <Box w="50%">
                                        <FormControl id="firstName" isRequired >
                                            <FormLabel>First name</FormLabel>
                                            <Input value={first_name} name="first_name" type="text" onChange={(e) => setFirstName(e.target.value)} placeholder='Enter the fisrt name' maxLength={25} />
                                        </FormControl>
                                    </Box>
                                    <Box w="50%">
                                        <FormControl id="lastName" isRequired >
                                            <FormLabel>Last name</FormLabel>
                                            <Input value={last_name} name="last_name" onChange={(e) => setLastName(e.target.value)} type="text" placeholder='Enter the last name' maxLength={25} />
                                        </FormControl>
                                    </Box>

                                </HStack>

                                <HStack spacing={2}>
                                    <Box w={"100%"}>
                                        <FormControl id="email" isRequired >
                                            <FormLabel>Email address</FormLabel>
                                            <Input value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder='Enter the email address' maxLength={50} />
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <HStack spacing={2}>
                                    <Box w="50%">
                                        <FormControl id="password" isRequired >
                                            <FormLabel>Password</FormLabel>
                                            <InputGroup>
                                            <InputLeftElement onClick={generatePassword} as={'Button'} >
                                                <TfiReload color='gray.300' />
                                            </InputLeftElement>
                                                <Input value={pwd} name="password" type={showPassword ? 'text' : 'password'}
                                                    onChange={(e) => setPwd(e.target.value)} onCopy={(e) => e.preventDefault()}
                                                    placeholder='Enter the password' />
                                                <InputRightElement h={'full'}>
                                                    <Button
                                                        variant={'ghost'}
                                                        onClick={() =>
                                                            setShowPassword((showPassword) => !showPassword)
                                                        }>
                                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>

                                        </FormControl>
                                    </Box>

                                    <Box w={"50%"}>
                                        <FormControl id="password_confirmation" isRequired >
                                            <FormLabel>Confirm password</FormLabel>
                                            <InputGroup>
                                            {(disableInputConfirm && pwd === generetedPwd) ?
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirm password' disabled />
                                                :
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirm password' />
                                            }
                                            </InputGroup>
                                        </FormControl>
                                    </Box>
                                </HStack>
                                {pwd.length > 0 ?
                                    <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                    : ""}
                                <HStack spacing={2}>
                                    <Box w="50%">
                                        <FormControl id="rue" isRequired>
                                            <FormLabel ml={0.5}>Phone number</FormLabel>
                                            <Input value={tel} onChange={(e) => setTel(e.target.value)} name="tel" type="number" placeholder='Enter the phone number' maxLength={50} />
                                        </FormControl>

                                    </Box>
                                    <Box w="50%">
                                        <FormControl id="sexe" isRequired>
                                            <FormLabel ml={0.5}>Sex</FormLabel>
                                            <Select placeholder="Select" onChange={handleSexeChange}>

                                                <option value="Female">Female</option>
                                                <option value="male">male</option>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                </HStack>
                                <HStack spacing={2} >
                                    <Box w="50%">
                                        <FormControl id="Address" >
                                            <FormLabel>Address</FormLabel>
                                            <Input value={address} name="address" onChange={(e) => setAddress(e.target.value)} type="text" placeholder='Enter the address' maxLength={15} />
                                        </FormControl>
                                    </Box>
                                    <Box w="50%">
                                        <FormControl id="rue" isRequired>
                                            <FormLabel ml={0.5}>Student number</FormLabel>
                                            <Input value={codeStudent} onChange={(e) => setCodeStudent(e.target.value)} name="codeStudent" type="text" placeholder='Enter the student code' maxLength={50} />
                                        </FormControl>
                                    </Box>

                                </HStack>





                            </Stack>

                            <Stack spacing={8} pt={7} >

                                <Stack >
                                    <Text align={'center'}>
                                        Already have an account ? &nbsp;
                                        <Link onClick={() => {setRegisterForm(false)
                                        setMessage('')}} >Login.</Link>

                                    </Text>
                                </Stack>
                                <Button
                                    _hover={
                                        {
                                            color: "red.600",
                                            background: "transparent",
                                            border: '1px solid',
                                            borderColor: 'red.600'
                                        }
                                    }
                                    transition={".3s ease-in-out"}
                                    fontSize='13px'
                                    type="submit" onClick={onRegister} isLoading={isLoading}
                                    color={"#f2c478"} background='#7e172c' isDisabled={!isRegisterFormFilled}>
                                    {isLoading ? <Spinner size="sm" /> : "Register"}
                                </Button>
                                
                            </Stack>

                        </Flex>
                    </Flex> :
                    <Flex

                        alignItems='center'
                        justifyContent='center'
                        style={{ userSelect: "none" }}
                        mx={{ base: "auto", lg: "unset" }}
                        // ms={{ base: "auto", lg: "auto" }}
                        w={{ base: "100%", md: "50%", lg: "50%" }}
                        px={{ base: "20px", md: 0, lg: "40px", xl: "150px" }}
                    >
                        <Flex
                            direction='column'
                            w='100%'
                            background='transparent'
                        // mt={{ base: "50px", md: "150px", lg: "160px", xl: "150px" }}
                        // mb={{ base: "60px", lg: "95px" }}
                        >
                            <Heading color={titleColor} fontSize='32px' mb='20px'>
                                Student area Login
                            </Heading>
                            <Text
                                mb='36px'
                                ms='4px'
                                color={textColor}
                                fontWeight='bold'
                                fontSize='14px'>
                                Enter your email address and password to log in.
                            </Text>
                            {message.alert &&
                                <Alert status='error' rounded="md">
                                    <AlertIcon />
                                    {message.alert}
                                </Alert>
                            }
                            <FormControl mb="3" id="email" isRequired >
                                <FormLabel color={"gray.800"} >Email address</FormLabel>
                                <Input color={titleColor} onChange={(e) => setEmail(e.target.value)} type="text" placeholder='Tapez votre adresse email' />
                            </FormControl>
                            <FormControl id="password" isRequired isInvalid={message.target === "password"}>
                                <FormLabel mb="3" color={"gray.800"}>Passowrd</FormLabel>
                                <InputGroup mb="3">
                                    <Input color={titleColor} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder='Tapez votre mot de passe' />
                                    <InputRightElement h={'full'}>
                                        <Button
                                            color={"gray.800"}
                                            variant={'ghost'}
                                            _hover='none'
                                            onClick={() =>
                                                setShowPassword((showPassword) => !showPassword)
                                            }>
                                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>

                            <Stack spacing={6}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'}>
                                    <Link href='/reset-password' color={'#f2c478'}>Forgot password ?</Link>
                                </Stack>
                                <Stack >
                                    <Text align={'center'}>
                                        Do not have an account ? &nbsp;
                                        <Link onClick={() => {setRegisterForm(true)
                                        setMessage('')}}
                                         >Create one.</Link>

                                    </Text>
                                </Stack>
                                <Button
                                    _hover={
                                        {
                                            color: "red.600",
                                            background: "transparent",
                                            border: '1px solid',
                                            borderColor: 'red.600'
                                        }
                                    }
                                    transition={".3s ease-in-out"}
                                    fontSize='13px'
                                    type="submit" onClick={onSubmit} isLoading={isLoading}
                                    color={"#f2c478"} background='#7e172c' isDisabled={!isFormFilled}>
                                    {isLoading ? <Spinner size="sm" /> : "Login"}
                                </Button>
                                <Button
                            variant='outline'
                            display={{base: 'block', lg:'none'}}
                            fontSize='13px'
                            onClick={() => navigate('/login')}
                            colorScheme='yellow' >
                            Login as Admin
                        </Button>
                            </Stack>


                        </Flex>
                    </Flex>
                }


                <Box
                    display={{ base: "none", lg: "block" }}
                    overflowX='hidden'
                    h='100%'
                    maxW={{ md: "50vw", lg: "50vw" }}
                    minH='100vh'
                    w='960px'
                    position='absolute'
                    right='0px'>
                    <Box
                        bgImage={signInImage}
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                           <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        }}
                    ></Box>
                        <Text
                        zIndex={1}
                            mb={2}
                            textAlign='center'
                            color='white'
                            letterSpacing='8px'
                            fontSize='20px'
                            fontWeight='500'>
                            BIENVENU DANS NIHAO STUDENT
                        </Text>
                        <Text
                        zIndex={1}
                            textAlign='center'
                            color='transparent'
                            letterSpacing='10px'
                            fontSize='36px'
                            fontFamily="initial"
                            fontWeight='bold'
                            bgClip='text !important'
                            bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                            NIHAO
                        </Text>
                        <Button
                            fontSize='13px'
                            onClick={() => navigate('/login')}
                            color={"#f2c478"} background='#7e172c' >
                            Login as admin
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

