import { createContext, useContext, useState, useEffect } from "react";
import axiosClient from "../axios-client";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => {},
  setToken: () => {},
  setNotification: () => {},
  setIsLoading: () => {},
 
});

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [student, setStudent] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [refresh, _setRefresh] = useState(
    localStorage.getItem("REFRESH_TOKEN")
  );
  const [tokenStudent, _setTokenStudent] = useState(localStorage.getItem("ACCESS_TOKEN_STUDENT"));
  const [refreshStudent, _setRefreshStudent] = useState(
    localStorage.getItem("REFRESH_TOKEN_STUDENT")
  );

  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);
  

  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (tokenStudent) {
      axiosClient
        .get("auth/student/")
        .then(({ data }) => {
          setStudent(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token,tokenStudent]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };

  const setTokenStudent = (token) => {
    _setTokenStudent(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN_STUDENT", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN_STUDENT");
    }
  };


  const setRefresh = (refresh) => {
    _setRefresh(refresh);
    if (refresh) {
      localStorage.setItem("REFRESH_TOKEN", refresh);
    } else {
      localStorage.removeItem("REFRESH_TOKEN");
    }
  };

  const setRefreshStudent = (refresh) => {
    _setRefreshStudent(refresh);
    if (refresh) {
      localStorage.setItem("REFRESH_TOKEN_STUDENT", refresh);
    } else {
      localStorage.removeItem("REFRESH_TOKEN_STUDENT");
    }
  };

  const setIsLoading = (v) => {
    _setIsLoading(v);
  };
  const [updateCount, setUpdateCount] = useState(0);
  const upd = () => {
    setUpdateCount((prevCount) => prevCount + 1);
  };

  return (
    <StateContext.Provider
      value={{
        upd,
        user,
        setUser,
        student,
        setStudent,
        token,
        tokenStudent,
        refreshStudent,
        setToken,
        setTokenStudent,
        setRefreshStudent,
        refresh,
        setRefresh,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
