import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../axios-client";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import MyPaginantion from "../../../components/MyPagination";
import { AiOutlineUserAdd } from "react-icons/ai";
import { PiPlusBold } from "react-icons/pi";
import empty from "../../../assets/images/bell.png";
import animationData from "../../../assets/lotties/notifs.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Notifs_Students() {
  const navigate = useNavigate();
  const { user, setUser } = useStateContext();
  const [notificationsData, setNotificationsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [id, setId] = useState(null);
  const toast = useToast();
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setMessage] = useState("");
  const [notifData, setNotifData] = useState();
  const [notifId, setNotifId] = useState("");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [selectedCible, setSelectedCible] = useState(null);
  const [selectedC, setSelectedC] = useState("");

  //search variable
  const [searchTerm, setSearchTerm] = useState("");

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [changeModal, setChangeModal] = useState(true);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleCibleChange = (ev) => {
    setSelectedCible(ev.target.value);
  };
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 5;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  //get the data
  //   useEffect(() => {
  //     axiosClient
  //       .get("notificationsAdmin/")
  //       .then((res) => {
  //         setNotificationsData(res.data.sort().reverse());
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }, []);
  const { student } = useStateContext();

  useEffect(() => {
    axiosClient
      .get("/notificationsAdmin/")
      .then((response) => {
        setNotificationsData(
          response.data
            .filter(
              (e) =>
                e.cible === "Students" ||
                e.cible === "All" ||
                e.student === student.id
            )
            .sort()
            .reverse()
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [student]);

  function update() {
    axiosClient
      .get("notificationsAdmin/")
      .then((res) => {
        setNotificationsData(res.data.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //search method
  const filteredData = useCallback(() => {
    return notificationsData.filter((row) => {
      return (
        row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.content
          .toString()
          .toLowerCase()
          .includes(searchTerm.toString().toLowerCase()) ||
        row.cible
          .toString()
          .toLowerCase()
          .includes(searchTerm.toString().toLowerCase())
      );
    });
  }, [notificationsData, searchTerm]);

  const handleDelete = (id) => {
    if (!id) return;
    axiosClient
      .delete(`/notificationsAdmin/${id}/`)
      .then((response) => {
        setNotificationsData((prevData) =>
          prevData.filter((row) => row.id !== id)
        );
        // setNotificationsData(notificationsData.filter((row) => row.id !== id));
        toast({
          description: "la notification est supprimé avec succès",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
          variant: "left-accent",
        });
      })
      .catch((error) => {
        console.error(error);
      });
    onCloseAlert();
  };

  const AjoutNotification = () => {
    if (!title || title.trim() === "") {
      setMessage("Veuillez remplir le titre");
      return;
    }
    if (!content || content.trim() === "") {
      setMessage("Veuillez remplir le contenu");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("cible", selectedCible);
    formData.append("content", content);

    axiosClient.post("/notificationsAdmin/", formData).then((response) => {
      setNotificationsData([response.data, ...notificationsData]);
      setTitle("");
      setContent("");
      setSelectedCible("");
      setMessage("");

      toast({
        description: `La notificiation est ajoutée avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
        variant: "left-accent",
      });
    });
    onClose();
  };

  const UpdateNotif = (id) => {
    const formData = new FormData();
    if (title) formData.append("title", title);
    if (selectedCible) formData.append("cible", selectedCible);
    if (content) formData.append("content", content);

    axiosClient.patch(`/notificationsAdmin/${id}/`, formData).then(() => {
      update();

      toast({
        description: `La notification est modifiée avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
        variant: "left-accent",
      });
      setTitle("");
      setContent("");
      setSelectedCible("");
      setMessage("");
    });

    onClose();
  };
  const IsRead = (id) => {
    axiosClient
      .get(`/notificationsAdmin/${id}/`)
      .then((response) => {
        const notification = response.data;

        if (!notification.is_read.includes(student.id)) {
          notification.is_read.push(student.id);

          axiosClient
            .put(`/notificationsAdmin/${id}/`, {
              ...notification,
              is_read: notification.is_read,
            })
            .then((response) => {
              console.log("ok");
              update();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const myColor = useColorModeValue("gray.50", "gray.700");

  return (
    <Box>
      {notificationsData.length === 0 ? (
        <Flex
          mb={"10"}
          mt={20}
          flexDirection={"column"}
          align={"center"}
          justify={"center"}
        >
          <Image
            height={150}
            width={150}
            rounded={"md"}
            alt={"empty"}
            src={empty}
            objectFit={"empty"}
          />
          <Text mt={10} color={"#aeaeae"}>
            There is no notifications to display
          </Text>
        </Flex>
      ) : (
        <Flex
          direction="column"
          mt={{ sm: "25px", md: "0px" }}
          p={{ base: 0, lg: 7 }}
        >
          <Grid
            templateColumns={{ sm: "1fr", md: "1fr", lg: "60%" }}
            justifyContent={{ lg: "center" }}
          >
            <Flex>
              <Box
                w="100%"
                display={"flex"}
                justifyContent="space-between"
                mt={2}
                p={5}
              >
                <Text
                  fontFamily="Plus Jakarta Display"
                  as="cite"
                  fontSize={{ base: "18px", md: "20px", lg: "25px" }}
                  color="gray"
                >
                  Notifications
                </Text>
              </Box>
            </Flex>
            <Card bg={"none"} border={"none"} shadow={"none"} display={"flex"}>
              <CardBody px="2px">
                <Flex
                  px={5}
                  justifyContent="end"
                  alignItems="center"
                  direction={{ base: "column", md: "row" }}
                >
                  {(user.role === "ADMIN" ||
                    user.role === "SUPERADMIN" ||
                    user.role === "RESPO") && (
                    <Select
                      borderRadius={"5"}
                      bg={myColor}
                      fontSize={{ base: 13, lg: 16 }}
                      size={"sm"}
                      w={{ base: "100%", md: "25%" }}
                      mr={{ base: 0, md: 5 }}
                      mb={4}
                      value={selectedC}
                      onChange={(e) => setSelectedC(e.target.value)}
                    >
                      <option value="">Tout</option>
                      <option value="TeamMembers">Team members</option>
                      <option value="Partners">Partners</option>
                      <option value="Students">Students</option>
                      <option value="All">All</option>
                    </Select>
                  )}
                  <InputGroup size={"sm"} w={{ base: "100%", md: "40%" }}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="gray.300" />}
                    />
                    <Input
                      borderRadius={"5"}
                      placeholder="Search"
                      value={searchTerm}
                      bg={myColor}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      mb={4}
                      sx={{
                        marginLeft: "auto",
                      }}
                    />{" "}
                  </InputGroup>
                </Flex>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "stretch", md: "center" }}
                  justify={{ base: "flex-start", md: "space-between" }}
                  p={5}
                >
                  <Flex
                    direction="column"
                    width={{ base: "100%", lg: "100%" }}
                    justify={{ base: "flex-start", md: "space-between" }}
                  >
                    {filteredData()
                      .slice(
                        currentPage * pageSize,
                        currentPage * pageSize + pageSize
                      )
                      .filter(
                        (row) => selectedC === "" || row.cible === selectedC
                      )
                      .map((row) => (
                        <Stack
                          backgroundColor={
                            row.is_read.includes(student.id)
                              ? "white"
                              : "gray.100"
                          }
                          onClick={() => {
                            IsRead(row.id);
                            navigate(`/notification-details`, {
                              state: {
                                idNotif: row.id,
                              },
                            });
                          }}
                          cursor={"pointer"}
                          mb={4}
                          rounded={"lg"}
                          p={5}
                          border={row.is_read.includes(student.id) && "1px solid #ebebeb"}
                          boxShadow={
                            row.is_read.includes(student.id)
                              ? "none"
                              : "md"
                          }
                          alignItems={"start"}
                          justify="space-between"
                          // as={"Button"}
                        >
                          <Flex
                            direction="row"
                            justify="space-between"
                            ml={"auto"}
                            width="full"
                          >
                            <Stack direction={"row"} align={"center"}>
                              <Text fontSize={"lg"} fontWeight="semibold">
                                {row.title}
                              </Text>
                              <Stack mt={1}></Stack>
                            </Stack>
                            <Text mt={2} fontSize={"xs"} color={"gray.500"}>
                            {new Date(row.date_creation).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}
                            </Text>
                          </Flex>

                          <Flex
                            direction="row"
                            width="full"
                            ml={"auto"}
                            justify="space-between"
                          >
                            <Text
                              fontSize={{ base: "sm" }}
                              textAlign={"left"}
                              w={{ base: "80%", md: "80%", lg: "85%" }}
                            >
                              {row.content.split(" ").length > 10
                                ? row.content
                                    .split(" ")
                                    .slice(0, 20)
                                    .join(" ") + "..."
                                : row.content}
                            </Text>
                          </Flex>
                        </Stack>
                      ))}
                    {(filteredData().length === 0 ||
                      filteredData().filter(
                        (row) => selectedC === "" || row.cible === selectedC
                      ).length === 0) && (
                      <Flex alignItems={"center"}>
                        <Text>No matching rows were found.</Text>
                      </Flex>
                    )}
                    <Flex justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPaginantion
                          data={filteredData()}
                          searchInput={searchTerm}
                          PAGE_SIZE={pageSize}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Flex
              px={10}
              display={{ base: "none", lg: "flex" }}
              align={"center"}
            ></Flex>
          </Grid>
        </Flex>
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You won't be able to undo this action later.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Close
              </Button>
              <Button onClick={() => handleDelete(id)} colorScheme="red" ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {changeModal ? (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setMessage("");
            setSelectedCible("");
            setTitle("");
            setContent("");
            setSelectedCible("");
          }}
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>Add a new notification</ModalHeader>

            <ModalCloseButton />
            <ModalBody pb={6}>
              {message && (
                <Alert
                  mb={2}
                  fontSize={"sm"}
                  padding={2}
                  variant={"left-accent"}
                  status="error"
                  rounded="md"
                >
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Flex flexDirection={"column"} align={"center"}>
                <Flex w="100%" flexDirection={"column"}>
                  <FormControl isRequired mb={3}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      maxLength={50}
                      name="type"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="notification title.."
                    />
                  </FormControl>
                  <FormControl isRequired mb={3}>
                    <FormLabel>Notification</FormLabel>
                    <Textarea
                      height={"100px"}
                      resize={"none"}
                      type="text"
                      maxLength={250}
                      name="message"
                      onChange={(e) => setContent(e.target.value)}
                      placeholder="Content..."
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Target</FormLabel>
                    <Select
                      onChange={handleCibleChange}
                      placeholder="Select option"
                    >
                      <option value="TeamMembers">Team members</option>
                      <option value="Partners">Partners</option>
                      <option value="Students">Students</option>
                      <option value="All">All</option>
                    </Select>
                  </FormControl>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {!title || !content || !selectedCible ? (
                <Button
                  colorScheme="blue"
                  title="Entrer le nom de catégorie"
                  isDisabled
                >
                  Add
                </Button>
              ) : (
                <Button colorScheme="blue" onClick={AjoutNotification}>
                  Add
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setMessage("");
            setSelectedCible("");
            setTitle("");
            setContent("");
            setSelectedCible("");
          }}
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>Edit notification</ModalHeader>

            <ModalCloseButton />
            <ModalBody pb={6}>
              {message && (
                <Alert
                  mb={2}
                  fontSize={"sm"}
                  padding={2}
                  variant={"left-accent"}
                  status="error"
                  rounded="md"
                >
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Flex flexDirection={"column"} align={"center"}>
                <Flex w="100%" flexDirection={"column"}>
                  <FormControl isRequired mb={3}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      maxLength={50}
                      defaultValue={notifData.title}
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="notification title..."
                    />
                  </FormControl>
                  <FormControl isRequired mb={3}>
                    <FormLabel>Notification</FormLabel>
                    <Textarea
                      height={"100px"}
                      defaultValue={notifData.content}
                      resize={"none"}
                      type="text"
                      maxLength={250}
                      name="message"
                      onChange={(e) => setContent(e.target.value)}
                      placeholder="Content..."
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Target</FormLabel>
                    <Select
                      onChange={handleCibleChange}
                      defaultValue={notifData.cible}
                      placeholder="Select option"
                    >
                      <option value="TeamMembers">Team members</option>
                      <option value="Partners">Partners</option>
                      <option value="Students">Students</option>
                      <option value="All">All</option>
                    </Select>
                  </FormControl>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={() => UpdateNotif(notifId)}>
                Edit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default Notifs_Students;
