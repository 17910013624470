import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    InputGroup,
    InputRightElement,
    useColorModeValue,
    Text,
    useToast,
    Heading,
    Alert,
    AlertIcon
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import axiosClient, { linkImage } from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import React, { useState, useEffect, useRef } from 'react';
import { EditIcon, ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { useNavigate,useLocation } from 'react-router-dom';

import axios from 'axios';
const ResetPwdPage = () => {
    
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { userId ,token,expires } = useParams();
    const navigate=useNavigate();
    const [isExpired, setIsExpired] = useState(false);
     //toast variable
     const toast = useToast()
    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    useEffect(() => {
      const expirationTime = new Date(expires);
      const currentTime = new Date();
    
      if (expirationTime > currentTime) {
        setIsExpired(false);
      } else {
        setIsExpired(true);
      }
    }, [expires]);
   



    const checkPasswordStrength = () => {
      const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
      const minLengthRegex = /^.{8,}$/;
      const startLength = /^.{2,}$/;
      const digitRegex = /\d/;
      const lowercaseRegex = /[a-z]/;
      const uppercaseRegex = /[A-Z]/;

      let missingRequirements = [];

      if (!specialchar.test(newPassword)) {
          missingRequirements.push("a special Character");
      } else if (!lowercaseRegex.test(newPassword)) {
          missingRequirements.push("a lowercase letter");
      } else if (!uppercaseRegex.test(newPassword)) {
          missingRequirements.push("an uppercase letter");
      } else if (!digitRegex.test(newPassword)) {
          missingRequirements.push("a number");
      } else if (!minLengthRegex.test(newPassword)) {
          missingRequirements.push("a minimum length of 8 characters");
      }

      if (missingRequirements.length > 0) {
          const missingText = `You need ${missingRequirements.join(", ")} in your password.`;
          setPasswordStrength(missingText);
          setPasswordColor('red.500');
      } else {
          setPasswordStrength('The password is correct !');
          setPasswordColor('green');
      }
  }
    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);
    


    // check the password complexity
    const isPasswordValid = (password) => {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
      const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
      const minLengthRegex = /^.{8,}$/;
      const digitRegex = /\d/;
      const lowercaseRegex = /[a-z]/;
      const uppercaseRegex = /[A-Z]/;

      let errors = [];

      if (!minLengthRegex.test(password)) {
          errors.push('The password must be at least 8 characters long.');
      }

      if (!digitRegex.test(password)) {
          errors.push('The password must contain at least one number.');
      }

      if (!lowercaseRegex.test(password)) {
          errors.push('The password must contain at least one lowercase letter.');
      }

      if (!uppercaseRegex.test(password)) {
          errors.push('The password must contain at least one uppercase letter.');
      }
      if (!specialchar.test(password)) {
          errors.push('The password must contain at least one special character (@#$%^&+=).');
      }
      if (password.length > 20) {
          errors.push('The password must not exceed 20 characters.');
      }

      if (errors.length > 0) {
          setMessage(errors[0]);
          return false;
      }

      return passwordRegex.test(password);
  };

    // reset password 
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`reset_password/${userId}/`, {
                new_password: newPassword,
                confirm_password: confirmPassword,
              });
            if (response.data.success) {
                toast({
                    description: "The password is successfuly updated",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                navigate('/login')

            }
            setMessage(response.data.error);
        } catch (err) {
            setError(err.response.data.error);
        }
    };
    const colorModeValue = useColorModeValue('gray.50', 'gray.800');
    const colorModeValue1 = useColorModeValue('white', 'gray.700');

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
      >
        {isExpired ? (
          <Box>
            <Alert status="error" rounded="md">
              <AlertIcon />
              The link has expired. You've gone over the allowed time
            </Alert>
          </Box>
        ) : (
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            rounded={'xl'}
            boxShadow={'2xl'}
            p={6}
            my={12}
          >
            <Heading  color={"red.700"} lineHeight={1.1} fontSize={{ base: '2xl', md: '2xl' }}>
            Password reset
            </Heading>
            {message && (
              <Alert status="error" rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            )}
            <FormControl mt={4}>
              <FormLabel color={'gray.600'} >New password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  id="new_password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onCopy={(e) => e.preventDefault()}
                />
                <InputRightElement h={'full'}>
                  <Button
                  color={'gray.600'}
                    variant={'ghost'}
                    onClick={() => setShowPassword((showPassword) => !showPassword)}
                  >
                    {showPassword ? <ViewIcon color={'gray.300'} /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {newPassword.length > 0 && (
              <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>
                {passwordStrength}
              </Text>
            )}
            <FormControl mt={4}>
              <FormLabel color={'gray.600'}>Confirm password</FormLabel>
              <Input
                type="password"
                id="confirm_password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Stack spacing={6}>
              <Button
                name="submit"
                type="submit"
                onClick={handleFormSubmit}
                color={'white'}
                _hover={'none'}
                backgroundColor={'gray.500'}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
        )}
      </Flex>
    );
      
}

export default ResetPwdPage