import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Badge, Flex, FormControl, FormLabel, Grid,GridItem, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, background, useColorModeValue, useSteps, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../assets/images/china4.jpg";
import { IoCloudUploadOutline } from 'react-icons/io5';
import upload from "../../../assets/images/upload.png";
import zip from "../../../assets/images/zip.png";
import axiosClient from '../../../axios-client';
import { IoIosCloseCircle } from "react-icons/io";
import ReactCardSlider from "react-card-slider-component";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import Footer from '../../../components/footer/Footer';
import { IoMdCheckmark } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { FiDollarSign } from "react-icons/fi";
import { LuTimerReset } from "react-icons/lu";
import { FaClockRotateLeft, FaPlaneDeparture } from "react-icons/fa6";

function MyApplications() {

    const navigate = useNavigate()

    const { student } = useStateContext();
    const [loading, setLoading] = useState(true); // Introduce a loading state

    const [candidature, setCandidature] = useState([])


    useEffect(() => {
        axiosClient.get('candidature/')
            .then((res) => {
                const filteredCandidature = res.data.filter((row) => row.student === student?.id);
                setCandidature(filteredCandidature);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [student.id]);

    return (
        <>
        <Box  >

            <Grid mb={60} templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} px={{base:10,md:20}} py={10} w={"100%"} gap='5' >

                {
                    candidature.map((row, key) => (
                        <GridItem w="100%" cursor='pointer' onClick={()=>{
                            navigate('/my-application',{
                                state:{
                                    idCandidature:row.id
                                }
                            })
                        }}>
                            <Card
                                key={key}
                                direction='row'
                                overflow='hidden'
                                variant='outline'
                                h="100%"
                            >

                                <Flex h='100%' align='center' px={5}>
                                {row.status === "pending" &&  <Avatar bg='gray.500' icon={<LuTimerReset fontSize='1.5rem' />} />}
                                {row.status === "payment" &&  <Avatar bg='purple.400' icon={<FiDollarSign fontSize='1.5rem' />} />}
                                {row.status === "flight" &&  <Avatar bg='blue.500' icon={<FaPlaneDeparture fontSize='1.5rem' />} />}
                                {row.status === "rejected" &&  <Avatar bg='red.500' icon={<AiOutlineClose fontSize='1.5rem' />} />}
                                {row.status === "accepted" &&  <Avatar bg='green.500' icon={<IoMdCheckmark fontSize='1.5rem' />} />}
                                   
                                </Flex>

                                <Stack>
                                    <CardBody>
                                        <Heading size='md'>Program</Heading>

                                        <Text py='2'>
                                            {row.major} / {row.degree} / {row.scholarship}
                                        </Text>
                                        <Text> Application status : {row.status}</Text>
                                    </CardBody>
                                    

                                </Stack>
                            </Card>
                        </GridItem>
                    ))
                }


            </Grid>

            
        </Box >
        <Footer   />
        </>

    )
}

export default MyApplications