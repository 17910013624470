import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Select,
  HStack,
  Td,
  Heading,
  TableContainer,
} from "@chakra-ui/react";
import china from "../../../assets/images/china.jpg";
// Custom components
// import Card from '../../components/Card/Card.js';
// import CardBody from '../../components/Card/CardBody.js';
// // import CardHeader from '../../components/Card/CardHeader.js';
// import BarChart from '../../../components/Charts/BarChart';
// import LineChart from '../../../components/Charts/LineChart';
import { Swiper, SwiperSlide } from "swiper/react";

import IconBox from "../../../components/Icons/IconBox";
// Icons
// import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from '../../../components/Icons/Icons.js';
// import DashboardTableRow from '../../components/Tables/DashboardTableRow';
// import TimelineRow from '../../components/Tables/TimelineRow';
import React, { useCallback, useEffect, useState } from "react";
import {
  IoCheckmarkDoneCircleSharp,
  IoEllipsisHorizontal,
} from "react-icons/io5";

import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../axios-client";
import { useNavigate } from "react-router-dom";

import { FaStar } from "react-icons/fa";
import DonutChart from "../../../components/Charts/DonutChart";
// import ReactApexChart from 'react-apexcharts';
import MyPaginantion from "../../../components/MyPagination";
import Footer from "../../../components/footer/Footer";

// import { dashboardTableData, timelineData } from 'variables/general';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
export default function Dashboard() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const { user } = useStateContext();
  const [students, setStudents] = useState([]);
  const [partenaires, setPartenaires] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersT, setTeamMembersT] = useState([]);
  const [teamMembersC, setTeamMembersC] = useState([]);

  const [listStudents, setListStudents] = useState([]);
  const [listTeamMembers, setListTeamMembers] = useState([]);

  const [listPartenaires, setListPartenaires] = useState([]);
  const [listOffres, setListOffres] = useState([]);
  const [listRatings, setListRatings] = useState([]);
  const [selectedP, setSelectedP] = useState([]);
  const navigate = useNavigate();
  const [plainte, setPlainte] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [defaultDonut, setDefaultDonut] = useState("years");
  const [unsolvedTickets, setUnsolvedTickets] = useState([]);

  const [demandes, setDemandes] = useState([]);

  const generateYearOptions = () => {
    const yearOptions = [];
    for (let year = 2023; year <= currentYear; year++) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return yearOptions;
  };
  const [pend, setPend] = useState();
  const [rej, setRej] = useState();
  const [conf, setConf] = useState();

  const fetchClients = () => {
    axiosClient
      .get("students/")
      .then((response) => {
        setStudents(response.data.filter((e) => e.etat === true).length);
        setPend(
          response.data.filter((e) => e.is_confirmed === "pending").length
        );
        setRej(
          response.data.filter((e) => e.is_confirmed === "rejected").length
        );
        setConf(
          response.data.filter((e) => e.is_confirmed === "confirmed").length
        );
        setListStudents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTeamMembers = () => {
    axiosClient
      .get("membres-equipe/")
      .then((response) => {
        setTeamMembers(response.data.filter((e) => e.etat === true).length);
        setTeamMembersT(
          response.data.filter(
            (e) => e.etat === true && e.equipe === "SuiviTeam"
          ).length
        );
        setTeamMembersC(
          response.data.filter(
            (e) => e.etat === true && e.equipe === "ConfirmationTeam"
          ).length
        );
        setListTeamMembers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPartenaires = () => {
    axiosClient
      .get("partenaires/")
      .then((response) => {
        setPartenaires(response.data.filter((e) => e.etat === true).length);
        setListPartenaires(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOffres = () => {
    axiosClient
      .get("equipes/")
      .then((response) => {
        setTeams(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchRequests = () => {
    axiosClient
      .get("demandeInscription/")
      .then((response) => {
        setDemandes(
          response.data.filter(
            (res) => new Date(res.date_demande).getMonth() + 1 === currentMonth
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPlaintes = () => {
    axiosClient
      .get("tickets/")
      .then((response) => {
        setPlainte(response.data);
        setUnsolvedTickets(
          response.data.filter((e) => e.etat === false).length
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setDefaultDonut("year");
    if (event.target.value === "0") {
      setDefaultDonut("years");
      setSelectedYear(currentYear);
    }
  };
  // // plaintes par jours chart data
  // const [plainteByDayChartData, setPlainteByDayChartData] = useState({
  // 	series: [],
  // 	options: {
  // 		chart: {
  // 			type: 'donut',
  // 		},
  // 		labels: [],
  // 	},
  // });

  // plaintes par mois chart data
  const [plainteByMonthChartData, setPlainteByMonthChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
    },
  });

  // plaintes par année chart data
  const [plainteByYearChartData, setPlainteByYearChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
    },
  });

  // afficher le nombre de plainte par jours
  // const fetchPlainteParJours = () => {
  // 	// Helper function to get the day name based on day index (Sunday is 0, Monday is 1, etc.)
  // 	const getDayName = (dayIndex) => {
  // 		const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Venderedi', 'Samedi'];
  // 		return daysOfWeek[dayIndex - 1];
  // 	};
  // 	axiosClient.get('tickets/plaintes_per_day/')
  // 		.then((response) => {
  // 			const data = response.data;

  // 			const count = data.map(item => item.count);
  // 			const days = data.map(item => {
  // 				const dayIndex = item.day_of_week;
  // 				const dayName = getDayName(dayIndex);
  // 				return dayName;
  // 			});
  // 			setPlainteByDayChartData({
  // 				series: count,
  // 				options: {
  // 					chart: {
  // 						type: 'donut',
  // 					},
  // 					labels: days,
  // 					legend: { show: false }
  // 				},
  // 			});

  // 		})
  // 		.catch((error) => {
  // 			console.log(error);
  // 		});
  // }

  // afficher le nombre de plainte par mois
  const fetchPlainteParMois = () => {
    // Helper function to get the month name based on month index (January is 0, February is 1, etc.)
    const getMonthName = (monthIndex) => {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Septembre",
        "October",
        "November",
        "December",
      ];
      return monthNames[monthIndex];
    };

    axiosClient
      .get(`tickets/plaintes_per_month/?year=${selectedYear}`)
      .then((response) => {
        const data = response.data;
        const count = data.map((item) => item.count);
        const month = data.map((item) => {
          const monthIndex = item.month ? new Date(item.month).getMonth() : -1;
          const monthName = getMonthName(monthIndex);
          return monthName;
        });
        console.log(count);
        setPlainteByMonthChartData({
          series: count,
          options: {
            chart: {
              type: "donut",
            },
            labels: month,
            legend: { show: false },
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // afficher le nombre de plainte par jours
  const fetchPlainteParAnnee = () => {
    axiosClient
      .get("tickets/plaintes_per_year/")
      .then((response) => {
        const data = response.data;

        const years = data.map((item) => item.year);
        const counts = data.map((item) => item.count);
        setPlainteByYearChartData({
          series: counts,
          options: {
            chart: {
              type: "donut",
            },
            labels: years,
            legend: { show: false },
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 3;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  //search method
  // const filteredData = useCallback(() => {
  //     return demandes.filter((row) => {
  //         return row.label.toLowerCase().includes(searchTerm.toLowerCase())
  // 		|| row.client.toLowerCase().includes(searchTerm.toLowerCase());
  //     });
  // }, [ searchTerm]);

  useEffect(() => {
    fetchClients();
    fetchPartenaires();
    fetchPlainteParAnnee();
    fetchPlainteParMois();
    // fetchPlainteParJours()
    fetchTeamMembers();
    fetchPlaintes();
    fetchRequests();
    fetchOffres();
  }, [selectedP, selectedYear, searchTerm, user.role, rej, conf, pend]);

  const [majors, setMajors] = useState([]);
  const [candidature, setCandidature] = useState([]);

  const [pending, setPending] = useState();
  // const [flight, setFlight] = useState();
  const [payment, setPayment] = useState();
  const [rejected, setRejected] = useState();
  const [accepted, setAccepted] = useState();

  useEffect(() => {
    axiosClient
      .get("programmes/")
      .then((res) => {
        setMajors(res.data.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });
    axiosClient
      .get("candidature/")
      .then((res) => {
        setCandidature(res.data.sort().reverse());
        setPending(res.data.filter((e) => e.status === "pending").length);
        // setFlight(res.data.filter((e) => e.status === "flight").length);
        setRejected(res.data.filter((e) => e.status === "rejected").length);
        setAccepted(res.data.filter((e) => e.status === "accepted").length);
        setPayment(res.data.filter((e) => e.status === "payment").length);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pending, payment, rejected, accepted]);

  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 900) {
        setSlidesPerView(2);
      } else if (window.innerWidth < 1200) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const labels2 = ["pending", "confirmed", "rejected"];
  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: "Students",
        data: [pend, conf, rej],
        backgroundColor: [
          "rgba(91, 255, 76, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(91, 255, 76)",
          "rgba(153, 102, 255)",
          "rgb(255, 99, 132)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const labels = ["pending", "payment", "accepted", "rejected"];
  const data = {
    labels: labels,
    datasets: [
      {
		title:'lol',
        label: "Students applications",
        data: [pending, payment,  accepted, rejected],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(91, 255, 76, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgb(54, 162, 235)",
          "rgba(153, 102, 255)",
          "rgba(91, 255, 76)",

          "rgb(255, 99, 132)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Students Applications Chart',
        font: {
          size: 16,
        },
      },
    },
  };

  return (
    <Flex flexDirection="column">
      <Flex>
        <Box w="90%" pt="12px" mb={8}>
          <Text
            fontFamily="Plus Jakarta Display"
            as="cite"
            fontSize={{ base: "18px", md: "20px", lg: "25px" }}
            color="gray"
          >
            <span
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              Home
            </span>{" "}
            / <span>Dashboard</span>
          </Text>
        </Box>
      </Flex>
      {(user?.role === "ADMIN" ||
        user?.role === "RESPO" ||
        user?.role === "SUPERADMIN") && (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of students
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {students}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <WalletIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
            {/* MiniStatistics Card */}
            <Card
              bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)"
              minH="83px"
            >
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of partners
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {partenaires}
                      </StatNumber>
                      {/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+5%
									</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of unsolved tickets
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {unsolvedTickets}
                      </StatNumber>
                      {/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+5%
									</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of team members
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {teamMembers}
                      </StatNumber>
                      {/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+5%
									</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
          </SimpleGrid>
          <SimpleGrid  columns={{ sm: 1, md: 1, xl: 1 }} my="26px" gap="18px">
            

            {/* Welcome Card */}
            <Card  p="0px" bgImage={china} bgSize="cover" bgPosition="50%">
              <CardBody h="100%">
                <Flex
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                  h="100%"
                >
                  <Flex
                    flexDirection="column"
                    h="100%"
                    p="22px"
                    minW="60%"
                    lineHeight="1.6"
                  >
                    <Text fontSize="sm" color="red.700" fontWeight="bold">
                      Welcome,
                    </Text>
                    <Text
                      fontSize="28px"
                      color="#fff"
                      fontWeight="bold"
                      mb="18px"
                    >
                      {user.first_name} {user.last_name}
                    </Text>

                    <Spacer />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
			<Flex
              gap={5}
              w={"100%"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's applications status
                    </Text>
                <Bar data={data} options={options}></Bar>
              </Card>
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's status
                    </Text>
                <Bar data={data2} options={options}></Bar>
              </Card>{" "}
            </Flex>
            <Flex
              gap={5}
              w={"100%"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
                <CardHeader p="12px 0px 28px 0px">
                  <Flex direction="column">
                    <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student requests
                    </Text>
                    <Flex align="center">
                      <Icon
                        as={IoCheckmarkDoneCircleSharp}
                        color="teal.300"
                        w={4}
                        h={4}
                        pe="3px"
                      />
                      <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        <Text fontWeight="bold" as="span">
                          {demandes.length} sent
                        </Text>{" "}
                        this month
                      </Text>
                    </Flex>
                  </Flex>
                </CardHeader>
                <TableContainer rounded={10} px={2}>
                  <Table variant="simple">
                    <Thead>
                      <Tr my=".8rem" ps="0px">
                        <Th
                          ps="0px"
                          color="gray.400"
                          fontFamily="Plus Jakarta Display"
                          borderBottomColor="gray.300"
                        >
                          Student
                        </Th>
                        <Th
                          color="gray.400"
                          fontFamily="Plus Jakarta Display"
                          borderBottomColor="gray.300"
                        >
                          Phone number
                        </Th>

                        <Th
                          color="gray.400"
                          fontFamily="Plus Jakarta Display"
                          borderBottomColor="gray.300"
                        >
                          request date
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {demandes
                        .slice(
                          currentPage * pageSize,
                          currentPage * pageSize + pageSize
                        )
                        .map((row) => (
                          <Tr>
                            <Td ps="0px" borderBottomColor="gray.300">
                              {/* {getClientName(demandes.client)} */}
                              {row.first_name} {row.last_name}
                            </Td>

                            <Td borderBottomColor="gray.300">
                              <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                pb=".5rem"
                              >
                                {row.tel}
                              </Text>
                            </Td>

                            <Td borderBottomColor="gray.300">
                              <Flex direction="column">
                                <Text
                                  fontSize="sm"
                                  fontWeight="semibold"
                                  pb=".2rem"
                                >
                                  {new Date(
                                    row.date_demande
                                  ).toLocaleDateString("en-US")}
                                </Text>
                              </Flex>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>

                {/* Pagination */}
                <Flex justify="space-between" align="center" w="100%">
                  <Box flex="1">
                    <MyPaginantion
                      data={demandes}
                      searchInput={searchTerm}
                      PAGE_SIZE={pageSize}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Box>
                </Flex>
              </Card>
              <Card bgColor={'gray.50'} shadow={'xl'} w={{ base: "100%", xl: "50%" }}>
                <Flex direction="column" p={5}>
                  <Flex justify="space-between" align="center" mb="40px">
                    <Text fontSize="lg" fontWeight="bold">
                      Tickets statistics
                    </Text>
                    {/* <Select size={'sm'} width={150} value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
									<option value="0">Tous les mois</option>
									{months.map((month) => (
										<option key={month.value} value={month.value}>
											{month.name}
										</option>
									))}
								</Select> */}
                    <Select
                      rounded={"lg"}
                      size={"sm"}
                      width={150}
                      defaultValue={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="0">years</option>
                      {generateYearOptions()}
                    </Select>
                  </Flex>
                  <Flex
                    h="100%"
                    w={"100%"}
                    direction={{ sm: "row", md: "row", xl: "row" }}
                    justify={"center"}
                    alignItems={"center"}
                  >
                    {/* Tickets par jours chart */}
                    {/* <Box width={{ base: "100%", md: "33%" }}>
									<DonutChart title={"Tickets par jours"} data={plainteByDayChartData} />
								</Box> */}
                    {/* Tickets par mois chart */}
                    {/* <Box width={{ base: "100%", md: "33%" }}>
									<DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
								</Box> */}
                    {/* Tickets par anneé chart */}
                    {/* <Box>
									<DonutChart title={"Tickets par année"} data={plainteByMonthChartData} />
								</Box> */}

                    {plainteByYearChartData.series.length === 0 ? (
                      <Box h={"100%"}>
                        <Text>nothing available yet</Text>
                      </Box>
                    ) : (
                      <>
                        {defaultDonut === "year" && (
                          <>
                            <Box w={"100%"}>
                              <DonutChart
                                title={"Tickets par année"}
                                data={plainteByYearChartData}
                              />
                            </Box>
                            <Box w={"100%"}>
                              <DonutChart
                                title={"Tickets par mois"}
                                data={plainteByMonthChartData}
                              />
                            </Box>
                          </>
                        )}
                        {defaultDonut === "years" && (
                          <>
                            <Box w={"100%"}>
                              <DonutChart
                                title={"Tickets par mois"}
                                data={plainteByYearChartData}
                              />
                            </Box>
                            <Box w={"100%"}>
                              <DonutChart
                                title={"Tickets par mois"}
                                data={plainteByMonthChartData}
                              />
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </SimpleGrid>
        </>
      )}
      {user?.role === "PARTENAIRE" && (
        <>
          <Box>
            <Card
              p="0px"
              gridArea={{ md: "1 / 1 / 2 / 3", "2xl": "auto" }}
              bgImage={china}
              bgSize="cover"
              bgPosition="50%"
            >
              <CardBody w="100%" h="100%">
                <Flex
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                  h="100%"
                >
                  <Flex
                    flexDirection="column"
                    h="100%"
                    p="22px"
                    minW="60%"
                    lineHeight="1.6"
                  >
                    <Text fontSize="sm" color="red.700" fontWeight="bold">
                      Welcome,
                    </Text>
                    <Text
                      fontSize="28px"
                      color="#fff"
                      fontWeight="bold"
                      mb="18px"
                    >
                      {user.first_name} {user.last_name}
                    </Text>

                    <Spacer />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Flex pt={10} align={"center"} justify={"center"}>
              <Text
                color={"#7e172c"}
                fontWeight="bold"
                fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
              >
                Specializations Available For Studying In China
              </Text>
            </Flex>
            <Flex justify={"center"} align={"center"} h={"100%"} w={"100%"}>
              {majors.length >= 4 && (
                <Flex mt={10} px={{ base: 10, lg: 20 }} h={"300px"} w={"100%"}>
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={slidesPerView}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {majors.map((row) => (
                      <SwiperSlide>
                        <Card shadow={"xl"} minHeight={"250px"}>
                          <CardHeader pb={0}>
                            <Heading size={{ base: "sm", lg: "md" }}>
                              {" "}
                              {row.nom}
                            </Heading>
                          </CardHeader>
                          <CardBody>
                            <Text>{row.description}</Text>
                          </CardBody>
                        </Card>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Flex>
              )}
            </Flex>
          </Box>
          <Footer />
        </>
      )}

      {user?.role === "CHEFEQUIPE" && (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing="24px">
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of students
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {students}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <WalletIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>

            {/* MiniStatistics Card */}
            {/* <Card bg='linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)'>
							<CardBody>
								<Flex flexDirection='row' align='center' justify='center' w='100%'>
									<Stat me='auto'>
										<StatLabel fontSize='sm' color='#f2c478' fontWeight='bold' pb='2px'>
											Number of unsolved tickets
										</StatLabel>
										<Flex>
											<StatNumber fontSize='lg' color='#fff'>
												{unsolvedTickets}
											</StatNumber>
										
										</Flex>
									</Stat>
									<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200'>
										<GlobeIcon h={'24px'} w={'24px'} color='#fff' />
									</IconBox>
								</Flex>
							</CardBody>
						</Card> */}
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of confirmation team members
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {teamMembersC}
                      </StatNumber>
                      {/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+5%
									</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of tracking team members
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {teamMembersT}
                      </StatNumber>
                      {/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+5%
									</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
          </SimpleGrid>
          <SimpleGrid
		  columns={{ sm: 1, md: 1, xl: 1 }}
            my="26px"
            gap="18px"
          >
            {/* Welcome Card */}
            <Card
              p="0px"
              bgImage={china}
              bgSize="cover"
              bgPosition="50%"
            >
              <CardBody w="100%" h="100%">
                <Flex
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                  h="100%"
                >
                  <Flex
                    flexDirection="column"
                    h="100%"
                    p="22px"
                    minW="60%"
                    lineHeight="1.6"
                  >
                    <Text fontSize="sm" color="red.700" fontWeight="bold">
                      Welcome,
                    </Text>
                    <Text
                      fontSize="28px"
                      color="#fff"
                      fontWeight="bold"
                      mb="18px"
                    >
                      {user.first_name} {user.last_name}
                    </Text>

                    <Spacer />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
		
			<Flex
              gap={5}
              w={"100%"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's applications status
                    </Text>
                <Bar data={data} options={options}></Bar>
              </Card>
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's status
                    </Text>
                <Bar data={data2} options={options}></Bar>
              </Card>{" "}
            </Flex>
            <Card bg={'gray.50'} p="16px" w={"100%"}>
              <CardHeader p="12px 0px 28px 0px">
                <Flex direction="column">
                  <Text fontSize="lg" fontWeight="bold" pb="8px">
                    Student requests
                  </Text>
                  <Flex align="center">
                    <Icon
                      as={IoCheckmarkDoneCircleSharp}
                      color="teal.300"
                      w={4}
                      h={4}
                      pe="3px"
                    />
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      <Text fontWeight="bold" as="span">
                        {demandes.length} sent
                      </Text>{" "}
                      this month
                    </Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <TableContainer rounded={10} px={2}>
                <Table variant="simple">
                  <Thead>
                    <Tr my=".8rem" ps="0px">
                      <Th
                        ps="0px"
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Student
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Phone number
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Email
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Etat
                      </Th>

                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        request date
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {demandes
                      .slice(
                        currentPage * pageSize,
                        currentPage * pageSize + pageSize
                      )
                      .map((row) => (
                        <Tr>
                          <Td ps="0px" borderBottomColor="gray.300">
                            {/* {getClientName(demandes.client)} */}
                            {row.first_name} {row.last_name}
                          </Td>

                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.tel}
                            </Text>
                          </Td>
                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.email}
                            </Text>
                          </Td>
                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.email}
                            </Text>
                          </Td>

                          <Td borderBottomColor="gray.300">
                            <Flex direction="column">
                              <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                pb=".2rem"
                              >
                                {new Date(row.date_demande).toLocaleDateString(
                                  "en-US"
                                )}
                              </Text>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {/* Pagination */}
              <Flex justify="space-between" align="center" w="100%">
                <Box flex="1">
                  <MyPaginantion
                    data={demandes}
                    searchInput={searchTerm}
                    PAGE_SIZE={pageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Box>
              </Flex>
            </Card>
          </SimpleGrid>
        </>
      )}
      {user?.role === "MBEQUIPE" && (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing="24px">
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of students
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {students}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <WalletIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>

            {/* MiniStatistics Card */}
            {/* <Card bg='linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)'>
					<CardBody>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='#f2c478' fontWeight='bold' pb='2px'>
									Number of unsolved tickets
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff'>
										{unsolvedTickets}
									</StatNumber>
								
								</Flex>
							</Stat>
							<IconBox as='box' h={'45px'} w={'45px'} bg='brand.200'>
								<GlobeIcon h={'24px'} w={'24px'} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card> */}
            {/* MiniStatistics Card */}
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of confirmation team members
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {teamMembersC}
                      </StatNumber>
                      {/* <StatHelpText
								alignSelf='flex-end'
								justifySelf='flex-end'
								m='0px'
								color='green.400'
								fontWeight='bold'
								ps='3px'
								fontSize='md'>
								+5%
							</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
            <Card bg="linear-gradient(126.97deg, #7e172c 28.26%, #7e172c 91.2%)">
              <CardBody>
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="sm"
                      color="#f2c478"
                      fontWeight="bold"
                      pb="2px"
                    >
                      Number of tracking team members
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize="lg" color="#fff">
                        {teamMembersT}
                      </StatNumber>
                      {/* <StatHelpText
								alignSelf='flex-end'
								justifySelf='flex-end'
								m='0px'
								color='green.400'
								fontWeight='bold'
								ps='3px'
								fontSize='md'>
								+5%
							</StatHelpText> */}
                    </Flex>
                  </Stat>
                  <IconBox as="box" h={"45px"} w={"45px"} bg="brand.200">
                    {/* <GlobeIcon h={'24px'} w={'24px'} color='#fff' /> */}
                  </IconBox>
                </Flex>
              </CardBody>
            </Card>
          </SimpleGrid>
		  <SimpleGrid
		  columns={{ sm: 1, md: 1, xl: 1 }}
            my="26px"
            gap="18px"
          >
            {/* Welcome Card */}
            <Card
              p="0px"
              bgImage={china}
              bgSize="cover"
              bgPosition="50%"
            >
              <CardBody w="100%" h="100%">
                <Flex
                  flexDirection={{ sm: "column", lg: "row" }}
                  w="100%"
                  h="100%"
                >
                  <Flex
                    flexDirection="column"
                    h="100%"
                    p="22px"
                    minW="60%"
                    lineHeight="1.6"
                  >
                    <Text fontSize="sm" color="red.700" fontWeight="bold">
                      Welcome,
                    </Text>
                    <Text
                      fontSize="28px"
                      color="#fff"
                      fontWeight="bold"
                      mb="18px"
                    >
                      {user.first_name} {user.last_name}
                    </Text>

                    <Spacer />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
		
			<Flex
              gap={5}
              w={"100%"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's applications status
                    </Text>
                <Bar data={data} options={options}></Bar>
              </Card>
              <Card bgColor={'gray.50'} shadow={'xl'} p="16px" w={{ base: "100%", xl: "50%" }}>
			  <Text fontSize="lg" fontWeight="bold" pb="8px">
                      Student's status
                    </Text>
                <Bar data={data2} options={options}></Bar>
              </Card>{" "}
            </Flex>
            <Card bg={'gray.50'} p="16px" w={"100%"}>
              <CardHeader p="12px 0px 28px 0px">
                <Flex direction="column">
                  <Text fontSize="lg" fontWeight="bold" pb="8px">
                    Student requests
                  </Text>
                  <Flex align="center">
                    <Icon
                      as={IoCheckmarkDoneCircleSharp}
                      color="teal.300"
                      w={4}
                      h={4}
                      pe="3px"
                    />
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      <Text fontWeight="bold" as="span">
                        {demandes.length} sent
                      </Text>{" "}
                      this month
                    </Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <TableContainer rounded={10} px={2}>
                <Table variant="simple">
                  <Thead>
                    <Tr my=".8rem" ps="0px">
                      <Th
                        ps="0px"
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Student
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Phone number
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Email
                      </Th>
                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        Etat
                      </Th>

                      <Th
                        color="gray.400"
                        fontFamily="Plus Jakarta Display"
                        borderBottomColor="gray.300"
                      >
                        request date
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {demandes
                      .slice(
                        currentPage * pageSize,
                        currentPage * pageSize + pageSize
                      )
                      .map((row) => (
                        <Tr>
                          <Td ps="0px" borderBottomColor="gray.300">
                            {/* {getClientName(demandes.client)} */}
                            {row.first_name} {row.last_name}
                          </Td>

                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.tel}
                            </Text>
                          </Td>
                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.email}
                            </Text>
                          </Td>
                          <Td borderBottomColor="gray.300">
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              pb=".5rem"
                            >
                              {row.email}
                            </Text>
                          </Td>

                          <Td borderBottomColor="gray.300">
                            <Flex direction="column">
                              <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                pb=".2rem"
                              >
                                {new Date(row.date_demande).toLocaleDateString(
                                  "en-US"
                                )}
                              </Text>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {/* Pagination */}
              <Flex justify="space-between" align="center" w="100%">
                <Box flex="1">
                  <MyPaginantion
                    data={demandes}
                    searchInput={searchTerm}
                    PAGE_SIZE={pageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Box>
              </Flex>
            </Card>
          </SimpleGrid>
        </>
      )}
    </Flex>
  );
}
