import logo from "../assets/images/logo.png";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { useEffect, useState } from "react";
import axiosClient, { linkImage } from "../axios-client";
import { FaBell, FaChevronCircleLeft, FaStickyNote, FaUser, FaUserFriends, FaUserGraduate, FaUserTie, FaUsers, FaUsersCog } from "react-icons/fa";
import { BsFillInfoCircleFill, BsPersonVcardFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { FaBookOpenReader, FaMapLocationDot } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import { IoNotificationsSharp } from "react-icons/io5";
import { GiNotebook } from "react-icons/gi";


import { BiCategory, BiLogOut, BiMessageSquareDetail, BiSolidUser } from "react-icons/bi";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Image,
  HStack,
  Center,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Card,
  CardBody,
  Heading,
  Link,
  Badge
} from "@chakra-ui/react";
import {
  FiHome,
  FiMenu,
  FiMoon,
  FiSun,
  FiChevronDown,
  FiGitPullRequest,
} from "react-icons/fi";
import { MdEventNote, MdLocalOffer, MdNotificationsActive, MdVideoLibrary } from "react-icons/md";
import { SettingsIcon } from "@chakra-ui/icons";
import { PiStudentFill } from "react-icons/pi";


// add links to sidebar here
// use icons from 'react-icons/fi'

export default function DefaultLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSiderBar, setIsSideBar] = useState("block");
  const [changedWidth, setChangedWidth] = useState("60");

  const handleCloseSideBar = () => {
    if (isSiderBar === "block") {
      setIsSideBar("none");
      setChangedWidth("full");
    } else {
      setIsSideBar("block");
      setChangedWidth("60");
    }
  };
  const location = useLocation();

  // Define a list of paths where padding should be removed.
  const pathsWithNoPadding = ['/new-offers'];
  const pathsWithNoPadding2 = ['/list-of-majors'];

  const paddingValue = pathsWithNoPadding.includes(location.pathname) ? '0' : pathsWithNoPadding2.includes(location.pathname) ? 0 : '4';
  return (
    <Box minH="100vh" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: isSiderBar }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        onOpen={onOpen}
        handleCloseSideBar={handleCloseSideBar}
        isSiderBar={isSiderBar}
      />
      <Box ml={{ base: 0, md: changedWidth }} p={paddingValue} >

        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {

  const { user } = useStateContext();
  const userRole = user.role;
  const [listTeamMembers, setListTeamMembers] = useState([]);
  const { upd } = useStateContext();

  useEffect(() => {
    upd()
    axiosClient.get('membres-equipe/')
      .then((response) => {

        setListTeamMembers(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const getMBEQUIPEItems = () => {
    const commonItems = [
      { name: "Dashboard", icon: FiHome, path: "/" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Leader's notes", icon: FaStickyNote, path: "/team-notes" },
      { name: "Contact us", icon: BiMessageSquareDetail, path: "/ticket" },
    ];

    if (listTeamMembers.find((e) => e.id === user.id)?.equipe === 'ConfirmationTeam') {
      return [
        ...commonItems,
        { name: "Register requests", icon: BsPersonVcardFill, path: "/demandes-inscription" },
        // Add confirmation team specific items here
      ];
    } else if (listTeamMembers.find((e) => e.id === user.id)?.equipe === 'SuiviTeam') {
      return [
        ...commonItems,
        // Add tracking team specific items here
      ];
    } else {
      // Default items for MBEQUIPE if the team is not specified or unknown
      return commonItems;
    }
  };

  const LinkItems = {
    SUPERADMIN: [
      { name: "Dashboard", icon: FiHome, path: "/" },
      { name: "Admins management", icon: FaUserTie, path: "/admins" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Partners management ", icon: FaUserFriends, path: "/partners" },
      { name: "Team members ", icon: RiTeamFill, path: "/team-members" },
      { name: "Notifications ", icon: FaBell, path: "/notifications" },
      { name: "Majors", icon: FaBookOpenReader, path: "/majors" },
      { name: "Current Offers", icon: MdLocalOffer, path: "/offers" },
      { name: "Register requests", icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: "Notes", icon: GiNotebook, path: "/notes" },
      // { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
      // { name: "Paramètres", icon: SettingsIcon, path: "/contacts" },
    ],

    ADMIN: [
      { name: "Dashboard", icon: FiHome, path: "/" },
      // { name: "Admins management", icon: FaUserTie, path: "/admins" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Partners management ", icon: FaUserFriends, path: "/partners" },
      { name: "Team members ", icon: RiTeamFill, path: "/team-members" },
      { name: "Notifications ", icon: FaBell, path: "/notifications" },
      { name: "Team Notes", icon: GiNotebook, path: "/notes" },

      // { name: "Majors", icon: FaBookOpenReader, path: "/majors" },
      // { name: "Current Offers", icon: MdLocalOffer, path: "/offers" },
      // { name: "Register requests", icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      // { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
      // { name: "Paramètres", icon: SettingsIcon, path: "/contacts" },
    ],

    RESPO: [
      { name: "Dashboard", icon: FiHome, path: "/" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Partners management ", icon: FaUserFriends, path: "/partners" },
      { name: "Team members ", icon: RiTeamFill, path: "/team-members" },
      { name: "Notifications ", icon: FaBell, path: "/notifications" },
      { name: "Team Notes", icon: GiNotebook, path: "/notes" },
      { name: "Majors", icon: FaBookOpenReader, path: "/majors" },
      { name: "Current Offers", icon: MdLocalOffer, path: "/offers" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
    ],

    PARTENAIRE: [
      { name: "Dashboard", icon: FiHome, path: "/" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Current offers", icon: MdLocalOffer, path: "/new-offers" },
      { name: "List of majors", icon: FaBookOpenReader, path: "/list-of-majors" },
      { name: "Contact us", icon: BiMessageSquareDetail, path: "/ticket" },


    ],
    CHEFEQUIPE: [
      { name: "Dashboard", icon: FiHome, path: "/" },
      { name: "Team members ", icon: RiTeamFill, path: "/team-members" },
      { name: "Students management", icon: PiStudentFill, path: "/students" },
      { name: "Register requests", icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Notes", icon: GiNotebook, path: "/notes" },
      { name: "Contact us", icon: BiMessageSquareDetail, path: "/ticket" },

    ],
    MBEQUIPE:
      [
        { name: "Dashboard", icon: FiHome, path: "/" },
        { name: "Students management", icon: PiStudentFill, path: "/students" },
        { name: "Leader's notes", icon: FaStickyNote, path: "/team-notes" },
        { name: "Contact us", icon: BiMessageSquareDetail, path: "/ticket" },

      ],
  };
  const items = LinkItems[userRole] || [];

  return (
    <Box
      transition="3s ease"
      color="white"
      bgGradient={useColorModeValue("linear(to-b, #450000, red.700)", "linear(to-b, #450000, red.700)")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      overflowY={"scroll"}
      h="full"
      pb="20px"
      css={{
        /* Hide scrollbar */
        "&::-webkit-scrollbar": {
          display: "none",
        },
        /* Optional: Firefox scrollbar */
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}

      {...rest}

    >
      <Flex h="20" alignItems="center" mx="8" mt={2} mb={5} justifyContent="center">
        <Image backgroundColor={'yellow.600'} borderRadius={"50px"} src={logo} width="80px" mt="10px"></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {items.map((link) => (
        <NavItem
          items={items}
          key={link.name}
          icon={link.icon}
          onClose={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, items, onClose, ...rest }) => {
  const navigate = useNavigate();
  const { user, setUser } = useStateContext();

  const location = useLocation();
  const pathLink = items.find((e) => e.name === children);

  const [countTickets, setCountTickets] = useState()

  // current user data
  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
    axiosClient.get("tickets/")
      .then((res) => {
        setCountTickets(res.data.filter(e => e.etat === false).length);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [countTickets]);
  const handleClick = () => {
    navigate(pathLink.path);
    onClose();
  };
  return (
    <Box
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        m="2"
        p="4"
        mx="4"
        bg={pathLink.path === location.pathname ? "red.800" : ""}
        color={pathLink.path === location.pathname ? "#f2c478" : ""}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#f2c478",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children === "Tickets" ? (
          <>
            <Box flex={"1"}>
              <Text fontSize="md">{children}</Text>
            </Box>
            {countTickets > 0 && (
              <Badge
                rounded="full"
                bg="#df2e38"
                style={{ padding: "2px 7px 2px 6px" }}
                color="white"
                ml="2"
              >
                {countTickets}
              </Badge>
            )}
          </>
        ) :
          <Text fontSize="sm" fontWeight={"500"}>{children}</Text>
        }
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, handleCloseSideBar, isSiderBar, ...rest }) => {
  const { user, setToken, setUser, setRefresh } = useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();

  //user picture
  const [avatarUrl, setAvatarUrl] = useState("");

  const [tickets, setTickets] = useState([])

  const [notifs, setNotifs] = useState([])
  const [count, setCount] = useState()
  const [countNotes, setCountNotes] = useState()


  useEffect(() => {
    axiosClient.get(`tickets/`)
            .then((res) => {
                setTickets(res.data.filter((e)=>e.user===user.id && e.response.length>0 && e.response_read===false))
                
            })
            .catch((err)=> console.error(err))
    if (
      user.role === "ADMIN" ||
      user.role === "SUPERADMIN" ||
      user.role === "RESPO"
    ) {
      axiosClient
        .get("/notificationsAdmin/")
        .then((res) => {
          setNotifs(res.data.filter((e) => e.cible === "TeamLead" && e.date_creation > user.date_creation).sort().reverse());
          const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && notification.cible === "TeamLead" && notification.date_creation > user.date_creation);
          const unreadCount = unreadNotifications.length;
          setCount(unreadCount);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // axiosClient
      //   .get("/notificationsAdmin/")
      //   .then((response) => {
      //     // setNotifs(
      //     //   response.data
      //     //     .filter((e) => e.cible === "All")
      //     //     .sort()
      //     //     .reverse()
      //     // );
      //     // const unreadNotifications = response.data.filter(notification => (!notification.is_read.includes(user.id) && notification.cible === "All"));
      //     // const unreadCount = unreadNotifications.length;
      //     // setCount(unreadCount);



      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });

      if (user.role === "CHEFEQUIPE") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "TeamLead" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );
            const unreadNotifications = res.data.filter(notification => (!notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamLead") && notification.date_creation > user.date_creation));
            const unreadCount = unreadNotifications.length;
            setCount(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (user.role === "MBEQUIPE") {
        axiosClient
          .get("/notes/")
          .then((res) => {

            const unreadNotes = res.data.filter(notes => !notes.is_read.includes(user.id));
            const unreadCount = unreadNotes.length;
            setCountNotes(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
        axiosClient.get(`/membres-equipe/${user?.id}/`).then((rep) => {
          if (rep.data.equipe === "ConfirmationTeam") {
            axiosClient
              .get("/notificationsAdmin/")
              .then((res) => {
                setNotifs(
                  res.data
                    .filter(
                      (e) =>
                        (e.cible === "TeamMembers" ||
                          e.cible === "All" ||
                          e.confirmationTeam) && e.date_creation > user.date_creation
                    )
                    .sort()
                    .reverse()
                );
                const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamMembers" || notification.confirmationTeam) && notification.date_creation > user.date_creation);
                const unreadCount = unreadNotifications.length;
                setCount(unreadCount);
              })
              .catch((error) => {
                console.error(error);
              });

          } else {
            axiosClient
              .get("/notificationsAdmin/")
              .then((res) => {
                setNotifs(
                  res.data
                    .filter(
                      (e) =>
                        (e.cible === "TeamMembers" ||
                          e.cible === "All") && e.date_creation > user.date_creation
                    )
                    .sort()
                    .reverse()
                );
                const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamMembers") && notification.date_creation > user.date_creation);
                const unreadCount = unreadNotifications.length;
                setCount(unreadCount);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
        if (user.equipe === "ConfirmationTeam") {
        }
      }
      if (user.role === "PARTENAIRE") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "Partners" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );
            const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "Partners") && notification.date_creation > user.date_creation);
            const unreadCount = unreadNotifications.length;
            setCount(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (user.role === "") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "Students" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );

          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [user, count, countNotes]);

  function update() {
    if (
      user.role === "ADMIN" ||
      user.role === "SUPERADMIN" ||
      user.role === "RESPO"
    ) {
      axiosClient
        .get("/notificationsAdmin/")
        .then((res) => {
          setNotifs(res.data.filter((e) => e.cible === "TeamLead" && e.date_creation > user.date_creation).sort().reverse());
          const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && notification.cible === "TeamLead" && notification.date_creation > user.date_creation);
          const unreadCount = unreadNotifications.length;
          setCount(unreadCount);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // axiosClient
      //   .get("/notificationsAdmin/")
      //   .then((response) => {
      //     // setNotifs(
      //     //   response.data
      //     //     .filter((e) => e.cible === "All")
      //     //     .sort()
      //     //     .reverse()
      //     // );
      //     // const unreadNotifications = response.data.filter(notification => (!notification.is_read.includes(user.id) && notification.cible === "All"));
      //     // const unreadCount = unreadNotifications.length;
      //     // setCount(unreadCount);



      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });

      if (user.role === "CHEFEQUIPE") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "TeamLead" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );
            const unreadNotifications = res.data.filter(notification => (!notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamLead") && notification.date_creation > user.date_creation));
            const unreadCount = unreadNotifications.length;
            setCount(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (user.role === "MBEQUIPE") {
        axiosClient
          .get("/notes/")
          .then((res) => {

            const unreadNotes = res.data.filter(notes => !notes.is_read.includes(user.id));
            const unreadCount = unreadNotes.length;
            setCountNotes(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
        axiosClient.get(`/membres-equipe/${user?.id}/`).then((rep) => {
          if (rep.data.equipe === "ConfirmationTeam") {
            axiosClient
              .get("/notificationsAdmin/")
              .then((res) => {
                setNotifs(
                  res.data
                    .filter(
                      (e) =>
                        (e.cible === "TeamMembers" ||
                          e.cible === "All" ||
                          e.confirmationTeam) && e.date_creation > user.date_creation
                    )
                    .sort()
                    .reverse()
                );
                const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamMembers" || notification.confirmationTeam) && notification.date_creation > user.date_creation);
                const unreadCount = unreadNotifications.length;
                setCount(unreadCount);
              })
              .catch((error) => {
                console.error(error);
              });

          } else {
            axiosClient
              .get("/notificationsAdmin/")
              .then((res) => {
                setNotifs(
                  res.data
                    .filter(
                      (e) =>
                        (e.cible === "TeamMembers" ||
                          e.cible === "All") && e.date_creation > user.date_creation
                    )
                    .sort()
                    .reverse()
                );
                const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "TeamMembers") && notification.date_creation > user.date_creation);
                const unreadCount = unreadNotifications.length;
                setCount(unreadCount);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
        if (user.equipe === "ConfirmationTeam") {
        }
      }
      if (user.role === "PARTENAIRE") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "Partners" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );
            const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(user.id) && (notification.cible === "All" || notification.cible === "Partners") && notification.date_creation > user.date_creation);
            const unreadCount = unreadNotifications.length;
            setCount(unreadCount);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (user.role === "") {
        axiosClient
          .get("/notificationsAdmin/")
          .then((res) => {
            setNotifs(
              res.data
                .filter((e) => (e.cible === "Students" || e.cible === "All") && e.date_creation > user.date_creation)
                .sort()
                .reverse()
            );

          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
  // Logout Function
  const navigate = useNavigate();

  const onLogout = (ev) => {
    ev.preventDefault();
    setUser({});
    setToken(null);
    setRefresh(null);
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("mutumAdmin");
    localStorage.removeItem("tsaroAdmin");
    localStorage.removeItem("refreshTimeAdmin");
    localStorage.removeItem("role");

  };
  const IsRead = (id) => {
    axiosClient.get(`/notificationsAdmin/${id}/`)
      .then((response) => {
        const notification = response.data;

        if (!notification.is_read.includes(user.id)) {
          notification.is_read.push(user.id);

          axiosClient.put(`/notificationsAdmin/${id}/`, {
            ...notification,
            is_read: notification.is_read,
          })
            .then((response) => {
              console.log('ok');
              update()
            })
            .catch((error) => {
              console.error(error)
            });
        }
      })
      .catch((error) => {
        console.error(error)
      });

  };


  //stocking the user image
  // useEffect(() => {
  //   if (user.id) {
  //     axiosClient
  //       .get(`/responsables/${user.id}/image`)
  //       .then((response) => {
  //         setAvatarUrl(response.request.responseURL);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       })
  //   }
  // }, [user.id]);

  const flexContent = (
    <>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        style={{
          color: "white",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
        icon={<FiMenu />}
      />
      {isSiderBar === "block" ? (
        <Text
          mt={2}
          ml={2}
          fontWeight={"bold"}
          color={"white"}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
        >
          NIHAO Administration
        </Text>
      ) : (
        <Text
          color={"white"}
          ml={10}
          fontWeight={"bold"}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
        >
          NIHAO Administration
        </Text>
      )}
      <HStack zIndex={999} spacing={{ base: "0", md: "6" }}>


        <Flex alignItems={"center"} gap={6}>
          {user.role === "MBEQUIPE" &&
            <div style={{ position: 'relative' }}>
              <IconButton
                p={0}
                m={0}
                size="sm"
                variant=""
                color={'#f2c478'}
                onClick={() => navigate('/team-notes')}
                icon={<FaStickyNote fontSize={"20px"} />}
              />
              {countNotes > 0 && <Badge
                position={'absolute'}
                height={3}
                width={3}
                top={0}
                right={0}
                borderRadius={"50"}
                backgroundColor='gray.50'
              ></Badge>}

            </div>
          }

          <Menu _hover='none' zIndex={"1"}>
            <MenuButton
              background={"transparent"}
              _hover='none'
              position="relative"

            >
              <Badge

                borderRadius={"25"}
                opacity={.9}
                backgroundColor={!count || count === 0 ? 'transparent' : 'gray.50'}
                color={'yellow.700'}
                px={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  size="sm"
                  variant=""
                  color={"#f2c478"}
                  icon={<IoNotificationsSharp fontSize={"22px"} />}
                />
                {
                  !count
                    ? null
                    : <Text pr={2} fontSize={13}>{count < 100 ? count : '+99'}</Text>
                }

              </Badge>


            </MenuButton>


            <MenuList
              w={{ base: "200px", md: "450px" }}
              maxH="450px"
              overflow="scroll"
              overflowX={'hidden'}
            >
              {notifs.slice(0, 7).map((row) => (
                <MenuItem
                  onClick={() =>
                    navigate(`/details-notification`, {
                      state: {
                        idNotif: row.id,
                      },
                    })
                  }
                  _hover={{ backgroundColor: "transparent" }}
                  minH="48px"
                >
                  <Card backgroundColor={row.is_read.includes(user.id) ? 'white' : 'gray.100'} onClick={() => IsRead(row.id)} _hover={{ backgroundColor: "gray.50" }} w={"100%"}>
                    <CardBody>
                      <Heading size="sm">{row.title}</Heading>
                      <Flex w={"100%"} justify={"end"}>
                        <Text fontSize={"sm"} color={'gray.500'}>{new Date(row.date_creation).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>
                      </Flex>

                    </CardBody>
                  </Card>
                </MenuItem>
              ))}
              {notifs.length === 0 ? (
                <Text color={"black"} px={2} p={5}>
                  There is no notifications
                </Text>
              ) : (
                <Flex w="100%" justify="center">
                  <Link text-center color={"black"} href="/notifications">
                    show all ...
                  </Link>
                </Flex>
              )}
            </MenuList>
          </Menu>
          <Menu >
            <MenuButton
              py={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={linkImage + user.image} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color="white">
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text fontSize="xs" color="#f2c478">
                    {user.role === "ADMIN"
                      ? "Director"
                      : user.role === "SUPERADMIN"
                        ? "super-admin"
                        : user.role === "RESPO"
                          ? "Manager"
                          : user.role === "CHEFEQUIPE"
                            ? "team leader"
                            : user.role === "MBEQUIPE"
                              ? "team member"
                              : user.role === "PARTENAIRE"
                                ? "partner"
                                : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color="white">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              // minW='200px'
              zIndex={9999}
              borderColor="red.800"
              alignItems={"center"}
              bg={useColorModeValue("red.800", "black")}
              color="white"
              boxShadow={"3px 3px 50px #1a202c"}
            >
              <Center mt={3} mb={3}>
                <Avatar size={"xl"} src={linkImage + user.image} />
              </Center>

              <MenuItem
                fontSize={'sm'}
                icon={<BiSolidUser size={15} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={() => navigate("/profile")}
              >
                {" "}
                Profile
              </MenuItem>
              <MenuItem
                fontSize={'sm'}
                icon={<FaUserEdit size={15} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={() => navigate("/edit-profile")}
              >
                {" "}
                My settings
              </MenuItem>
              {
                ( user.role === "PARTENAIRE" || user.role === "MBEQUIPE" || user.role === "CHEFEQUIPE" ) &&
                <MenuItem
                fontSize={'sm'}
                icon={<BiMessageSquareDetail size={15} />}
                _hover='red.900'
                bg="red.800"
                onClick={() => navigate("/my-tickets")}
              >
                {" "}
                My tickets  
                {tickets.length > 0 && <Badge fontSize={"11px"} ml={2}>new answer </Badge>}
                
              </MenuItem>
              }
             
              <MenuDivider />
              <MenuItem
                icon={<BiLogOut size={20} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={onLogout}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  );
  const b = useColorModeValue("linear(to-r, #450000, red.700)", "linear(to-r, #450000, red.700)");
  const border = useColorModeValue("black", "gray.700");
  return (
    <Box>
      {isSiderBar === "block" ? (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={{ base: 0, md: 60, lg: 60 }}
          bgGradient={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      ) : (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={0}
          bgGradient={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      )}
      {isSiderBar === "block" ? (
        <IconButton
          icon={<FaChevronCircleLeft />}
          onClick={handleCloseSideBar}
          display={{ base: "none", md: "block" }}
          variant=""
          style={{
            position: "absolute",
            left: "220px",
            top: "25px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      ) : (
        <IconButton
          onClick={handleCloseSideBar}
          icon={<FiMenu />}
          variant="outline"
          aria-label="open menu"
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      )}
    </Box>
  );
};
