import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, background, useColorModeValue, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../../assets/images/loginS.png";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import axiosClient from '../../../../axios-client';

function NewOffersPartner() {
  const { student } = useStateContext();
  const navigate = useNavigate()
  const toast = useToast()

  const inputRef = useRef < HTMLInputElement | null > (null)
  const [selectedMajor, setSelectedMajor] = useState('')
  const [selectedDegree, setSelectedDegree] = useState('')
  const [selectedScholarship, setSelectedScholarship] = useState('')
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [message, setMessage] = useState('')
  const [offers, setOffers] = useState([])


  useEffect(() => {
    axiosClient.get('offres/')
      .then((res) => {
        setOffers(res.data.sort().reverse())
      })
      .catch((error) => {
        console.error(error);
      });

  }, [])

  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setSlidesPerView(1);
      }
      else if (window.innerWidth < 700) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 1250) {
        setSlidesPerView(2);
      }
       else {
        setSlidesPerView(3);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box >
      
      <Flex
        w={"100%"}
        rounded={"0px 0px 50px 50px"}
        bgRepeat={"no-repeat"}
        backgroundSize={'cover'}
        backgroundImage={china} minH={"250px"}
        backgroundPosition={"center"}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'right' }}
        justify={"center"}
        
      >
        {/* <Flex w="100%" h="250px" position='absolute' backgroundColor="black" opacity="0.4" ></Flex> */}
        <Flex zIndex="1" align={"center"} justify={"center"} flexDirection={"column"} >
        <Text color={"white"} fontWeight='bold'  fontSize={{base:'xl',md:"2xl", lg:"3xl"}} backgroundColor={"#00000066"}>Check our current offers
        </Text>
        </Flex>

      </Flex>
    
      < Flex justify={'center'} align={"center"} px={10} my={10} h={"100%"} w={"100%"} >
          <Swiper
            
            style={{ paddingBottom:50, width: "100%", paddingLeft: 15, paddingRight: 15 }}
            spaceBetween={30}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {offers.map((row) => (

              <SwiperSlide style={{ height: "100%", display: "flex", alignItems: "center" }} >
                <Card shadow={"xl"}  minWidth={"250px"} minHeight={"250px"}>
                  <CardHeader pb={0}>
                    <Heading size={{base:'sm' , md:"md"}}> {row.nom}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{row.description}</Text>
                    <Flex justify={"end"}>
                      <Text >{row.prix} DH</Text>
                    </Flex>
                  </CardBody>


                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
    </Box >

  )
}

export default NewOffersPartner