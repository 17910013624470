import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../../components/MyPagination';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
// import Subs from '../../assets/images/Subscribe.png'
import animationData from '../../../assets/lotties/program.json'
import Lottie from 'react-lottie';
import { CiEdit } from "react-icons/ci";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function GestProgrammes() {
    const navigate = useNavigate()
    const { user, setUser } = useStateContext();
    const [programmes, setProgrammes] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [programId, setProgramId] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        axiosClient.get('programmes/')
            .then((res) => {
                setProgrammes(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])


    function update() {
        axiosClient.get('programmes/')
            .then((res) => {
                setProgrammes(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }

    //search method
    const filteredData = useCallback(() => {
        return programmes.filter((row) => {
            return row.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.description.toLowerCase().includes(searchTerm.toLowerCase())


        });
    }, [programmes, searchTerm]);

    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/programmes/${id}/`)
            .then((response) => {
                setProgrammes((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "the major is successfuly removed",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    const AddProgram = () => {
        // if (!nom || nom.trim() === "") {
        //     setMessage("Veuillez remplir le t")
        //     return;
        // }
        const formData = new FormData()
        formData.append("nom", nom)
        formData.append("description", description)

        if (programmes.length > 0) {
            const check = programmes.find(obj => {
                return obj.nom.toLowerCase() === nom.toLowerCase().trim();
            })
            if (check) {
                return setMessage(`This major already exists !`)
            }
        }
        axiosClient.post('/programmes/', formData)
            .then((response) => {
                setProgrammes([response.data, ...programmes]);

                setMessage("")

                toast({
                    description: `The major is successfuly added`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
    }

    const UpdateProgram = (id) => {

        const formData = new FormData();
        if (nom) formData.append("nom", nom)
        if (description) formData.append("description", description)
        const newList = programmes.filter((e) => e.id !== id)
        if (newList.length > 0) {
            const check = newList.find(obj => {
                return obj.nom.toLowerCase() === nom.toLowerCase().trim();
            })
            if (check) {
                return setMessage(`Major name already exists !`)
            }
        }

        axiosClient.patch(`/programmes/${id}/`, formData).then(() => {

            update()

            toast({
                description: `The major is successfuly updated`,
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            // setTeam('')
            setMessage("")
            setProgramData("")
        })

        onClose()
    }


    const borderColor = useColorModeValue("gray.300", "gray.600");

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" mt={2} mb={5}>
                <div style={{display:"flex" , flexDirection:'row' , alignItems :"center" , gap: 5 }}>

                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span>Majors</span> </Text>
                    <Lottie style={{ height: "60px", width: "100px", objectFit: 'contain' }}
                            options={defaultOptions}
                        />
                    </div>
                    <Button onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                        leftIcon={<PiPlusBold color='#f2c478' />} _hover={'none'} color={"#f2c478"} bg="#7e172c">
                        New Major
                    </Button>
                </Box>

            </Flex>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>

                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr " }} justify={"center"} gap='22px'>

                    {/* <Flex display={{ base: "none", lg: "flex" }} align={'center'}>
                        <Lottie style={{ height: "80px", width: "150px", objectFit: 'contain' }}
                            options={defaultOptions}
                        />
                    </Flex> */}

                    <Card
                        boxShadow={"xl"}
                        bg={useColorModeValue("gray.50", "gray.700")}
                        display={'flex'}
                    >

                        <CardBody px='2px' >
                            <Flex
                                align={'center'}
                                justify={'center'}
                            >
                                
                                <Stack
                                    w={'full'}
                                    maxW='full'

                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    rounded={'lg'}
                                    p={6}
                                >
                                    {/* Search input */}
                                    <Flex
                                        justifyContent="end"
                                        alignItems="center"
                                        direction={{ base: 'column', lg: 'row' }}
                                    >
                                       
                                        
                                        <InputGroup size={"sm"} w={{ base: '100%', lg: "30%" }}>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<SearchIcon color='gray.300' />}
                                            />
                                            <Input
                                                borderRadius={"5"}
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                mb={4}
                                                sx={{
                                                    marginLeft: 'auto',
                                                }}
                                            />  </InputGroup>

                                    </Flex>
                                    <TableContainer >
                                        <Table size={"sm"} variant='simple' >
                                            <Thead>
                                                <Tr>
                                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Name</Th>
                                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Description</Th>
                                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Creation date</Th>
                                                    <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Options</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filteredData().slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                                    <Tr borderColor={borderColor} p={0} key={row.id}>
                                                        <Td borderColor={borderColor}>{row.nom}</Td>
                                                        <Td borderColor={borderColor}>{row.description}</Td>
                                                        <Td borderColor={borderColor}>{new Date(row.date_creation).toLocaleDateString('en-US')}</Td>

                                                        <Td borderColor={borderColor}>
                                                            <IconButton
                                                                size="sm"
                                                                onClick={() => {
                                                                    setChangeModal(false)
                                                                    setProgramData(row)
                                                                    setProgramId(row.id)
                                                                    onOpen()

                                                                }}

                                                                colorScheme='whatsapp'
                                                                variant='outline'
                                                                icon={<EditIcon />}>
                                                                Save
                                                            </IconButton>

                                                           <IconButton
                                                                ml={5}
                                                                size="sm"
                                                                onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }}
                                                                colorScheme='red'
                                                                icon={<DeleteIcon />}>
                                                                Delete
                                                            </IconButton>
                                                            

                                                        </Td>
                                                    </Tr>
                                                ))}
                                                {filteredData().length === 0 && (
                                                    <Tr>
                                                        <Td borderColor={borderColor} colSpan={10}>No matching rows were found.</Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </Table>

                                    </TableContainer>
                                    {/* Pagination */}
                                    <Flex justify="space-between" align="center" w="100%">
                                        <Box flex="1">
                                            <MyPaginantion
                                                data={filteredData()}
                                                searchInput={searchTerm}
                                                PAGE_SIZE={pageSize}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </Box>


                                    </Flex>
                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Major
                        </AlertDialogHeader>

                        <AlertDialogBody>
                        Are you sure? You won't be able to undo this action later.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Close
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Add a new major</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody >
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Name</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setNom(e.target.value)} placeholder='Major name ...' />
                                    </FormControl>

                                </Flex>
                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Description</FormLabel>
                                        <Input maxLength={250} name="description" onChange={e => setDescription(e.target.value)} placeholder='Major description ...' />
                                    </FormControl>

                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!nom || !description)
                                ?
                                <Button colorScheme='blue' title="Entrer le nom de catégorie" isDisabled
                                >
                                    Add Major
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AddProgram}
                                >
                                    Add Major
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')

                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Major edit</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody >
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <Flex w="100%" flexDirection={'column'}>
                                        <FormControl isRequired mb={3}>
                                            <FormLabel>Name</FormLabel>
                                            <Input defaultValue={programData.nom} maxLength={50} name="type" onChange={e => setNom(e.target.value)} placeholder='Major name ...' />
                                        </FormControl>

                                    </Flex>
                                    <Flex w="100%" flexDirection={'column'}>
                                        <FormControl isRequired mb={3}>
                                            <FormLabel>Description</FormLabel>
                                            <Input defaultValue={programData.description} maxLength={250} name="description" onChange={e => setDescription(e.target.value)} placeholder='Major description ...' />
                                        </FormControl>

                                    </Flex>

                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateProgram(programId)}
                            >
                                Save
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>

            }

        </Box>
    )
}

export default GestProgrammes