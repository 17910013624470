import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../../context/ContextProvider';
import axiosClient from '../../../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../../../components/MyPagination';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../../../assets/images/notes.png'
import animationData from '../../../../assets/lotties/notes.json'
import Lottie from 'react-lottie';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function GestNotes() {
    const navigate = useNavigate()
    const { user, setUser } = useStateContext();
    const [notesData, setNotesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [notifData, setNotifData] = useState();
    const [notifId, setNotifId] = useState('');

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [selectedC, setSelectedC] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        axiosClient.get('notes/')
            .then((res) => {
                setNotesData(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])

    function update() {
        axiosClient.get('notes/')
            .then((res) => {
                setNotesData(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //search method
    const filteredData = useCallback(() => {
        return notesData.filter((row) => {
            return row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.content.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()) 
        });
    }, [notesData, searchTerm]);

    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/notes/${id}/`)
            .then((response) => {
                setNotesData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "The note is successfuly deleted",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    const AjoutNote = () => {
        if (!title || title.trim() === "") {
            setMessage("Please enter the title")
            return;
        }
        if (!content || content.trim() === "") {
            setMessage("Please write the content")
            return;
        }
        const formData = new FormData()
        formData.append("title", title)
        formData.append("content", content)


        axiosClient.post('/notes/', formData)
            .then((response) => {
                setNotesData([response.data, ...notesData]);
                setTitle('')
                setContent('')
                setMessage("")

                toast({
                    description: `The note is successfuly added`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
    }

    const UpdateNotif = (id) => {

        const formData = new FormData();
        if (title) formData.append("title", title)
        if (content) formData.append("content", content)

        axiosClient.patch(`/notes/${id}/`, formData).then(() => {

            update()

            toast({
                description: `The note is successfuly edited`,
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            setTitle('')
            setContent('')
            setMessage("")
        })

        onClose()
    }
    const myColor = useColorModeValue("gray.50", "gray.700");

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span>Notes</span> </Text>
                    
                    <Button onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }} leftIcon={<AiOutlineUserAdd color='#f2c478' />} _hover={'none'} color={"#f2c478"} bg="#7e172c">
                    Add a note
                </Button>
                </Box>

            </Flex>
            {notesData.length === 0 ? (
                <Flex mb={'10'} mt={20} flexDirection={'column'} align={'center'} justify={'center'}>
                    <Image
                        height={150} width={150}
                        rounded={'md'}
                        alt={'empty'}
                        src={
                            empty
                        }
                        objectFit={'empty'}
                    />
                    <Text mt={10} color={"#aeaeae"}>There is no notes to display</Text>
                </Flex>) :
                <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>

                    <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 1fr " }} justify={"center"} gap='22px'>

                        <Card

                            bg={"none"}
                            border={'none'}
                            shadow={'none'}
                            display={'flex'}
                        >

                            <CardBody px='2px' >

                                <Flex
                                    px={5}
                                    justifyContent="end"
                                    alignItems="center"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                  
                                    <InputGroup size={"sm"} w={{ base: '100%', md: "40%" }}>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<SearchIcon color='gray.300' />}
                                        />
                                        <Input
                                            borderRadius={"5"}
                                            placeholder="Search"
                                            value={searchTerm}
                                            bg={myColor}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            mb={4}
                                            sx={{
                                                marginLeft: 'auto',
                                            }}
                                        />  </InputGroup>

                                </Flex>
                                <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    align={{ base: 'stretch', md: 'center' }}
                                    justify={{ base: 'flex-start', md: 'space-between' }}
                                    p={5}
                                >

                                    <Flex
                                        direction="column"
                                        width={{ base: "100%", lg: "100%" }}
                                        justify={{ base: 'flex-start', md: 'space-between' }}

                                    >

                                        {filteredData().slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (


                                            <Stack
                                                bg={myColor}
                                                mb={4}
                                                rounded={'lg'}
                                                p={5}
                                                boxShadow={'xl'}
                                                alignItems={'start'}
                                                justify="space-between"
                                            // as={"Button"}

                                            >
                                                <Flex direction="row"
                                                    justify="space-between"
                                                    ml={'auto'}
                                                    width="full">
                                                    <Stack direction={'row'} align={'center'}>
                                                        <Text fontSize={'lg'} fontWeight="semibold">{row.title}</Text>
                                                        
                                                    </Stack>
                                                    <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString('en-US', { hour: '2-digit', minute: '2-digit' })}</Text>

                                                </Flex>

                                                <Flex
                                                    direction="row"
                                                    width="full"
                                                    ml={'auto'}
                                                    justify="space-between">
                                                    <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                                        {row.content}

                                                    </Text>

                                                    <Flex align={'center'} mt={1} direction={'column'}>

                                                        <Flex direction={"row"} >
                                                            {/* <EditIcon
                                                        size="sm"
                                                        cursor='pointer'
                                                        onClick={() => {
                                                            setChangeModal(false)
                                                            setNotifData(row)
                                                            setNotifId(row.id)
                                                            onOpen()

                                                        }}

                                                        color='green'
                                                        
                                                    /> */}

                                                           <DeleteIcon
                                                                ml={5}
                                                                cursor='pointer'
                                                                size="sm"
                                                                onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }}
                                                                color='#e53e3e'
                                                            // icon={<DeleteIcon />}>
                                                            />
                                                            

                                                        </Flex>


                                                    </Flex>
                                                </Flex>
                                            </Stack>
                                        ))}
                                        {(filteredData().length === 0 ) && (
                                            <Flex alignItems={'center'}>
                                                <Text >No matching rows were found.</Text>
                                            </Flex>
                                    
                                )}
                                        <Flex justify="space-between" align="center" w="100%">
                                            <Box flex="1">
                                                <MyPaginantion
                                                    data={filteredData()}
                                                    searchInput={searchTerm}
                                                    PAGE_SIZE={pageSize}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            </Box>


                                        </Flex>
                                    </Flex>


                                </Flex>
                            </CardBody>
                        </Card>
                        <Flex display={{ base: "none", lg: "flex" }} align={'center' } >
                            <Lottie   height={"300px"} speed={0.5} options={defaultOptions} />
                        </Flex>
                     
                    </Grid>
                </Flex>}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Note
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You won't be able to undo this action later.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                            Close
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setTitle('')
                    setContent('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Add a new note</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Title</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setTitle(e.target.value)} placeholder='Title of the note...' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Note</FormLabel>
                                        <Textarea height={"100px"} resize={"none"} type='text' maxLength={250} name="message" onChange={e => setContent(e.target.value)} placeholder='note...' />
                                    </FormControl>
                                   


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!title || !content)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    Add note
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutNote}
                                >
                                    Add note
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setTitle('')
                    setContent('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Edit the note</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Titre</FormLabel>
                                        <Input maxLength={50} defaultValue={notifData.title} name="title" onChange={e => setTitle(e.target.value)} placeholder='Title of the note...' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea height={"100px"} defaultValue={notifData.content} resize={"none"} type='text' maxLength={250} name="message" onChange={e => setContent(e.target.value)} placeholder='note...' />
                                    </FormControl>
                                   


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateNotif(notifId)}
                            >
                                Edit note
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>
    )
}

export default GestNotes