import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../../components/MyPagination';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
// import Subs from '../../assets/images/Subscribe.png'
import animationData from '../../../assets/lotties/teams.json'
import Lottie from 'react-lottie';
import { CiEdit } from "react-icons/ci";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function GestTeams() {
    const navigate = useNavigate()
    const { user, setUser } = useStateContext();
    const [team, setTeam] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [teamData, setTeamData] = useState();
    const [teamId, setTeamId] = useState();
    const [nom, setNom] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullName, setFullName] = useState()
    const [selectedLead, setSelectedLead] = useState()
    const [assign, setAssign] = useState(false)


    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const onCloseModal = () => {
        setIsModalOpen(false);
        setSelectedLead()
        setAssign(false)
        setTeamId()
    };

    //get the data
    useEffect(() => {
        axiosClient.get('equipes/')
            .then((res) => {
                setTeam(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])
    useEffect(() => {
        axiosClient.get('membres-equipe/')
            .then((res) => {
                setTeamMembers(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])

    function update() {
        axiosClient.get('equipes/')
            .then((res) => {
                setTeam(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });
        axiosClient.get('membres-equipe/')
            .then((res) => {
                setTeamMembers(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //search method
    const filteredData = useCallback(() => {
        return team.filter((row) => {
            return row.nom.toLowerCase().includes(searchTerm.toLowerCase())
        });
    }, [team, searchTerm]);

    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/equipes/${id}/`)
            .then((response) => {
                setTeam((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "le type d'abonnement est supprimé avec succès",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    const AddTeam = () => {
        if (!nom || nom.trim() === "") {
            setMessage("Veuillez remplir le type")
            return;
        }
        const formData = new FormData()
        formData.append("nom", nom)


        if (team.length > 0) {
            const check = team.find(obj => {
                return obj.nom.toLowerCase() === nom.toLowerCase().trim();
            })
            if (check) {
                return setMessage(`Cet equipe existe déja !`)
            }
        }
        axiosClient.post('/equipes/', formData)
            .then((response) => {
                setTeam([response.data, ...team]);

                setMessage("")

                toast({
                    description: `Le type d'abonnement est ajoutée avec succès`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
    }

    const UpdateTeam = (id) => {

        const formData = new FormData();
        if (nom) formData.append("nom", nom)

        // const newList = team.filter((e) => e.id !== id)
        if (team.length > 0) {
            const check = team.find(obj => {
                return obj.nom.toLowerCase() === nom.toLowerCase().trim();
            })
            if (check) {
                return setMessage(`Team already exists !`)
            }
        }

        axiosClient.patch(`/equipes/${id}/`, formData).then(() => {

            update()

            toast({
                description: `Le type d'abonnement est modifié avec succès`,
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            // setTeam('')
            setMessage("")
            setTeamData("")
        })

        onClose()
    }
    const handleLeadSubmit = () => {
        console.log(selectedLead)

        changeLead(teamId, selectedLead);
        setIsModalOpen(false);
    };
    const changeLead = (id) => {
        const updatedData = team.map(row => {
            if (row.id === id) {
                console.log("hi")
                const formData = new FormData();
                formData.append("chef_equipe", selectedLead);

                axiosClient.patch(`/equipes/${id}/`, formData)
                    .then((response) => {
                        // const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setTeam(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    chef_equipe: selectedLead,
                                    // date_modification: (updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "le role est changé avec succès",
                            status: 'success',
                            duration: 2000,
                            variant: 'left-accent',
                            isClosable: true,
                            position: 'bottom-right'
                        })
                        update()
                    })

                    .catch((error) => console.error(error));



            }
        });
    }

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span>Teams</span> </Text>
                    <Button onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                        leftIcon={<PiPlusBold color='#f2c478' />} _hover={'none'} color={"#f2c478"} bg="#7e172c">
                        New team
                    </Button>
                </Box>

            </Flex>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>

                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 3fr " }} justify={"center"} gap='22px'>

                    <Flex display={{ base: "none", lg: "flex" }} align={'center'}>
                        <Lottie style={{ height: "250px", width: "full", objectFit: 'contain' }}
                            options={defaultOptions}
                        />
                    </Flex>

                    <Card

                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                    >

                        <CardBody px='2px' >
                            <Flex
                                align={'center'}
                                justify={'center'}
                            >
                                <Stack
                                    w={'full'}
                                    maxW='full'
                                    bg={useColorModeValue('white', 'gray.700')}
                                    rounded={'lg'}
                                    p={6}
                                >
                                    {/* Search input */}
                                    <Flex
                                        justifyContent="end"
                                        alignItems="center"
                                        direction={{ base: 'column', lg: 'row' }}
                                    >

                                        <InputGroup size={"sm"} w={{ base: '100%', lg: "30%" }}>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<SearchIcon color='gray.300' />}
                                            />
                                            <Input
                                                borderRadius={"5"}
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                mb={4}
                                                sx={{
                                                    marginLeft: 'auto',
                                                }}
                                            />  </InputGroup>

                                    </Flex>
                                    <TableContainer>
                                        <Table size={"sm"} variant='simple' >
                                            <Thead>
                                                <Tr>
                                                    <Th fontFamily='Plus Jakarta Display'>Name</Th>
                                                    <Th fontFamily='Plus Jakarta Display'>Team leader</Th>
                                                    <Th fontFamily='Plus Jakarta Display'>Creation date</Th>
                                                    <Th fontFamily='Plus Jakarta Display'>Options</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filteredData().slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                                    <Tr p={0} key={row.id}>
                                                        <Td>{row.nom}</Td>
                                                        <Td>{row.chef_equipe ?
                                                            <Flex flexDirection={'row'} align={'center'}>
                                                                {teamMembers.find(e => e.id === row.chef_equipe)?.first_name} {teamMembers.find(e => e.id === row.chef_equipe)?.last_name}
                                                                <CiEdit cursor={'pointer'} style={{ marginLeft: 5, fontSize: "16px", color: "green" }}
                                                                    onClick={() => {

                                                                        setFullName(`${row.first_name} ${row.last_name}`)
                                                                        setSelectedLead(row.chef_equipe)
                                                                        setTeamId(row.id);
                                                                        setIsModalOpen(true);


                                                                    }} />
                                                            </Flex>
                                                            :
                                                            <Link color='gray' onClick={() => {

                                                                setTeamId(row.id);
                                                                setIsModalOpen(true);
                                                                setSelectedLead(row.chef_equipe)
                                                                setAssign(true)

                                                            }}>assign a team leader </Link>}
                                                        </Td>

                                                        <Td>{new Date(row.date_creation).toLocaleDateString('en-US')}</Td>

                                                        <Td>
                                                            <IconButton
                                                                size="sm"
                                                                onClick={() => {
                                                                    setChangeModal(false)
                                                                    setTeamData(row)
                                                                    setTeamId(row.id)
                                                                    onOpen()

                                                                }}

                                                                colorScheme='whatsapp'
                                                                variant='outline'
                                                                icon={<EditIcon />}>
                                                                Save
                                                            </IconButton>

                                                            <IconButton
                                                                ml={5}
                                                                size="sm"
                                                                onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }}
                                                                colorScheme='red'
                                                                icon={<DeleteIcon />}>
                                                                Delete
                                                            </IconButton>
                                                            

                                                        </Td>
                                                    </Tr>
                                                ))}
                                                {filteredData().length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={10}>No matching rows were found.</Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </Table>

                                    </TableContainer>
                                    {/* Pagination */}
                                    <Flex justify="space-between" align="center" w="100%">
                                        <Box flex="1">
                                            <MyPaginantion
                                                data={filteredData()}
                                                searchInput={searchTerm}
                                                PAGE_SIZE={pageSize}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </Box>


                                    </Flex>
                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Team
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You won't be able to undo this action later.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Close
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>

                    {assign ?
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Assign a team leader
                            </AlertDialogHeader>

                            <AlertDialogBody >

                                <Stack>

                                    <Select
                                        size={"sm"}
                                        value={selectedLead} onChange={e => {
                                            setSelectedLead(e.target.value)
                                        }
                                        }>
                                        {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                     <option value="MASTER">Super-administrateur</option>
                 } */}
                                        <option value="">select a team leader</option>
                                        {teamMembers.filter(e => e.equipe === teamId).map((member, index) => (

                                            <option key={index} value={member.id}>{member.first_name} {member.last_name}</option>
                                        ))}

                                    </Select>
                                </Stack>


                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button size={"sm"} ref={cancelRef} onClick={() => {
                                    setMessage('')
                                    onCloseModal()
                                }}>
                                    Close
                                </Button>
                                <Button size={"sm"} onClick={handleLeadSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                    Save
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                        :
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Edit team leader
                            </AlertDialogHeader>

                            <AlertDialogBody >

                                <Stack>
                                    <Text mb={2} fontSize={"14px"}>
                                        Are you sure you want to change the team leaser ?
                                    </Text>
                                    <Select
                                        size={"sm"}
                                        defaultValue={selectedLead} onChange={e => setSelectedLead(e.target.value)}>
                                        {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                        <option value="MASTER">Super-administrateur</option>
                    } */}
                                                            <option value="">select a team leader</option>

                                        {teamMembers.filter(e => e.equipe === teamId).map((member, index) => (

                                            <option key={index} value={member.id}>{member.first_name} {member.last_name}</option>
                                        ))}


                                    </Select>
                                </Stack>


                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button size={"sm"} ref={cancelRef} onClick={() => {
                                    setMessage('')
                                    onCloseModal()
                                }}>
                                    Close
                                </Button>
                                <Button size={"sm"} onClick={handleLeadSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                    Save
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>}

                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Add a new team</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody >
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Name</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setNom(e.target.value)} placeholder='Team name ...' />
                                    </FormControl>

                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!nom)
                                ?
                                <Button colorScheme='blue' title="Entrer le nom de catégorie" isDisabled
                                >
                                    Add team
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AddTeam}
                                >
                                    Add team
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')

                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Team edit</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody >
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Name</FormLabel>
                                        <Input defaultValue={teamData.nom} maxLength={50} name="nom" onChange={e => setNom(e.target.value)} placeholder='Team name ...' />
                                    </FormControl>




                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateTeam(teamId)}
                            >
                                Save
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>

            }

        </Box>
    )
}

export default GestTeams