import { Box, Button, Container, Flex, Grid, Heading, IconButton, Image, Link, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import logo from '../../assets/images/logo.png'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'

function Footer() {
    return (
        <Box bg='white' borderTop={"1px"} borderTopColor={'#f2c478'}>
            <Container maxW={1300}>
                <SimpleGrid textAlign={{ lg: 'start', base: 'center' }} spacing='30px' py='40px' templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}>
                    <Flex w={"100%"} h={"100%"} align={"center"} justify={"center"}>
                        <Image w={"150px"} src={logo} alt='NIHAO'></Image>

                    </Flex>
                    {/* <Flex align={"start"} >
                        <Box >
                            <Heading size={"md"} color='#7e172c'>A wide range is available in </Heading>
                            <Heading size={"md"} color='#7e172c'>Chinese universities.</Heading>
                        </Box>
                    </Flex> */}
                    <Box>
                        <Heading size={"md"} color='#7e172c' pt={2}>A wide range is available in
                        </Heading>
                        <Heading size={"md"} color='#7e172c' pt={2}>Chinese universities
                        </Heading>
                        <Grid display={'flex'} justifyContent={{base:"center", lg:"start"}} fontSize={"20px"} pt='20px' color='gray'>
                            <Flex gap={2}>
                            <IconButton _hover={{color:"#f2c478" , backgroundColor:"#7e172c"}} backgroundColor={'transparent'} color={"#f2c478"} icon={<FaInstagram fontSize={"24px"}/>}></IconButton>
                            <IconButton _hover={{color:"#f2c478" , backgroundColor:"#7e172c"}} backgroundColor={'transparent'} color={"#f2c478"} icon={<FaTiktok fontSize={"24px"}/>}></IconButton>
                            <IconButton _hover={{color:"#f2c478" , backgroundColor:"#7e172c"}} backgroundColor={'transparent'} color={"#f2c478"} icon={<FaYoutube fontSize={"24px"}/>}></IconButton>
                            </Flex>
                            

                        </Grid>
                    </Box>
                    <Box>
                        <Heading color='#7e172c' >Contact Us</Heading>
                        <Grid fontWeight={"bold"} fontSize={"18px"} pt='20px' color='gray'>
                            <Link color={"#7e172c"} mb={2} href='/' _hover={{
                                color: "#f2c478"
                            }}>Contact@Nihaoservices.Ma</Link>
                            <Link color={"#7e172c"} mb={2} href='/' _hover={{
                                color: "#f2c478"
                            }} >Support@Nihaoservices.Ma
                            </Link>
                            <Link color={"#7e172c"} href='/' _hover={{
                                color: "#f2c478"
                            }}>+212 703-630282 </Link>
                        </Grid>
                    </Box>
                </SimpleGrid>

            </Container>
            <Flex w={"100%"} align={"center"} justify={"center"} height={"40px"} backgroundColor={"#7e172c"} >
                <Text fontWeight={"bold"} color={"white"}>2023 • NIHAO - All rights reserved © </Text>
            </Flex>
        </Box>
    )
}

export default Footer