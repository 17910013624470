import logo from "../assets/images/logo.png";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { useEffect, useState } from "react";
import axiosClient, { linkImage } from "../axios-client";
import {
  FaChevronCircleLeft,
  FaUser,
  FaUserFriends,
  FaUserGraduate,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import { BsFillInfoCircleFill, BsPersonVcardFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";

import {
  BiCategory,
  BiLogOut,
  BiMessageSquareDetail,
  BiSolidUser,
} from "react-icons/bi";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Image,
  HStack,
  Center,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Button,
  Card,
  CardBody,
  Heading,
  Link,
  Badge,
} from "@chakra-ui/react";
import {
  FiHome,
  FiMenu,
  FiMoon,
  FiSun,
  FiChevronDown,
  FiGitPullRequest,
} from "react-icons/fi";
import {
  MdEventNote,
  MdLocalOffer,
  MdNotificationsActive,
  MdVideoLibrary,
} from "react-icons/md";
import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  VscFileSubmodule,
  VscGitPullRequestGoToChanges,
} from "react-icons/vsc";
import { IoChatboxEllipses, IoNotificationsSharp } from "react-icons/io5";

// add links to sidebar here
// use icons from 'react-icons/fi'

export default function StudentLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSiderBar, setIsSideBar] = useState("block");
  const [changedWidth, setChangedWidth] = useState("");

  const handleCloseSideBar = () => {
    if (isSiderBar === "block") {
      setIsSideBar("none");
      setChangedWidth("full");
    } else {
      setIsSideBar("block");
      // setChangedWidth("60");
    }
  };
  const location = useLocation();
  const pathsWithNoPadding = ["/ajouter-evenement"];
  const pathsWithNoPadding2 = ["/modifier-evenement"];

  const paddingValue = pathsWithNoPadding.includes(location.pathname)
    ? "0"
    : pathsWithNoPadding2.includes(location.pathname)
    ? 0
    : "4";

  return (
    <Box minH="100vh" bg="white">
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        onOpen={onOpen}
        handleCloseSideBar={handleCloseSideBar}
        isSiderBar={isSiderBar}
      />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { student } = useStateContext();
  // const userRole = user.role;
  // current user data

  const LinkItems = [
    { name: "Home", icon: FiHome, path: "/" },
    {
      name: "Submit an application",
      icon: VscGitPullRequestGoToChanges,
      path: "/program-application",
    },
    { name: "Current offers", icon: MdLocalOffer, path: "/current-offers" },
    { name: "Contact us", icon: IoChatboxEllipses, path: "/contact-us" },
  ];

  const items = LinkItems;

  return (
    <Box
      transition="3s ease"
      color="white"
      bgGradient={useColorModeValue(
        "linear(to-b, #450000, red.600)",
        "linear(to-t, red.600, #450000)"
      )}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      overflowY={"scroll"}
      h="full"
      pb="20px"
      css={{
        /* Hide scrollbar */
        "&::-webkit-scrollbar": {
          display: "none",
        },
        /* Optional: Firefox scrollbar */
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        mt={2}
        mb={5}
        justifyContent="center"
      >
        <Image
          backgroundColor={"yellow.600"}
          borderRadius={"50px"}
          src={logo}
          width="80px"
          mt="10px"
        ></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {items.map((link) => (
        <NavItem
          items={items}
          key={link.name}
          icon={link.icon}
          onClose={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, items, onClose, ...rest }) => {
  const navigate = useNavigate();
  const { student, setStudent } = useStateContext();

  const location = useLocation();
  const pathLink = items.find((e) => e.name === children);
  // current user data
  useEffect(() => {
    axiosClient.get("auth/student/").then(({ data }) => {
      setStudent(data);
    });
  }, []);
  const handleClick = () => {
    navigate(pathLink.path);
    onClose();
  };
  return (
    <Box
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        m="2"
        p="4"
        mx="4"
        bg={pathLink.path === location.pathname ? "red.800" : ""}
        color={pathLink.path === location.pathname ? "#f2c478" : ""}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#f2c478",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        <Text fontSize="sm">{children}</Text>
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, handleCloseSideBar, isSiderBar, ...rest }) => {
  const { student, setTokenStudent, setStudent, setRefreshStudent } =
    useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const [count, setCount] = useState()

  //user picture
  const [avatarUrl, setAvatarUrl] = useState("");
  const [tickets, setTickets] = useState([])

  const [notifs, setNotifs] = useState([]);

  useEffect(() => {
    axiosClient.get(`tickets/`)
            .then((res) => {
                setTickets(res.data.filter((e)=>e.user===student.id && e.response.length>0 && e.response_read===false))
                
            })
            .catch((err)=> console.error(err))
    axiosClient
      .get("notificationsAdmin/")
      .then((res) => {
        setNotifs(
          res.data
            .filter((e) => e.cible === "Students" || e.cible === "All" || e.student===student.id)
            .sort()
            .reverse()
        );
        const unreadNotifications = res.data.filter(notification => !notification.is_read.includes(student.id) &&  (notification.cible === "Students" || notification.cible === "All" || notification.student===student.id));
        const unreadCount = unreadNotifications.length;
        setCount(unreadCount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [student,count]);
  // Logout Function
  const navigate = useNavigate();
  function update(){
    axiosClient.get(`tickets/`)
            .then((res) => {
                setTickets(res.data.filter((e)=>e.user===student.id && e.response.length>0 && e.response_read===false))
                
            })
            .catch((err)=> console.error(err))
    axiosClient
      .get("notificationsAdmin/")
      .then((res) => {
        setNotifs(
          res.data
            .filter((e) => e.cible === "Students" || e.cible === "All" || e.student===student.id)
            .sort()
            .reverse()
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const onLogout = (ev) => {
    ev.preventDefault();
    setStudent({});
    setTokenStudent(null);
    setRefreshStudent(null);
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("mutumStudent");
    localStorage.removeItem("tsaroStudent");
    localStorage.removeItem("refreshTimeStudent");
  };
  const IsRead = (id) => {
    axiosClient.get(`/notificationsAdmin/${id}/`)
      .then((response) => {
        const notification = response.data;

        if (!notification.is_read.includes(student.id)) {
          notification.is_read.push(student.id);

          axiosClient.put(`/notificationsAdmin/${id}/`, {
            ...notification,
            is_read: notification.is_read,
          })
            .then((response) => {
              console.log('ok');
              update()
            })
            .catch((error) => {
              console.error(error)
            });
        }
      })
      .catch((error) => {
        console.error(error)
      });

  };
  //stocking the user image
  // useEffect(() => {
  //   if (user.id) {
  //     axiosClient
  //       .get(`/responsables/${user.id}/image`)
  //       .then((response) => {
  //         setAvatarUrl(response.request.responseURL);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       })
  //   }
  // }, [user.id]);

  const flexContent = (
    <>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        style={{
          color: "white",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
        icon={<FiMenu />}
      />
      {/* {isSiderBar === "block" ? (
        <Text
          mt={2}
          ml={2}
          fontWeight={"bold"}
          color={"white"}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
        >
          NIHAO Student
        </Text>
      ) : ( */}
      <Text
        color={"white"}
        ml={{base : 2 , md:10}}
        fontWeight={"bold"}
        fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
      >
        NIHAO Student
      </Text>
      {/* )} */}
      <HStack zIndex={999} px={{base:5,lmd:10}} spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"} gap={5}>
          <Menu zIndex={"1"}>
          <MenuButton
              background={"transparent"}
              _hover='none'
              position="relative"

            >
              <Badge

                borderRadius={"25"}
                opacity={.9}
                backgroundColor='gray.50'
                color={'yellow.700'}
                px={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  size="sm"
                  variant=""
                  color={"#f2c478"}
                  icon={<IoNotificationsSharp fontSize={"22px"} />}
                />
                <Text pr={2} fontSize={13}>{count < 100 ? count : '+99'}</Text>
              </Badge>


            </MenuButton>

            <MenuList
            
              w={{ base: "200px", md: "450px" }}
              maxH="450px"
              overflow="scroll"
              overflowX={'hidden'}
            >
              {notifs
              .slice(0,7)
              .map((row) => (
                <MenuItem
                onClick={() =>
                  navigate(`/notification-details`, {
                    state: {
                      idNotif: row.id,
                    },
                  })
                }
                  _hover={{ backgroundColor: "transparent" }}
                  minH="48px"
                >
                  <Card backgroundColor={row.is_read.includes(student.id) ? 'white' : 'gray.100'} onClick={() => IsRead(row.id)} _hover={{ backgroundColor: "gray.50" }} w={"100%"}>
                    <CardBody>
                    <Heading size="sm">{row.title}</Heading>
                      <Flex w={"100%"} justify={"end"}>
                        <Text fontSize={"sm"} color={'gray.500'}>{new Date(row.date_creation).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>
                      </Flex>
                    </CardBody>
                  </Card>
                </MenuItem>
              ))}
              {notifs.length === 0 && (
                <Text color={"black"} px={2} p={5}>
                  There is no notifications
                </Text>
              )}

              <Flex w="100%" justify="center">
                <Link text-center color={"black"} href="/all-notifications">
                  show all ...
                </Link>
              </Flex>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              py={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={linkImage + student.image} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color="white">
                    {student.first_name} {student.last_name}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color="white">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              // minW='200px'
              zIndex={9999}
              borderColor="red.800"
              alignItems={"center"}
              bg={useColorModeValue("red.800", "black")}
              color="white"
              boxShadow={"3px 3px 50px #1a202c"}
            >
              <Center mt={3} mb={3}>
                <Avatar size={"xl"} src={linkImage + student.image} />
              </Center>

              <MenuItem
                fontSize={"sm"}
                icon={<BiSolidUser size={15} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={() => navigate("/my-profile")}
              >
                {" "}
                Profile
              </MenuItem>
              <MenuItem
                fontSize={"sm"}
                icon={<FaUserEdit size={15} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={() => navigate("/edit-my-profile")}
              >
                {" "}
                My Settings
              </MenuItem>
              <MenuItem
                fontSize={"sm"}
                icon={<VscFileSubmodule size={15} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={() => navigate("/my-applications")}
              >
                {" "}
                My Application
              </MenuItem>
              <MenuItem
                fontSize={'sm'}
                icon={<BiMessageSquareDetail size={15} />}
                _hover='red.900'
                bg="red.800"
                onClick={() => navigate("/my-tickets-list")}
              >
                {" "}
                My tickets
                {tickets.length > 0 && <Badge fontSize={"11px"} ml={2}>new answer </Badge>}

              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<BiLogOut size={20} />}
                _hover={{ bg: "red.900", color: "white" }}
                bg={useColorModeValue("red.800", "black")}
                onClick={onLogout}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  );
  const flexContent2 = (
    <>
      <Flex
        px={10}
        w={"100%"}
        justifyContent={"end"}
        alignItems={"center"}
        color="black"
      >
        <Button
          onClick={() => navigate("/program-application")}
          mx={5}
          _hover={"none"}
          color={"#f2c478"}
          bg="#7e172c"
        >
          Submit an application
        </Button>
        <Button
          mx={5}
          _hover={{
            backgroundColor: "#7e172c",
            color: "#f2c478",
          }}
          color={"#7e172c"}
          bg="none"
          onClick={() => navigate("/contact-us")}
        >
          Contact us
        </Button>
        <Button
          mx={5}
          _hover={{
            backgroundColor: "#7e172c",
            color: "#f2c478",
          }}
          onClick={() => navigate("/current-offers")}
          color={"#7e172c"}
          bg="none"
        >
          Current offers
        </Button>
        <Button
          onClick={() => navigate("/dashboard-student")}
          mx={5}
          _hover={{
            backgroundColor: "#7e172c",
            color: "#f2c478",
          }}
          color={"#7e172c"}
          bg="none"
        >
          Home
        </Button>
      </Flex>
    </>
  );
  const b = useColorModeValue(
    "linear(to-r, #450000, red.700)",
    "linear(to-l, red.700, #450000)"
  );
  const border = useColorModeValue("black", "gray.700");
  return (
    <Box>
      {/* {isSiderBar === "block" ? (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={{ base: 0, md: 60, lg: 60 }}
          bgGradient={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      ) : ( */}
      <Flex
        ml={{ base: 0, md: "full" }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        color="white"
        marginLeft={0}
        bgGradient={b}
        borderBottomWidth="1px"
        borderBottomColor={border}
        justifyContent={{ base: "space-between", md: "space-between" }}
        {...rest}
      >
        {flexContent}
      </Flex>
      <Flex
        ml={{ base: 0, md: "full" }}
        px={{ base: 4, md: 4 }}
        height="70px"
        alignItems="center"
        color="white"
        marginLeft={0}
        display={{ base: "none", md: "flex" }}
        backgroundColor={"white"}
        borderBottomWidth="1px"
        borderBottomColor={"yellow.400"}
        justifyContent={{ base: "space-between", md: "space-between" }}
        {...rest}
      >
        {flexContent2}
      </Flex>
      {/* )} */}
      {/* {isSiderBar === "block" ? (
        <IconButton
          icon={<FaChevronCircleLeft />}
          onClick={handleCloseSideBar}
          display={{ base: "none", md: "block" }}
          variant=""
          style={{
            position: "absolute",
            left: "230px",
            top: "25px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      ) : ( */}
      {/* <IconButton
          onClick={handleCloseSideBar}
          icon={<FiMenu />}
          variant="outline"
          aria-label="open menu"
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        /> */}
      {/* )} */}
    </Box>
  );
};
