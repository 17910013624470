import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider';
import { Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, background, useColorModeValue, useToast } from '@chakra-ui/react';
import { GrAdd } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { FiFile } from 'react-icons/fi'
import china from "../../../assets/images/china1.jpg";
import { IoCloudUploadOutline } from 'react-icons/io5';
import upload from "../../../assets/images/upload.png";
import zip from "../../../assets/images/zip.png";
import axiosClient from '../../../axios-client';
import { IoIosCloseCircle } from "react-icons/io";
import ReactCardSlider from "react-card-slider-component";
import { Swiper, SwiperSlide } from 'swiper/react';
import resume from "../../../assets/files/resume.doc";
import PhExam from "../../../assets/files/Physical-Examination-Form.pdf";

// Import Swiper styles
import 'swiper/css';
import Footer from '../../../components/footer/Footer';
function Application() {
  const { student } = useStateContext();
  const navigate = useNavigate()
  const toast = useToast()

  const inputRef = useRef < HTMLInputElement | null > (null)
  const [selectedMajor, setSelectedMajor] = useState('')
  const [selectedDegree, setSelectedDegree] = useState('')
  const [selectedScholarship, setSelectedScholarship] = useState('')
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [message, setMessage] = useState('')
  const [majors, setMajors] = useState([])

  const slides = [
    {
      title: "This is a title",
      description: "This is a description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a second title",
      description: "This is a second description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a third title",
      description: "This is a third description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a fourth title",
      description: "This is a fourth description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a fifth title",
      description: "This is a fifth description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a sixth title",
      description: "This is a sixth description"
      // clickEvent: sliderClick
    },
    {
      title: "This is a seventh title",
      description: "This is a seventh description"
      // clickEvent: sliderClick
    }
  ];
  useEffect(() => {
    axiosClient.get('programmes/')
      .then((res) => {
        setMajors(res.data)
      })
      .catch((error) => {
        console.error(error);
      });

  }, [])
  const handleClick = () => inputRef.current?.click()
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const extensions = ['.zip', '.rar', '.7zip']
    if (file) {
      if (file.name?.length > 70) {
        toast({
          description: "The name of the zip is too long",
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-center'
        })
        event.target.value = null
      }
      else if (!extensions.includes(file.name?.substring(file.name?.length, file.name?.lastIndexOf(".")))) {
        toast({
          description: "the type of the file should be a zip or rar",
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-center'
        })
        event.target.value = null
      }
      else {
        setSelectedFiles(file);
      }
    }


    console.log('FILLLLLLLLLLLEEEEEEEe : ', file);
    // console.log('FILLLLLLLLLLLEEEEEEEe : ',file[0]);


  };

  const Submit = ev => {
    ev.preventDefault()
    const formData = new FormData()
    formData.append("student", student.id)
    formData.append("files", selectedFiles)
    formData.append("major", selectedMajor)
    formData.append("degree", selectedDegree)
    formData.append("scholarship", selectedScholarship)
    formData.append("status", "pending")
    // const fileName = selectedFiles.name;

    // const fileExtension = fileName.split('.').pop(); // Get the file extension

    // if (fileExtension != 'zip' || fileExtension !== 'rar') { 
    //   setMessage("the file should be .zip or .rar ?")
    // } 

    axiosClient.post('/candidature/', formData)
      .then((response) => {
        console.log(response)
        toast({
          description: "candidature added successfully",
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'bottom-right'
        })

      })
      .catch((err) => {
        const response = err.response;
        setMessage(response.data)
      })
    setSelectedDegree("");
    setSelectedMajor("");
    setSelectedScholarship("");
    setSelectedFiles(null)
  }
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      }
      else if (window.innerWidth < 900) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 1200) {
        setSlidesPerView(3);
      }
      else {
        setSlidesPerView(4);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box >
      {/* <Box w={"100%"}  mb={2}>
        <Text as="cite" fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span onClick={() => navigate('/students')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Students</span> / <span> Add a student</span></Text>
      </Box> */}
      <Flex
        w={"100%"}
        bgRepeat={"no-repeat"}
        backgroundSize={'cover'}
        backgroundImage={china} minH={"500px"}
        backgroundPosition={"center"}
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'right' }}
        justify={"right"}
        p={{ base: 2, md: 10 }}

      >
        {/* <Flex  w="100%" h="500px" position='absolute' backgroundColor="black" opacity="0.4" ></Flex> */}

        <Stack
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'lg'}
          p={6}
          my={5}
          w={{ base: '100%', md: '100%', lg: '50%', sm: '100%' }}
        >
          {message &&
            <Alert fontSize={"sm"} padding={2} status='error' rounded="md">
              <AlertIcon />
              {message}
            </Alert>
          }
          <Box rounded={'lg'} w="100%" maxW="full"
            align={'center'}
            justify={'center'}
          >
            <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
            >
              <Flex flexDirection={{ base: "column", md: "row" }} gap={{ base: 2, md: 5 }} >
                <Box w={{ base: "100%", md: "40%" }}>
                  <FormControl id="firstName" isRequired >
                    <FormLabel ml={0.5}>Major</FormLabel>
                    <Select placeholder="Select a major" onChange={(e) => setSelectedMajor(e.target.value)} >
                      {majors.map((row) => (
                        <option value={row.nom}>{row.nom}</option>
                      ))}
                      {/* <option value="CS">computer science</option>
                      <option value="Echo">echonomy</option> */}
                    </Select>

                  </FormControl>
                </Box>
                <Flex gap={{ base: 2, md: 5 }} w={{ base: "100%", md: "60%" }}>
                  <Box w="50%">
                    <FormControl id="lastName" isRequired >
                      <FormLabel ml={0.5}>Degree </FormLabel>
                      <Select placeholder="Select a degree" onChange={(e) => setSelectedDegree(e.target.value)}>

                        <option value="Bachelor">Bachelor</option>
                        <option value="Licence">Licence</option>
                        <option value="Master">Master</option>
                        <option value="Language">Language</option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box w="50%">
                    <FormControl id="lastName" isRequired >
                      <FormLabel ml={0.5}>Scholarship </FormLabel>
                      <Select placeholder="Select a scholarship" onChange={(e) => setSelectedScholarship(e.target.value)} >

                        <option value="25">25%</option>
                        <option value="50">50%</option>
                        <option value="100">100%</option>
                      </Select>


                    </FormControl>
                  </Box>
                </Flex>



              </Flex>

              <Stack p={{ base: 2, md: 5 }} spacing={{ base: 10, lg: 20 }}>
                <Flex position={'relative'} _hover={{ background: "#fafafa" }} cursor={"pointer"} align={'start'} justify={"start"} flexDirection={'column'} w={"100%"} minH={"150px"} border={"1px dashed lightgray"} borderRadius={"lg"} >
                  {/* <Input style={{
                    position:'absolute',
                    height:'100%',
                    width:'100%',
                    cursor:'pointer',
                    zIndex:10,
                    opacity:1
                  }} onChange={handleFileChange} type='file'  accept=".zip,.rar,.7zip"></Input> */}
                  <Input style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    zIndex: 10,
                    opacity: 0,
                    height: '100%'
                  }} onChange={handleFileChange} type='file' accept=".zip,.rar,.7zip"></Input>
                  {!selectedFiles ?

                    <>

                      <Flex w='100%' h='20%'>
                        <Box style={{ paddingLeft: 5, paddingTop: 5, color: '#8d8686' }}>
                          <Text >(* The name of the zip is too long and the type of the file should be a zip or rar)</Text>
                        </Box>
                      </Flex>


                      <Flex
                        flexDirection={"column"}
                        align={"center"}
                        justify={"center"}
                        position="relative"
                        w='100%'
                        h='150px'
                      >
                        <img src={upload} style={{ width: 70, height: 70, color: "lightgray", objectFit: "contain" }} />

                      </Flex>

                    </>


                    :
                    <Flex
                      flexDirection={"column"}
                      align={"center"}
                      justify={"center"}
                      position="relative"
                      w='100%'
                      h='150px'
                    >
                      {/* <IoIosCloseCircle
                      style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
                      onClick={handleCloseButtonClick}
                    /> */}
                      <img src={zip} style={{ width: 70, height: 70, color: "lightgray", objectFit: "contain", opacity: '.7' }} />
                      <Text>{selectedFiles.name}</Text>
                    </Flex>

                  }

                </Flex>
              </Stack>
              <Flex px={2} >
                <Text>Please fill these files and include them with your files : &nbsp;
                  <a style={{color:'#0000c6'}} href={resume} download>
                    resume.doc
                  </a>
                  &nbsp;and&nbsp;
                  <a  style={{color:'#0000c6'}} href={PhExam} download>
                    Physical-Examination-Form.pdf
                  </a>

                </Text>
              </Flex>

              <Stack direction={['column', 'row']} spacing={10} pt={2} justifyContent="end">
                {!selectedDegree || !selectedMajor || !selectedScholarship || !selectedFiles ?
                  <Button
                    type="submit"

                    colorScheme="gray"
                    isDisabled
                    leftIcon={<GrAdd />}
                    onClick={Submit}
                  >

                    Submit
                  </Button> :
                  <Button
                    type="submit"

                    colorScheme="gray"

                    leftIcon={<GrAdd />}
                    onClick={Submit}
                  >

                    Submit
                  </Button>}


              </Stack>
            </Stack>
          </Box>
        </Stack>

      </Flex>
      <Flex p={10} align={"center"} justify={"center"}>
        <Text color={"#7e172c"} fontSize={{ base: 'xl', md: "2xl", lg: "3xl" }}>Specializations Available For Studying In China
        </Text>

      </Flex>
      {majors.length >= 4 &&
        < Flex px={{ base: 10, md: 20 }} h={"300px"} py={5} w={"100%"} >
          <Swiper
            spaceBetween={50}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {majors.map((row) => (

              <SwiperSlide >
                <Card shadow={"xl"} style={{ height: "200px" }}>
                  <CardHeader pb={0}>
                    <Heading size={{ base: 'sm', md: "md" }}> {row.nom}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{row.description}</Text>
                  </CardBody>

                </Card>
              </SwiperSlide>
            ))}


          </Swiper>
        </Flex>}
      <Footer />
    </Box >

  )
}

export default Application