import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { GrAdd } from 'react-icons/gr';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { createRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
// import animationData from '../../assets/lot/suivi.json';
import React from 'react';
import { FcLock } from 'react-icons/fc';
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../context/ContextProvider";
import { AddIcon, SearchIcon } from '@chakra-ui/icons'
import img3 from '../../../assets/images/3.jpg';
import empty from '../../../assets/images/empty.png';



import {
    Box,
    Flex,
    useColorModeValue,
    Stack,
    Heading,
    Image,
    Text,
    Button,
    Badge,
    Spacer,
    InputGroup,
    InputLeftElement,
    Input,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    IconButton,
    FormControl,
    Textarea,
    Toast,
    useToast,
    FormLabel,
    Select,
    Alert,
    AlertIcon

} from '@chakra-ui/react';
import MyPaginantion from '../../../components/MyPagination';
import { PiPlusBold } from 'react-icons/pi';
import { IoMailOutline, IoMailUnreadOutline } from 'react-icons/io5';
const PAGE_SIZE = 4;
// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//     }
// };
function MyTickets() {
    // current user
    const { user } = useStateContext();

    //data
    const [plainteCategory, setPlainteCategory] = useState('')
    const [plainteSujet, setPlainteSujet] = useState('')
    const [plainteContent, setPlainteContent] = useState('')


    const [changeModal, setChangeModal] = useState(true);
    const [reponseApprenant, setReponseApprenant] = useState('')
    const [oldReponseApprenant, setOldReponseApprenant] = useState('')
    const [resUserByWho, setResUserByWho] = useState('')
    const [userTime, setUserTime] = useState('')
    const [resByWho, setResByWho] = useState('')
    const [time, setTime] = useState('')

    const [message, setmessage] = useState('')
    const handleCategoryChange = (ev) => {
        setPlainteCategory(ev.target.value)
    }

    const [selectedUrgence, setSelectedUrgence] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [plaintes, setPlaintes] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');

    const myColor = useColorModeValue("gray.50", "gray.700");
    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [id, setId] = useState(null);
    const [reponse, setReponse] = useState('');


    const filteredData = useCallback(() => {
        return plaintes.filter((row) => {
            const booleanField = row.etat ? "settled" : "In progress";
            return row.sujet.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
                booleanField.toLowerCase().includes(searchTerm.toLowerCase())

        });
    }, [plaintes, searchTerm, user.id]);

    useEffect(() => {
        axiosClient.get(`tickets/`)
            .then((res) => {
                setPlaintes(res.data.filter((e)=>e.user===user.id))
                let filteredData = res.data.filter((e)=>e.user===user.id);
                if (selectedEtat !== null) {
                    filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                }
                if (selectedCategory !== '') {
                    filteredData = filteredData.filter((item) => item.category === selectedCategory);
                }
                if (selectedUrgence !== '') {
                    filteredData = filteredData.filter((item) => item.urgence === selectedUrgence);
                }
                setPlaintes(filteredData.sort().reverse());
            })
    }, [selectedEtat, selectedCategory, user.id, time, userTime])

    const IsRead = (id) => {
        axiosClient.get(`/tickets/${id}/`)
          .then((response) => {
            const ticket = response.data;
            const formData = new FormData()
            if ( ticket.response.length>0 && !ticket.is_read) {
               formData.append("response_read" , "True")
              axiosClient.patch(`/tickets/${id}/`,formData)
                .then((response) => {
                  console.log('ok');
                //   update()
                })
                .catch((error) => {
                  console.error(error)
                });
            }
          })
          .catch((error) => {
            console.error(error)
          });
    
      };
    

    const lightTitle = 'linear(to-l, #040e56, #497dff)'
    const darkTitle = 'linear(to-l, #497dff, #497dff)'
    return (
        <Box >

           
            <Flex>
                <Box w="100%" display={"flex"} flexDirection={{base:'column', md:"row"}} justifyContent="space-between" mt={2} mb={5}>
                    <div style={{display:"flex" , flexDirection:'row' , alignItems :"center" , gap: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span>My tickets</span> </Text>

                    </div>
                    
                   
                    <Flex
                    alignItems="center"
                    gap={5}
                    mt={{base:2 , md:0}}
                >
                    <InputGroup w='100%'>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            bg={useColorModeValue('white', 'gray.700')}
                            sx={{
                                marginLeft: 'auto',
                            }}
                        />  </InputGroup>

                </Flex>
                </Box>

            </Flex>
            
           
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={{ base: 'flex-start', md: 'space-between' }}
                p={5}
                gap={10}
            >

                <Flex
                    direction="column"
                    width={{ base: "100%", lg: "60%" }}
                    justify={{ base: 'flex-start', md: 'space-between' }}

                >
                     <Flex mb={2} w={{base:"100%",md:'100%'}}>
                        <Select borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">All categories</option>
                          <option value="technical_support">Technical support</option>
                          <option value="account_assistance">Account assistance</option>
                          <option value="feedback_suggestions">Feedback suggestions</option>
                          <option value="content_issues">Content issues</option>
                          <option value="general_inquiries">General inquiries</option>
                        </Select>

                        <Select borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '25%' }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">All</option>
                          <option value="true">Settled</option>
                          <option value="false">In progress</option>
                        </Select>
                        <Select borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '25%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => setSelectedUrgence(e.target.value)}>
                          <option value="">All</option>
                          <option value="élevée">High</option>
                          <option value="moyenne">average</option>
                          <option value="faible">weak</option>
                        </Select>
                      </Flex>

                    {filteredData().sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).filter((row) => (selectedEtat === null || row.etat === selectedEtat) && (selectedCategory === '' || row.category === selectedCategory) && (selectedUrgence === '' || row.urgence === selectedUrgence)).slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE)
                        .map((plainte) => (

                            <Stack
                                bg={myColor}
                                mb={2}
                                rounded={'lg'}
                                p={5}
                                boxShadow={'md'}
                                alignItems={'start'}
                                justify="space-between"
                                as={"Button"}

                            >
                                <Flex direction="row"
                                    justify="space-between"
                                    ml={'auto'}
                                    width="full">
                                    <Stack direction={'row'} align={'center'}>
                                        <Text fontSize={'lg'} fontWeight="semibold">{plainte.sujet}</Text>
                                        <Stack mt={1}>
                                            <Badge
                                                rounded={'lg'}
                                                colorScheme={'blue'}
                                            >
                                                {plainte.category === "technical_support" ? "Technical support" :
                                    plainte.category === "account_assistance" ? "Account assistance" :
                                    plainte.category === "feedback_suggestions" ? "Feedback suggestions" :
                                    plainte.category === "content_issues" ? "Content issues" :
                                    plainte.category === "general_inquiries" ? "General inquiries" : ""}
                                            </Badge></Stack>
                                    </Stack>
                                    <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(plainte.created_at).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>

                                </Flex>

                                <Flex
                                    direction="row"
                                    width="full"
                                    ml={'auto'}
                                    justify="space-between">
                                    <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                        {plainte.content}

                                    </Text>

                                    <Flex align={'center'} mt={1} direction={'column'}>
                                        <Badge
                                            mb={2}
                                            rounded={'lg'}
                                            colorScheme={!plainte.etat ? 'red' : 'green'}
                                        >
                                            {plainte.etat ? 'Settled' : 'In progress'}
                                        </Badge>
                                        <Flex direction={"row"} >
                                            {plainte.response ? 
                                            
                                            <IoMailUnreadOutline color='black' fontSize={18} style={{ marginRight: "10px" }} cursor='pointer' onClick={() => {
                                                IsRead(plainte.id)
                                                setId(plainte.id);
                                                setReponse(plainte.response);
                                                setOldReponseApprenant(plainte.responseUser)
                                                setChangeModal(true)
                                                onOpen();

                                                setResUserByWho(plainte.responseUserbywho)
                                                setUserTime(plainte.responseUsertime)

                                                setResByWho(plainte.responsebywho)
                                                setTime(plainte.responsetime)
                                            }} />
                                            : <IoMailOutline color='gray' fontSize={18} style={{ marginRight: "10px" }} cursor='pointer' onClick={() => {
                                                setId(plainte.id);
                                                setReponse(plainte.response);
                                                setOldReponseApprenant(plainte.responseUser)
                                                setChangeModal(true)
                                                onOpen();

                                                setResUserByWho(plainte.responseUserbywho)
                                                setUserTime(plainte.responseUsertime)

                                                setResByWho(plainte.responsebywho)
                                                setTime(plainte.responsetime)
                                            }} />}
                                            
                                            {/* <FiEdit fontSize={15} cursor='pointer' onClick={() => {
                                                setChangeModal(false)
                                                onOpen();
                                            }} /> */}
                                        </Flex>


                                    </Flex>
                                </Flex>
                            </Stack>
                        ))}
                    {filteredData().length === 0 && (
                        <Flex mb={'10'} align={'center'} justify={'center'}>
                            <Image
                            opacity={0.2}
                                height={200} width={300}
                                rounded={'md'}
                                alt={'empty'}
                                src={
                                    empty
                                }
                                objectFit={'empty'}
                            />
                        </Flex>)}
                    <MyPaginantion data={filteredData()} PAGE_SIZE={PAGE_SIZE} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </Flex>

                <Flex
                    w={{ base: '100%', md: '40%' }}
                    justify="center"
                    alignItems="center"
                    p={{ base: '3', md: '5' }}
                >
                    {/* <Lottie height={400} width={500} display={{ base: 'none', md: 'flex' }} options={defaultOptions} /> */}
                    <Image
                        display={{base:'none' , md:'flex'}}
                        height={450} width={450}
                        rounded={'10px 150px'}
                        alt={'feature image'}
                        src={
                            img3
                        }
                        objectFit={'cover'}
                    />
                </Flex>

            </Flex>

           
                <Modal  onClose={() => {
                    setmessage('')
                    setReponseApprenant('')
                    setUserTime('')
                    onClose()
                }} isOpen={isOpen} isCentered>
                    <ModalOverlay />
                    <ModalContent >
                        <ModalHeader>Response</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                           
                        {reponse ? 
                      <Flex w="100%" >

                        <Flex direction={"column"} justifyContent="end" w={"100%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.50" border={"1px solid #8ceb8c"} color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{new Date(time).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>

                        </Flex>
                      </Flex> 
                      : <Text>there is no response yet</Text>}

                        </ModalBody>
                        <ModalFooter>

                           
                        </ModalFooter>
                    </ModalContent>
                </Modal>
               
            
        </Box>

    )
}

export default MyTickets