// import logo from '../../assets/img/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { createRef, useState, useEffect } from 'react';
import { useStateContext } from '../../../context/ContextProvider';
import { GrAdd } from 'react-icons/gr';
// import responsable_img from '../../assets/img/responsableimg.png'
// import countryData from '../../../assets/dataJson/countries.json';


import {
    Box,
    Select,
    FormControl,
    FormLabel,
    Input,
    Flex,
    InputGroup,
    HStack,
    InputRightElement,
    useColorModeValue,
    Stack,
    useToast,
    Button,
    Heading,
    Text,
    Image,
    Alert,
    AlertIcon,
    Spinner,
    InputLeftElement,

} from '@chakra-ui/react';
import axiosClient from '../../../axios-client';
import { TfiReload } from 'react-icons/tfi';
import MySelect from "react-select";
import axios from 'axios';

const linkStyle = {
    color: '#3C8DBC',
    textDecoration: "underline"
}


export default function AddTeamMember() {
    //toast variable
    const inputColor = useColorModeValue("gray.300", "gray.600")
    const [countries, setCountries] = useState([]);
    const [team, setTeam] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState({});
    const toast = useToast()
    const navigate = useNavigate()

    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;


    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const password = createRef()
    const [address, setAddress] = useState("")
    const [tel, setTel] = useState()

    const confirmPassword = createRef()

    const [pwd, setPwd] = useState('');

    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [selectedRole, setSelectedRole] = useState('')
    const [selectedSexe, setSelectedSexe] = useState('')
    const [selectedEquipe, setSelectedEquipe] = useState('')

    const { user, isLoading, setIsLoading } = useStateContext()

    const handleRoleChange = (ev) => {
        setSelectedRole(ev.target.value) // update selected role when option is selected
    }
    const handleEquipeChange = (ev) => {
        setSelectedEquipe(ev.target.value) // update selected role when option is selected
    }
    const handleSexeChange = (ev) => {
        setSelectedSexe(ev.target.value) // update selected role when option is selected
    }

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);
    const handleCountryChange = (e) => {
        setSelectedCountry(countries.find(
            (country) => country.value === e.target.value
        ))

    };


    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(pwd)) {
            missingRequirements.push("a special Character");
        } else if (!lowercaseRegex.test(pwd)) {
            missingRequirements.push("a lowercase letter");
        } else if (!uppercaseRegex.test(pwd)) {
            missingRequirements.push("an uppercase letter");
        } else if (!digitRegex.test(pwd)) {
            missingRequirements.push("a number");
        } else if (!minLengthRegex.test(pwd)) {
            missingRequirements.push("a minimum length of 8 characters");
        }

        if (missingRequirements.length > 0) {
            const missingText = `You need ${missingRequirements.join(", ")} in your password.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength('The password is correct !');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [pwd]);
    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('The password must be at least 8 characters long.');
        }

        if (!digitRegex.test(password)) {
            errors.push('The password must contain at least one number.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('The password must contain at least one lowercase letter.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('The password must contain at least one uppercase letter.');
        }
        if (!specialchar.test(password)) {
            errors.push('The password must contain at least one special character (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('The password must not exceed 20 characters.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const isEmpty = () => {
        return !last_name || !first_name || !email || !pwd || !selectedRole || !selectedSexe || !address 
    };
    useEffect(() => {
        axiosClient.get('membres-equipe/')
            .then((res) => {
                setTeam(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])
    const onSubmit = ev => {
        ev.preventDefault()
        if (selectedRole === "CHEFEQUIPE" && team?.find(e => e.type_membre === "CHEFEQUIPE")) {
            setMessage("there is already a team leader");
        return
        } 
        if(selectedEquipe ==="MBEQUIPE" && !selectedEquipe)
        {
            setMessage("You need to pick a team");
            return
        }
            const payload = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                address: address,
                tel: tel,
                gender: selectedSexe,
                added_by: user.first_name + ' ' + user.last_name,
                password: password.current.value,
                type_membre: selectedRole,
                equipe: selectedEquipe? selectedEquipe :'' ,
            }
            console.log(payload);
            if (first_name.trim() === "" || last_name.trim() === "" || (address && address.trim() === "")) {
                window.scrollTo(0, 0)
                setMessage('Please fill in the fields correctly.')
                return
            }
    
            if (password.current.value !== confirmPassword.current.value) {
                setMessage("Please confirm your password");
            } else if (passwordStrength !== 'Fort' && password.current.value.length > 0 && !isPasswordValid(password.current.value)) {
                return;
            }
            else if (!emailRegex.test(email)) {
                setMessage("Please enter a valid email.");
                setIsLoading(false)
            }
    
    
            else {
                axiosClient.post('/membres-equipe/', payload)
                    .then((response) => {
                        console.log(response)
                        toast({
                            description: "User added successfully",
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                            position: 'bottom-right'
                        })
                        navigate('/team-members')
    
                    })
                    .catch((err) => {
                        const response = err.response;
                        setMessage(response.data)
                    })
            }
        

       
    }

    const [disableInputConfirm, setDisableInputConfirm] = useState(false)
    const [generetedPwd, setGeneretedPwd] = useState()
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setPwd(newPassword);
        confirmPassword.current.value = newPassword
        setGeneretedPwd(newPassword)
        setDisableInputConfirm(true)

    };


    // const [countries, setCountries] = useState([]);
    // const [selectedCountry, setSelectedCountry] = useState({});

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);


    const colorbg = useColorModeValue('white', '#2d3748')
    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box>
            <Box w={"100%"} mt={3} mb={2}>
                <Text as="cite" fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span onClick={() => navigate('/team-members')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Team members</span> / <span> Add a team member</span></Text>
            </Box>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={"center"}
                p={5}
            >

                <Stack
                     shadow={"md"} backgroundColor={"gray.50"}
                    rounded={'lg'}
                    p={6}
                    my={5}
                    w={{ base: '100%', md: '100%', lg: '70%', sm: '100%' }}
                >
                    {message &&
                        <Alert fontSize={"sm"} padding={2} status='error' rounded="md">
                            <AlertIcon />
                            {message}
                        </Alert>
                    }
                    <Box p={5} rounded={'lg'} w="100%" maxW="full"
                        align={'center'}
                        justify={'center'}
                    >
                        <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
                        >
                            <HStack >
                                <Box w="50%">
                                    <FormControl id="firstName" isRequired >
                                        <FormLabel ml={0.5}>First name</FormLabel>
                                        <Input value={first_name} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='Enter the first name' maxLength={25} />

                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="lastName" isRequired >
                                        <FormLabel ml={0.5}>Last name </FormLabel>
                                        <Input value={last_name} onChange={(e) => setLastName(e.target.value)} name="last_name" type="text" placeholder='Enter the last name' maxLength={25} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="100%">
                                    <FormControl id="email" isRequired >
                                        <FormLabel ml={0.5}>Email address</FormLabel>
                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Enter the email address" maxLength={50} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="100%">
                                    <FormControl id="address">
                                        <FormLabel ml={0.5}>Address</FormLabel>
                                        <Input value={address} onChange={(e) => setAddress(e.target.value)} name="address" type="text" placeholder='Enter the address' maxLength={50} />
                                    </FormControl>

                                </Box>


                            </HStack>

                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="rue">
                                        <FormLabel ml={0.5}>Phone number</FormLabel>
                                        <Input value={tel} onChange={(e) => setTel(e.target.value)} name="tel" type="number" placeholder='Enter the phone number' maxLength={50} />
                                    </FormControl>

                                </Box>
                                <Box w="50%">
                                    <FormControl id="sexe" isRequired>
                                        <FormLabel ml={0.5}>Sex</FormLabel>
                                        <Select placeholder="Select" onChange={handleSexeChange}>

                                            <option value="Female">Female</option>
                                            <option value="male">male</option>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </HStack>

                            <Text align={'left'} fontSize="sm" color='yellow.600'>The password must be at least 8 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special number.</Text>

                            <HStack>
                                <Box w="50%">
                                    <FormControl id="password" isRequired>
                                        <FormLabel ml={0.5}>Password</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement onClick={generatePassword} as={'Button'} >
                                                <TfiReload color='gray.300' />
                                            </InputLeftElement>
                                            <Input value={pwd} ref={password} name="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPwd(e.target.value)} onCopy={(e) => e.preventDefault()} placeholder='Enter the password' />

                                            <InputRightElement h={'full'}>

                                                <Button
                                                    variant={'ghost'}
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }>
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>


                                    </FormControl>

                                </Box>

                                <Box w="50%">
                                    <FormControl id="password_confirmation" isRequired>
                                        <FormLabel ml={0.5}>Confirm password</FormLabel>
                                        <InputGroup>
                                            {(disableInputConfirm && pwd === generetedPwd) ?
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirm password' disabled />
                                                :
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirm password' />
                                            }
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                            </HStack>
                            {pwd.length > 0 ?
                                <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                : ""}
                            <HStack spacing={2}>
                                <Box w={{ base: '100%', md: '50%' }}>
                                    <FormControl id="role" isRequired >
                                        <FormLabel ml={0.5}>Role</FormLabel>
                                        <Select placeholder="Select" onChange={handleRoleChange}>

                                            <option value="MBEQUIPE">Simple member</option>
                                            <option value="CHEFEQUIPE">Team leader</option>
                                        </Select>
                                    </FormControl>

                                </Box>
                                {selectedRole==="MBEQUIPE" &&
                                <Box w={{ base: '100%', md: '50%' }}>
                                <FormControl id="team" isRequired >
                                    <FormLabel ml={0.5}>Team</FormLabel>
                                    <Select placeholder="Select the member's team" onChange={handleEquipeChange}>

                                            <option value="SuiviTeam">Tracking team</option>
                                            <option value="ConfirmationTeam">Confirmation team</option>
                                    </Select>
                                </FormControl>

                            </Box>}
                                

                            </HStack>

                            <Stack direction={['column', 'row']} spacing={10} pt={2} justifyContent="end">
                                {
                                    isEmpty() ? (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="gray"
                                            isDisabled
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Create"}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="gray"
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Create"}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                {/* <Stack w="50%"
                    justify="center"
                    alignItems="center"
                    p={5}
                >
                    <Image display={{ base: 'none', md: 'flex' }} src={"responsable_img"} />
                </Stack> */}
            </Flex>
        </Box>

    )
}