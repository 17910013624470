import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../../axios-client';
import { AiFillDelete } from 'react-icons/ai';
import { SearchIcon } from '@chakra-ui/icons'
import MyPagination from "../../../components/MyPagination";
import { BiDetail } from 'react-icons/bi';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import moment from 'moment';


import {
  Flex,
  Box,
  Heading,
  Button,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  InputGroup,
  HStack,
  Td,
  TableContainer,
  useColorModeValue,
  InputLeftElement,
  Badge,
  useToast,
  Input,

  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Image,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import { Switch } from '@chakra-ui/react';
import { useStateContext } from "../../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';


export const GestTickets = () => {

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const navigate = useNavigate()

  const [reponseUser, setReponseUser] = useState('')
  const [img, setImg] = useState('')

  const [resUserByWho, setResUserByWho] = useState('')
  const [userTime, setUserTime] = useState('')
  const [resByWho, setResByWho] = useState('')
  const [time, setTime] = useState('')

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onOpenImg = () => {
    setIsImgOpen(true);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
    setDeleteId(null)
  };

  const onCloseImg = () => {
    setIsImgOpen(false);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  const [deleteId, setDeleteId] = useState(null);
  const [reponse, setReponse] = useState('');
  const [sujet, setSujet] = useState('');
  const [content, setContent] = useState('');


  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  //toast variable
  const toast = useToast()

  //search variable
  const [searchTerm, setSearchTerm] = useState('');

  //plainte data
  const [data, setData] = useState([]);
  //selected plainte
  const [dataDetail, setDataDetail] = useState('');

  //user data
  // const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [respoData, setRespoData] = useState([]);
  const [count, setCount] = useState([]);
  const [countr, setCountr] = useState([]);
  const [countp, setCountp] = useState([]);
  const [countm, setCountm] = useState([]);


  const [datac, setDatac] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const [dataPartenaire, setDataPartenaire] = useState([]);
  const [dataMbEquipe, setDataMbEquipe] = useState([]);

  const [countc, setCountc] = useState([]);

  const { user, setUser } = useStateContext();

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedEtat, setSelectedEtat] = useState(false);
  const [selectedUrgence, setSelectedUrgence] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');
  const { upd } = useStateContext();

  // current user data
  useEffect(() => {
    upd()
    axiosClient.get('auth/user/')
      .then(({ data }) => {
        setUser(data)
      })
  }, [])
  useEffect(() => {
    axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/admins/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const adminUsers = usersResponse.data.filter(user => user.role === "ADMIN");
            const respoUsers = usersResponse.data.filter(user => user.role === "RESPO");
            // Filter plaintes with etat=false for admin users
            const filteredPlaintesadmin = response.data.filter(pl => !pl.etat && adminUsers.find(admin => admin.id === pl.user));
            const filteredPlaintesrespo = response.data.filter(pl => !pl.etat && respoUsers.find(admin => admin.id === pl.user));

            // Count the plaintes for admin users
            const countadmin = filteredPlaintesadmin.length;
            const countrespo = filteredPlaintesrespo.length;

            setCount(countadmin);
            setCountr(countrespo);

          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/students/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(client => client.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountc(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [countc]);

  useEffect(() => {
    axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/partenaires/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(partenaire => partenaire.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountp(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [countp]);

  useEffect(() => {
    axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/membres-equipe/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(mbEquipe => mbEquipe.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountm(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [countm]);


  //get the plainte data
  useEffect(() => {
    axiosClient.get('/tickets/')
      .then((response) => {
        let filteredData = response.data;
        if (selectedEtat !== null) {
          filteredData = filteredData.filter((item) => item.etat === selectedEtat);
        }
        if (selectedCategory !== '') {
          filteredData = filteredData.filter((item) => item.category === selectedCategory);
        }
        if (selectedUrgence !== '') {
          filteredData = filteredData.filter((item) => item.urgence === selectedUrgence);
        }
        setData(filteredData.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedEtat, selectedCategory]);

  //search method (client)
  const filteredClientData = useCallback(() => {
    return data.filter((row) => {
      if (dataClient.find((user) => user.id === row.user)) {
        const fullName = `${dataClient.find((user) => user.id === row.user)?.first_name || 'Unknown'} ${dataClient.find((user) => user.id === row.user)?.last_name || 'Unknown'}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
          || row.content.toLowerCase().includes(searchTerm.toLowerCase())
          || row.created_at.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [data, dataClient, searchTerm]);

  const filteredMemberData = useCallback(() => {
    return data.filter((row) => {
      if (dataMbEquipe.find((user) => user.id === row.user)) {
        const fullName = `${dataMbEquipe.find((user) => user.id === row.user)?.first_name || 'Unknown'} ${dataMbEquipe.find((user) => user.id === row.user)?.last_name || 'Unknown'}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
          || row.content.toLowerCase().includes(searchTerm.toLowerCase())
          || row.created_at.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [data, dataMbEquipe, searchTerm]);

  //search method (client)
  const filteredPartenaireData = useCallback(() => {
    return data.filter((row) => {
      if (dataPartenaire.find((user) => user.id === row.user)) {
        const fullName = `${dataPartenaire.find((user) => user.id === row.user)?.first_name || 'Unknown'} ${dataPartenaire.find((user) => user.id === row.user)?.last_name || 'Unknown'}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
          || row.content.toLowerCase().includes(searchTerm.toLowerCase())
          || row.created_at.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [data, dataPartenaire, searchTerm]);

  // //search method (admin)
  // const filtereAdmindData = useCallback(() => {
  //   return data.filter((row) => {
  //     if (adminData.find((user) => user.id === row.user && user.role === "ADMIN")) {
  //       const fullName = `${adminData.find((user) => user.id === row.user)?.first_name || 'Unknown'} ${adminData.find((user) => user.id === row.user)?.last_name || 'Unknown'}`;
  //       return fullName.toLowerCase().includes(searchTerm.toLowerCase())
  //         || row.content.toLowerCase().includes(searchTerm.toLowerCase())
  //         || row.created_at.toLowerCase().includes(searchTerm.toLowerCase());
  //     }
  //   });
  // }, [data, adminData, searchTerm]);

  //search method (respo)
  // const filteredRespoData = useCallback(() => {
  //   return data.filter((row) => {
  //     if (respoData.find((user) => user.id === row.user && user.role === "RESPO")) {
  //       const fullName = `${respoData.find((user) => user.id === row.user)?.first_name || 'Unknown'} ${respoData.find((user) => user.id === row.user)?.last_name || 'Unknown'}`;
  //       return fullName.toLowerCase().includes(searchTerm.toLowerCase())
  //         || row.content.toLowerCase().includes(searchTerm.toLowerCase())
  //         || row.created_at.toLowerCase().includes(searchTerm.toLowerCase());
  //     }
  //   });
  // }, [data, respoData, searchTerm]);

  function update() {
    axiosClient.get('/tickets/')
      .then((response) => {
        let filteredData = response.data;
        if (selectedEtat !== null) {
          filteredData = filteredData.filter((item) => item.etat === selectedEtat);
        }
        if (selectedCategory !== '') {
          filteredData = filteredData.filter((item) => item.category === selectedCategory);
        }
        if (selectedUrgence !== '') {
          filteredData = filteredData.filter((item) => item.urgence === selectedUrgence);
        }
        setData(filteredData.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });

      axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/students/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(client => client.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountc(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

      axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/partenaires/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(partenaire => partenaire.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountp(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
      axiosClient.get('/tickets/')
      .then((response) => {
        // Update data state variable
        setDatac(response.data);

        axiosClient.get('/membres-equipe/') // Assuming this endpoint retrieves user data
          .then((usersResponse) => {
            const Users = usersResponse.data
            // Filter plaintes with etat=false for admin users
            const filteredPlaintes = response.data.filter(pl => !pl.etat && Users.find(mbEquipe => mbEquipe.id === pl.user));

            // Count the plaintes for admin users
            const count = filteredPlaintes.length;
            setCountm(count);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  function formatDateToCustomFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  }

  //get partenaires data
  useEffect(() => {
    axiosClient.get('/partenaires/')
      .then((response) => {
        setDataPartenaire(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //get students data
  useEffect(() => {
    axiosClient.get('/students/')
      .then((response) => {
        setDataClient(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //get the admin data
  useEffect(() => {
    axiosClient.get('/admins/')
      .then((response) => {
        setAdminData(response.data);
        setRespoData(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //get membre equipe data
  useEffect(() => {
    axiosClient.get('/membres-equipe/')
      .then((response) => {
        setDataMbEquipe(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  //delete plainte
  // const handleDelete = (id = deleteId) => {
  //   if (!id) return;
  //   axiosClient.delete(`/tickets/${id}/`)
  //     .then((response) => {
  //       setData((prevData) => prevData.filter((row) => row.id !== id));
  //       toast({
  //         description: "le ticket est supprimé avec succès",
  //         status: 'warning',
  //         duration: 2000,
  //         isClosable: true,
  //         position: 'bottom-right'
  //       })
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   onCloseAlert()
  // }

  const [plainteData, setPlainteData] = useState({ content: "", sujet: "", etat: 0, user: "", response: "" });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPlainteData((prevUserData) => ({
      ...prevUserData,
      response: value,
    }));
  };

  const [message, setMessage] = useState('')

  //send response
  const sendResponse = (id = deleteId) => {
    if (!id) return;
    if (plainteData.response.trim() === "") {
      setMessage("Enter a response");
      return;
    }
    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const updatedPlainteData = {
      response: plainteData.response,
      edited_by: user.first_name + ' ' + user.last_name,
      responsebywho: user.first_name + ' ' + user.last_name,
      responsetime: formatDateTime(new Date())
    };
    axiosClient.patch(`/tickets/${deleteId}/`, updatedPlainteData)
      .then(() => {
        toast({
          description: "Your response is sent successfully",
          status: 'success',
          duration: 2000,
          isClosable: true,
          variant: 'left-accent',
        })
        
        update()
        setMessage("")
      }
      )
      .catch(error => console.error(error));
    onCloseAlert()
  }

  //get plainte
  useEffect(() => {
    axiosClient.get(`/tickets/${deleteId}/`)
      .then((response) => {
        setDataDetail(response.data.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [deleteId, dataDetail.response]);

  //edit the plainte state ('encours','reglée')
  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append("edited_by", user.first_name + ' ' + user.last_name);

    axiosClient.patch(`/tickets/${id}/`, formData)
      .then((response) => {
        const updatedRow = response.data;
        // Update the row object with the new etat value
        setData(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
              date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
              edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
            };
          }
          return row;
        }));
        update()
        setMessage("")
        console.error(response);
        
      })
      .catch((error) => console.error(error));
    if (!etat) {
      toast({
        description: "The ticket is settled",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
        variant: 'left-accent',
      })

    }
    else {
      toast({
        description: "The ticket is in progress",
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
        variant: 'left-accent',
      })
    }
  }


  const viderInputs = () => {
    setSearchTerm('')
    setSelectedCategory('')
    // setSelectedEtat('')
  }

  const bg = useColorModeValue("gray.50", "gray.700");

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    }

  };
  const color = useColorModeValue("#7e172c", "#7e172c");
  const txtcolor = useColorModeValue("#f2c478", "#f2c478");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box mt="5px" >

      <Flex>
        <Box w="90%" mt={2} mb={5}>
          <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >Home</span>  / <span >Improvement suggestions</span></Text>
        </Box>
      </Flex>
      <Stack>
        <Tabs variant="enclosed">
          <TabList overflowX={'auto'} overflowY={'hidden'} css={{
            /* Hide scrollbar */
            "&::-webkit-scrollbar": {
              display: "none",
            },
            /* Optional: Firefox scrollbar */
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}>

            {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Administrateurs
                  {count > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {count}
                    </Badge>
                  )}
                </Text>
              </Tab>} */}
            {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Responsables
                  {countr > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countr}
                    </Badge>
                  )}</Text>
              </Tab>} */}

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Students
                  {countc > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countc}
                    </Badge>
                  )}</Text>
              </Tab>


              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Partners
                  {countp > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countp}
                    </Badge>
                  )}</Text>
              </Tab>

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>team members
                  {countm > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countm}
                    </Badge>
                  )}</Text>
              </Tab>
          </TabList>
          <TabPanels shadow={"md"} rounded={"lg"}>

            

              <TabPanel bg={"gray.50"} shadow={"md"} rounded={"lg"}>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex mb={3} w={"100%"}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">All categories</option>
                          <option value="technical_support">Technical support</option>
                          <option value="account_assistance">Account assistance</option>
                          <option value="feedback_suggestions">Feedback suggestions</option>
                          <option value="content_issues">Content issues</option>
                          <option value="general_inquiries">General inquiries</option>
                        </Select>

                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">All</option>
                          <option value="true">Settled</option>
                          <option value="false">In progress</option>
                        </Select>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => setSelectedUrgence(e.target.value)}>
                          <option value="">All</option>
                          <option value="élevée">High</option>
                          <option value="moyenne">average</option>
                          <option value="faible">weak</option>
                        </Select>
                      </Flex>

                      <InputGroup mb={3} size="sm" w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          borderRadius="5"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer bg={"gray.50"} rounded={10} >
                      <Table size={'md'} variant='simple'>
                        <Thead>
                          <Tr>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Code</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>User</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Date</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>State</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Category</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Urgence</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Content</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Response</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>History</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredClientData().filter((row) => (selectedEtat === null || row.etat === selectedEtat) && (selectedCategory === '' || row.category === selectedCategory) && (selectedUrgence === '' || row.urgence === selectedUrgence)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => {
                            {
                              return (
                                <Tr key={row.id}>
                                  <Td borderColor={borderColor} >{row.code}</Td>
                                  <Td borderColor={borderColor}>{dataClient.find((user) => user.id === row.user)?.first_name || 'Unknown'} {dataClient.find((user) => user.id === row.user)?.last_name || 'Unknown'}</Td>

                                  <Td borderColor={borderColor}>{new Date(row.created_at).toLocaleDateString("en-US")}</Td>
                                  <Td borderColor={borderColor}>
                                    <Flex>
                                      <Stack direction='row' mr="3px">
                                        <Switch
                                          colorScheme='green'
                                          isChecked={row.etat}
                                          onChange={() => handleEtatClick(row.id, row.etat)}
                                        />
                                      </Stack>
                                      <Badge
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                      >
                                        {row.etat ? 'Settled' : 'In progress'}
                                      </Badge>
                                    </Flex>
                                  </Td>
                                  <Td borderColor={borderColor}>{row.category === "technical_support" ? "Technical support" :
                                    row.category === "account_assistance" ? "Account assistance" :
                                      row.category === "feedback_suggestions" ? "Feedback suggestions" :
                                        row.category === "content_issues" ? "Content issues" :
                                          row.category === "general_inquiries" ? "General inquiries" : ""}</Td>
                                  <Td borderColor={borderColor}>
                                    {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>High</Badge> :
                                      row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>Average</Badge> :
                                        row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>Weak</Badge> : ""}
                                  </Td>

                                  <Td borderColor={borderColor} p={4}>

                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setSujet(row.sujet);
                                        setContent(row.content);
                                        onOpen();
                                      }}
                                      colorScheme='blue'
                                      leftIcon={<BiDetail />}>
                                      Show
                                    </Button></Td>

                                    <Td borderColor={borderColor} p={4}>
                                    <Button
                                      size="sm"

                                      colorScheme='green'
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setReponse(row.response);
                                        setReponseUser(row.responseUser)
                                        onOpenAlert();
                                        setResUserByWho(row.responseUserbywho)
                                        setUserTime(row.responseUsertime)

                                        setResByWho(row.responsebywho)
                                        setTime(row.responsetime)
                                      }}
                                      leftIcon={<MdOutlineQuestionAnswer />}>
                                      Respond
                                    </Button></Td>

                                  <Td borderColor={borderColor} p={4}><Popover placement="left" >
                                    <PopoverTrigger>
                                      <Button
                                        size="sm"
                                        mr="5px"
                                        bg="gray.300"
                                        color={"black"}
                                        leftIcon={<MdHistory />}>
                                        History
                                      </Button>

                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #f2c478)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        History
                                      </PopoverHeader>
                                      <PopoverArrow bg="gray" />
                                      <PopoverCloseButton />
                                      <PopoverBody>

                                        <div style={{ marginBottom: '8px' }}>
                                          Edited by : {row.edited_by}
                                        </div>

                                        <div>
                                          last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover></Td>
                                  {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Delete
                                    </Button></Td> */}
                                </Tr>
                              )
                            }

                          })}

                          {/* when there is no search data found */}
                          {filteredClientData().length === 0 && (
                            <Tr>
                              <Td borderColor={borderColor} colSpan={12}>No matching rows were found.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          data={filteredClientData()}
                          searchInput={searchTerm}
                          PAGE_SIZE={pageSize}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>


              <TabPanel bg={"gray.50"} shadow={"md"} rounded={"lg"}>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex mb={3} w={"100%"}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">All categories</option>
                          <option value="technical_support">Technical support</option>
                          <option value="account_assistance">Account assistance</option>
                          <option value="feedback_suggestions">Feedback suggestions</option>
                          <option value="content_issues">Content issues</option>
                          <option value="general_inquiries">General inquiries</option>
                        </Select>

                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">All</option>
                          <option value="true">Settled</option>
                          <option value="false">In progress</option>
                        </Select>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => setSelectedUrgence(e.target.value)}>
                          <option value="">All</option>
                          <option value="élevée">High</option>
                          <option value="moyenne">average</option>
                          <option value="faible">weak</option>
                        </Select>
                      </Flex>

                      <InputGroup mb={3} size="sm" w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          borderRadius="5"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer bg={"gray.50"} rounded={10} >
                      <Table size={'md'} variant='simple'>
                        <Thead>
                          <Tr>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Code</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>User</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Date</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>State</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Category</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Urgence</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Content</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Response</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>History</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredPartenaireData().filter((row) => (selectedEtat === null || row.etat === selectedEtat) && (selectedCategory === '' || row.category === selectedCategory) && (selectedUrgence === '' || row.urgence === selectedUrgence)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => {
                            {
                              return (
                                <Tr key={row.id}>
                                  <Td borderColor={borderColor}>{row.code}</Td>
                                  <Td borderColor={borderColor}>{dataPartenaire.find((user) => user.id === row.user)?.first_name || 'Unknown'} {dataPartenaire.find((user) => user.id === row.user)?.last_name || 'Unknown'}</Td>

                                  <Td borderColor={borderColor}>{new Date(row.created_at).toLocaleDateString("en-US")}</Td>
                                  <Td borderColor={borderColor}>
                                    <Flex>
                                      <Stack direction='row' mr="3px">
                                        <Switch
                                          colorScheme='green'
                                          isChecked={row.etat}
                                          onChange={() => handleEtatClick(row.id, row.etat)}
                                        />
                                      </Stack>
                                      <Badge
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                      >
                                        {row.etat ? 'Settled' : 'In progress'}
                                      </Badge>
                                    </Flex>
                                  </Td>
                                  <Td borderColor={borderColor}>{row.category === "technical_support" ? "Technical support" :
                                    row.category === "account_assistance" ? "Account assistance" :
                                      row.category === "feedback_suggestions" ? "Feedback suggestions" :
                                        row.category === "content_issues" ? "Content issues" :
                                          row.category === "general_inquiries" ? "General inquiries" : ""}</Td>
                                  <Td borderColor={borderColor}>
                                    {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>High</Badge> :
                                      row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>Average</Badge> :
                                        row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>Weak</Badge> : ""}
                                  </Td>

                                  <Td borderColor={borderColor} p={4}>

                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setSujet(row.sujet);
                                        setContent(row.content);
                                        onOpen();
                                      }}
                                      colorScheme='blue'
                                      leftIcon={<BiDetail />}>
                                      Show
                                    </Button></Td>

                                    <Td borderColor={borderColor} p={4}>
                                    <Button
                                      size="sm"

                                      colorScheme='green'
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setReponse(row.response);
                                        setReponseUser(row.responseUser)
                                        onOpenAlert();
                                        setResUserByWho(row.responseUserbywho)
                                        setUserTime(row.responseUsertime)

                                        setResByWho(row.responsebywho)
                                        setTime(row.responsetime)
                                      }}
                                      leftIcon={<MdOutlineQuestionAnswer />}>
                                      Respond
                                    </Button></Td>

                                  <Td borderColor={borderColor} p={4}><Popover placement="left" >
                                    <PopoverTrigger>
                                      <Button
                                        size="sm"
                                        mr="5px"
                                        bg="gray.300"
                                        color={"black"}
                                        leftIcon={<MdHistory />}>
                                        History
                                      </Button>

                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #f2c478)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        History
                                      </PopoverHeader>
                                      <PopoverArrow bg="gray" />
                                      <PopoverCloseButton />
                                      <PopoverBody>

                                        <div style={{ marginBottom: '8px' }}>
                                          Edited by : {row.edited_by}
                                        </div>

                                        <div>
                                          last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover></Td>
                                  {/* <Td>
                      <Button
                        size="sm"
                        //onClick={onOpen}
                        //onClick={() => handleDelete(row.id)}
                        onClick={() => {
                          setDeleteId(row.id);
                          onOpenAlert();
                        }}
                        colorScheme='red'
                        leftIcon={<AiFillDelete />}>
                        Delete
                      </Button></Td> */}
                                </Tr>
                              )
                            }

                          })}

                          {/* when there is no search data found */}
                          {filteredPartenaireData().length === 0 && (
                            <Tr>
                              <Td borderColor={borderColor} colSpan={12}>No matching rows were found.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          data={filteredPartenaireData()}
                          searchInput={searchTerm}
                          PAGE_SIZE={pageSize}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>

              <TabPanel bg={"gray.50"} shadow={"md"} rounded={"lg"}>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex mb={3} w={"100%"}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">All categories</option>
                          <option value="technical_support">Technical support</option>
                          <option value="account_assistance">Account assistance</option>
                          <option value="feedback_suggestions">Feedback suggestions</option>
                          <option value="content_issues">Content issues</option>
                          <option value="general_inquiries">General inquiries</option>
                        </Select>

                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">All</option>
                          <option value="true">Settled</option>
                          <option value="false">In progress</option>
                        </Select>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => setSelectedUrgence(e.target.value)}>
                          <option value="">All</option>
                          <option value="élevée">High</option>
                          <option value="moyenne">average</option>
                          <option value="faible">weak</option>
                        </Select>
                      </Flex>

                      <InputGroup mb={3} size="sm" w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          borderRadius="5"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer bg={"gray.50"} rounded={10} >
                      <Table size={'md'} variant='simple'>
                        <Thead>
                          <Tr>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Code</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>User</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Date</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>State</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Category</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Urgence</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Content</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>Response</Th>
                            <Th borderColor={borderColor} fontFamily='Plus Jakarta Display'>History</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredMemberData().filter((row) => (selectedEtat === null || row.etat === selectedEtat) && (selectedCategory === '' || row.category === selectedCategory) && (selectedUrgence === '' || row.urgence === selectedUrgence)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => {
                            {
                              return (
                                <Tr key={row.id}>
                                  <Td borderColor={borderColor}>{row.code}</Td>
                                  <Td borderColor={borderColor}>{dataMbEquipe.find((user) => user.id === row.user)?.first_name || 'Unknown'} {dataMbEquipe.find((user) => user.id === row.user)?.last_name || 'Unknown'}</Td>

                                  <Td borderColor={borderColor}>{new Date(row.created_at).toLocaleDateString("en-US")}</Td>
                                  <Td borderColor={borderColor}>
                                    <Flex>
                                      <Stack direction='row' mr="3px">
                                        <Switch
                                          colorScheme='green'
                                          isChecked={row.etat}
                                          onChange={() => handleEtatClick(row.id, row.etat)}
                                        />
                                      </Stack>
                                      <Badge
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                      >
                                        {row.etat ? 'Settled' : 'In progress'}
                                      </Badge>
                                    </Flex>
                                  </Td>
                                  <Td borderColor={borderColor}>{row.category === "technical_support" ? "Technical support" :
                                    row.category === "account_assistance" ? "Account assistance" :
                                      row.category === "feedback_suggestions" ? "Feedback suggestions" :
                                        row.category === "content_issues" ? "Content issues" :
                                          row.category === "general_inquiries" ? "General inquiries" : ""}</Td>
                                  <Td borderColor={borderColor}>
                                    {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>High</Badge> :
                                      row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>Average</Badge> :
                                        row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>Weak</Badge> : ""}
                                  </Td>

                                  <Td borderColor={borderColor} p={4}>

                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setSujet(row.sujet);
                                        setContent(row.content);
                                        onOpen();
                                      }}
                                      colorScheme='blue'
                                      leftIcon={<BiDetail />}>
                                      Show
                                    </Button></Td>

                                  <Td borderColor={borderColor} p={4}>
                                    <Button
                                      size="sm"

                                      colorScheme='green'
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setReponse(row.response);
                                        setReponseUser(row.responseUser)
                                        onOpenAlert();
                                        setResUserByWho(row.responseUserbywho)
                                        setUserTime(row.responseUsertime)

                                        setResByWho(row.responsebywho)
                                        setTime(row.responsetime)
                                      }}
                                      leftIcon={<MdOutlineQuestionAnswer />}>
                                      Respond
                                    </Button></Td>

                                  <Td borderColor={borderColor} p={4}><Popover placement="left" >
                                    <PopoverTrigger>
                                      <Button
                                        size="sm"
                                        mr="5px"
                                        bg="gray.300"
                                        color={"black"}
                                        leftIcon={<MdHistory />}>
                                        History
                                      </Button>

                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #f2c478)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        History
                                      </PopoverHeader>
                                      <PopoverArrow bg="gray" />
                                      <PopoverCloseButton />
                                      <PopoverBody>

                                        <div style={{ marginBottom: '8px' }}>
                                          Edited by : {row.edited_by}
                                        </div>

                                        <div>
                                          last modification date: {new Date(row.date_modification).toLocaleString("en-US")}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover></Td>
                                  {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Delete
                                    </Button></Td> */}
                                </Tr>
                              )
                            }

                          })}

                          {/* when there is no search data found */}
                          {filteredMemberData().length === 0 && (
                            <Tr>
                              <Td borderColor={borderColor} colSpan={12}>No matching rows were found.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          data={filteredMemberData()}
                          searchInput={searchTerm}
                          PAGE_SIZE={pageSize}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>

      <Modal
        closeOnOverlayClick={plainteData.response ? false : true}
        isOpen={isAlertOpen}
        onClose={() => {
          setMessage('')
          setPlainteData({})
          onCloseAlert()
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Response</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* <FormControl>
              <Textarea
                maxLength={250}
                borderColor="gray.300"
                defaultValue={reponse}
                _hover={{
                  borderRadius: 'gray.300',
                }}
                h="100px"
                name="response"
                onChange={handleInputChange}
                placeholder="Ecrivez votre réponse"
              />
            </FormControl> */}
            {message &&
              <Alert fontSize={"sm"} padding={2} mb={2} status='error' rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            }

            <FormControl>
              {

               
                    <>
                     

                      {reponse ? 
                      <Flex w="100%" >

                        <Flex direction={"column"} justifyContent="end" w={"100%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.50" border={"1px solid #8ceb8c"} color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{new Date(time).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</Text>

                        </Flex>
                      </Flex> 
                      : null}
                     
                    </>


                
              }
              <Input mt={5} maxLength={250}
                borderColor="gray.300"
                _hover={{
                  borderRadius: 'gray.300',
                }}
                onChange={handleInputChange}
                name="reponse"
                placeholder="Enter your response"></Input>

            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={sendResponse} colorScheme='blue' isDisabled={!plainteData.response}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete le message
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You won't be able to undo this action later.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{sujet}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {content}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




      <Modal size="5xl" isOpen={isImgOpen} onClose={onCloseImg}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              objectFit='cover'
              boxSize={"100%"}
              src={img}
            />
          </ModalBody>


        </ModalContent>
      </Modal>

    </Box>
  )
}


