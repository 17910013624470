import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import RequireAuth from "./hooks/RequireAuth";

// import Dashboard from "./views/dashboard/Dashboard";

import Login from "./views/auth/Login";
import Dashboard from "./views/administrative_area/dashboard/Dashboard";
import LoginStudent from "./views/auth/LoginStudent";
import StudentLayout from "./components/StudentLayout";
import DashboardStudent from "./views/student_area/dashboard/DashboardStudent";
import RequireAuthStudent from "./hooks/RequireAuthStudent";
import Profile from "./views/student_area/profile/Profile";
import EditProfile from "./views/student_area/profile/EditProfile";
import Security from "./views/student_area/profile/Security";
import GestAdmins from "./views/administrative_area/gestion_administrateurs/GestAdmins";
import GestPartenaires from "./views/administrative_area/gestion_partenaires/GestPartenaires";
import RegisterDemande from "./views/administrative_area/gestion_demandes_inscription/RegisterDemande";
import AddAdmin from "./views/administrative_area/gestion_administrateurs/AddAdmin";
import EditAdmin from "./views/administrative_area/gestion_administrateurs/EditAdmin";
import GestStudents from "./views/administrative_area/gestion_students/GestStudents";
import { GestTickets } from "./views/administrative_area/gestion_tickets/GestTickets";
import AddStudent from "./views/administrative_area/gestion_students/AddStudent";
import { GestTeamMember } from "./views/administrative_area/gestion_team_members/GestTeamMember";
import GestTeams from "./views/administrative_area/gestion-equipes/GestTeams";
import EditProfileAdmin from "./views/administrative_area/profile/EditProfileAdmin";
import SecurityAdmin from "./views/administrative_area/profile/SecurityAdmin";
import ProfileAdmin from "./views/administrative_area/profile/ProfileAdmin";
import EditStudent from "./views/administrative_area/gestion_students/EditStudent";
import AddTeamMember from "./views/administrative_area/gestion_team_members/AddTeamMember";
import { Ticket } from "./views/administrative_area/gestion_tickets/Ticket";
import GestProgrammes from "./views/administrative_area/gestion_programmes/GestProgrammes";
import GestOffres from "./views/administrative_area/gestion_offres/GestOffres";
import NewOffers from "./views/student_area/offers/NewOffers";
import SuivreApplication from "./views/student_area/application/SuivreApplication";
import StudentApplication from "./views/administrative_area/gestion_students/StudentApplication";
import { STicket } from "./views/student_area/myTickets/STicket";
import AddPartenaire from "./views/administrative_area/gestion_partenaires/AddPartenaire";
import EditPartenaire from "./views/administrative_area/gestion_partenaires/EditPartenaire";
import EditTeamMember from "./views/administrative_area/gestion_team_members/EditTeamMember";
import GestNotifications from "./views/administrative_area/gestion_notifications/GestNotifications";
import NewOffersPartner from "./views/administrative_area/gestion_partenaires/offers_partner/NewOffersPartner";
import MajorsPartner from "./views/administrative_area/gestion_partenaires/majors_partner/MajorsPartner";
import Application from "./views/student_area/application/Application";
import GestNotes from "./views/administrative_area/gestion_team_members/gestion_notes/GestNotes";
import Notes from "./views/administrative_area/gestion_team_members/gestion_notes/Notes";
import MyApplications from "./views/student_area/application/MyApplications";
import Notifs_Students from "./views/administrative_area/gestion_notifications/Notifs_Students";
import ResetPassword from "./views/auth/ResetPassword";
import ResetPwdPage from "./views/auth/ResetPwdPage";
import ResetPasswordStudent from "./views/auth/ResetPasswordStudent";
import NotifStudents from "./views/administrative_area/gestion_notifications/NotifStudents.jsx";
import Notif from "./views/administrative_area/gestion_notifications/Notif";
import MyTickets from "./views/administrative_area/gestion_tickets/MyTickets.jsx";
import MyTicketsStudent from "./views/student_area/myTickets/MyTicketsStudent.jsx";

// import Profile from "./views/profile/Profile";


const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
    
      {
        path: '/',
        element:<RequireAuth element={<Dashboard/>}/>  
      },
      {
        path: '/admins',
        element:<RequireAuth element={<GestAdmins/>}/>  
      },
      {
        path: '/add-admin',
        element:<RequireAuth element={<AddAdmin/>}/>  
      },
      {
        path: '/modifier-admin',
        element:<RequireAuth element={<EditAdmin/>}/>  
      },
      {
        path: '/partners',
        element:<RequireAuth element={<GestPartenaires/>}/>  
      },
      {
        path: '/add-partner',
        element:<RequireAuth element={<AddPartenaire/>}/>  
      },
      {
        path: '/edit-partner',
        element:<RequireAuth element={<EditPartenaire/>}/>  
      },
      {
        path: '/demandes-inscription',
        element:<RequireAuth element={<RegisterDemande/>}/>  
      },
      {
        path: '/students',
        element:<RequireAuth element={<GestStudents/>}/>  
      },
      {
        path: '/edit-student',
        element:<RequireAuth element={<EditStudent/>}/>  
      },
      {
        path: '/add-student',
        element:<RequireAuth element={<AddStudent/>}/>  
      },
      {
        path: '/tickets',
        element:<RequireAuth element={<GestTickets/>}/>  
      },
      {
        path: '/ticket',
        element:<RequireAuth element={<Ticket/>}/>  
      },
      {
        path: '/team-members',
        element:<RequireAuth element={<GestTeamMember/>}/>  
      },
      {
        path: '/add-team-member',
        element:<RequireAuth element={<AddTeamMember/>}/>  
      },
      {
        path: '/teams',
        element:<RequireAuth element={<GestTeams/>}/>  
      },
      {
        path: '/profile',
        element:<RequireAuth element={<ProfileAdmin/>}/>  
      },
      {
        path: '/edit-profile',
        element:<RequireAuth element={<EditProfileAdmin/>}/>  
      },
      {
        path: '/account-security',
        element:<RequireAuth element={<SecurityAdmin/>}/>  
      },
      {
        path: '/majors',
        element:<RequireAuth element={<GestProgrammes/>}/>  
      },
      {
        path: '/offers',
        element:<RequireAuth element={<GestOffres/>}/>  
      },
      {
        path: '/student-applications',
        element:<RequireAuth element={<StudentApplication/>}/>  
      },
      {
        path: '/team-member-edit',
        element:<RequireAuth element={<EditTeamMember/>}/>  
      },
      {
        path: '/notifications',
        element:<RequireAuth element={<GestNotifications/>}/>  
      },
      {
        path: '/new-offers',
        element:<RequireAuth element={<NewOffersPartner/>}/>  
      },
      {
        path: '/list-of-majors',
        element:<RequireAuth element={<MajorsPartner/>}/>  
      },
      {
        path: '/notes',
        element:<RequireAuth element={<GestNotes/>}/>  
      },
      {
        path: '/team-notes',
        element:<RequireAuth element={<Notes/>}/>  
      },
      {
        path: '/details-notification',
        element:<RequireAuth element={<Notif/>}/>  
      },
      {
        path: '/my-tickets',
        element:<RequireAuth element={<MyTickets/>}/>  
      },
    
  
    ]
  },
  {
    path: '/',
    element: <StudentLayout />,
    children: [
    
      {
        path: '/dashboard-student',
        element:<RequireAuthStudent element={<DashboardStudent/>}/>  
      },
      {
        path: '/my-profile',
        element:<RequireAuthStudent element={<Profile/>}/>  
      },
      {
        path: '/edit-my-profile',
        element:<RequireAuthStudent element={<EditProfile/>}/>  
      },
      {
        path: '/my-account-security',
        element:<RequireAuthStudent element={<Security/>}/>  
      },
      {
        path: '/current-offers',
        element:<RequireAuthStudent element={<NewOffers/>}/>  
      },
      
      {
        path: '/my-applications',
        element:<RequireAuthStudent element={<MyApplications/>}/>  
      },
      {
        path: '/my-application',
        element:<RequireAuthStudent element={<SuivreApplication/>}/>  
      },
      {
        path: '/contact-us',
        element:<RequireAuthStudent element={<STicket/>}/>  
      },
      {
        path: '/program-application',
        element:<RequireAuthStudent element={<Application/>}/>  
      },
      {
        path: '/all-notifications',
        element:<RequireAuthStudent element={<Notifs_Students/>}/>  
      },
      {
        path: '/notification-details',
        element:<RequireAuthStudent element={<NotifStudents/>}/>  
      },
      {
        path: '/my-tickets-list',
        element:<RequireAuthStudent element={<MyTicketsStudent/>}/>  
      },
      
      
     
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-student',
        element: <LoginStudent />
      },
      {
        path: '/reset_password',
        element: <ResetPassword />
      },
      {
        path: '/reset-password',
        element: <ResetPasswordStudent />
      },
      {
        path: '/reset_my_password/:userId/:token/:expires',
        element: <ResetPwdPage />
      },
      
    ]
  },

  // {
  //   path: "*",
  //   element: <NotFound />
  // }
])

export default router;
